import { useCallback, useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
// import { DrawShapesDropdown, ToggleButton } from "../components";
import LayersIcon from "assets/images/layers-icon.svg";
import CloseIcon from "Components/Dashboard/Configuration/CloseIcon";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import apiLibrary from "services/api";
import axios from "axios";

const useInitializeCustomLayersListControl = (
  isMapFullyLoaded: boolean,
  map: any,
  selectedGlobalLayers: any,
  setSelectedGlobalLayers: any,
  layers: any,
  customControlId: string,
  type?: any,
  isEditMode: boolean = false
) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  //   const [layers, setLayers] = useState<any>(null);

  //   useEffect(() => {
  //     fetchLayers().then((data) => {
  //       setLayers(data);
  //     });
  //   }, []);

  useEffect(() => {
    setSelectedGlobalLayers((prev: any[]) => {
      return prev.map((item: any) => {
        return { ...item, status: false };
      });
    });
  }, [isEditMode]);

  useEffect(() => {
    if (map && isMapFullyLoaded) {
      ReactDOM.render(
        <>
          <ToggleButton handleClick={toggleDropdownListener} />
          <LayersList
            isVisible={isDropdownVisible}
            handleClickOnCloseBtn={toggleDropdownListener}
            data={layers}
            handleClickOnCheckbox={handleClickOnLayerCheckBox}
            selectedGlobalLayers={selectedGlobalLayers}
            type={type}
            isEditMode={isEditMode}
          />
        </>,
        document.getElementById("LayersListControlContainer" + customControlId)
      );
    }

    return () => {
      const container = document.getElementById(
        "LayersListControlContainer" + customControlId
      );
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
    };
  }, [map, isMapFullyLoaded, isDropdownVisible, layers]);

  // handlers
  const toggleDropdownListener = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleClickOnLayerCheckBox = (layer: any) => {
    setSelectedGlobalLayers((prev = []) => {
      return prev.map((item: any) => {
        if (item.id === layer.id) {
          return layer;
        }
        return item;
      });
    });
  };

  return null;
};

export { useInitializeCustomLayersListControl };

const ToggleButton = ({ handleClick }: any) => {
  return (
    <button onClick={handleClick} type="button">
      <img src={LayersIcon} className="mx-auto" />
    </button>
  );
};

const LayersList = ({
  isVisible,
  handleClickOnCloseBtn,
  data = [],
  handleClickOnCheckbox,
  selectedGlobalLayers,
  type = null,
  isEditMode = false,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchLayerMapData = async (e: any, layer: any) => {

    const isChecked = e.target.checked;

    if (isEditMode === false || layer.mapData) {
     
      handleClickOnCheckbox({
        ...layer,
        status: isChecked,
      });
      return;
    }

    try {
      setIsLoading(true);
      

      const response = await axios.get(layer.geoFilePath);
      const updatedLayer = {
        ...layer,
        status: isChecked,
        mapData: response.data,
      };

      handleClickOnCheckbox(updatedLayer);
    } catch (err) {
      console.error("Error during fetch", err);
      handleClickOnCheckbox({
        ...layer,
        status: isChecked,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className=" bg-bgWhite h-[250px] w-[200px] absolute top-0 right-[38px] rounded shadow-md "
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <div className="relative w-full h-full p-4">
        <div
          className="absolute flex items-center justify-center cursor-pointer top-4 right-4"
          onClick={handleClickOnCloseBtn}
        >
          <CloseIcon
            className="mx-auto "
            style={{ width: "18px !important" }}
          />
        </div>
        <h2 className="mb-4 text-base font-semibold font-Overpass text-secondaryMid">
          Global Layers
        </h2>
        {data ? (
          <ul className="overflow-y-auto overflow-x-hidden  h-[82%] scroll">
            {data.map((l: any) => {
              return (
                <li className="mb-2  pr-[2px]" key={l.id.toString()}>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={l.status}
                      onChange={(e: any) => fetchLayerMapData(e, l)}
                      id={l?.id}
                      disabled={isLoading}
                      className="w-3 h-3 cursor-pointer"
                    />
                    <label
                      htmlFor={l?.id}
                      className="cursor-pointer text-sm text-secondaryMidLight font-Overpass -mb-[3px] "
                      style={{ wordBreak: "break-word" }}
                    >
                      {l?.name}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="h-[80%] w-full flex justify-center items-center">
            <h1 className="text-sm font-semibold font-Overpass text-secondaryMid">
              Layers Not Found!
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};
