import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment-timezone";
import { DATE_FORMATS } from "constants/dateFormats";
import { TIME_FORMATS } from "constants/timeFormats";

function formatDateToDaysAgo(date: string): string {
  if (!date) {
    return "";
  }

  const today = moment();
  const datee = moment(
    date,
    ["YYYY-MM-DD", "DD-MM-YYYY", "YYYY/MM/DD", "HH:mm:ss.SSSSSS"],
    true
  );

  if (!datee.isValid()) {
    return date;
  }

  const daysDifference = today.diff(datee, "days");

  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "Yesterday";
  } else if (daysDifference < 7) {
    return `${daysDifference} days ago`;
  } else if (daysDifference < 30) {
    const weeksAgo = Math.floor(daysDifference / 7);
    return `${weeksAgo} ${weeksAgo === 1 ? "week" : "weeks"} ago`;
  } else if (daysDifference < 365) {
    const monthsAgo = Math.floor(daysDifference / 30);
    return `${monthsAgo} ${monthsAgo === 1 ? "month" : "months"} ago`;
  } else {
    const yearsAgo = Math.floor(daysDifference / 365);
    return `${yearsAgo} ${yearsAgo === 1 ? "year" : "years"} ago`;
  }
}
function daysUntil(endDate: any) {
  const endMoment = moment(endDate).endOf("day");
  const now = moment();

  if (!endMoment.isValid() || endMoment.isBefore(now, "minute")) {
    return "Invalid or past date";
  }

  const daysLeft = endMoment.diff(now, "days");

  const formattedEndDate = endMoment.format("MMM Do");
  const daysText = daysLeft === 1 ? "day" : "days";

  return `${daysLeft} ${daysText} till ${formattedEndDate}`;
}

function useDateFormatter() {
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  // console.log(profileSettings,'profileSettings');

  function formatDate(dateStr: string): string {
    if (!dateStr) {
      return "";
    }

    const datee = moment(
      dateStr,
      ["YYYY-MM-DD", "DD-MM-YYYY", "YYYY/MM/DD"],
      true
    );

    if (!datee.isValid()) {
      return dateStr;
    }

    const formattedDate = profileSettings?.dateFormat
      ? datee.format(profileSettings.dateFormat)
      : datee.format("YYYY-MM-DD");

    return formattedDate;
  }
  function extractDate(dateTimeStr: string): string {
    if (!dateTimeStr) {
      return "";
    }

    const datePart = dateTimeStr.split(" ")[0]; // Split the dateTimeStr by space and take the first part (date)
    const datee = moment(datePart, "YYYY-MM-DD", true);

    if (!datee.isValid()) {
      return dateTimeStr;
    }

    const formattedDate = profileSettings?.dateFormat
      ? datee.format(profileSettings.dateFormat)
      : datee.format("YYYY-MM-DD");

    return formattedDate;
  }

  const FormatDateTime = (date: any) => {
    // Return an empty string if the date is null or undefined
    if (!date) {
      return "";
    }

    const dateMoment = moment(date);

    // Check if the date is valid
    if (!dateMoment.isValid()) {
      return ""; // Return an empty string if the date is not valid
    }

    // Format the date if it's valid
    //   const formatMap: any = {
    //     "MM/DD/YYYY": 'MMM DD YYYY, hh:mm A',
    //     "DD/MM/YYYY": 'DD MMM YYYY, hh:mm A',
    //     "YYYY/MM/DD": 'YYYY MMM DD, hh:mm A'
    // };

    // const dateFormat: any = profileSettings?.dateFormat || 'DD MMM YYYY';
    // const selectedFormat = formatMap[dateFormat] || dateFormat;

    // const formattedDated = dateMoment.format(selectedFormat);
    const formattedDate = dateMoment.format("DD MMM YYYY, hh:mm A");
    return formattedDate;
  };

  const formatTime = (time: any) => {
    // Return an empty string if the time is null or undefined
    if (!time) {
      return "";
    }

    const timeMoment = moment(time, "HH:mm:ss");

    // Check if the date is valid
    if (!timeMoment.isValid()) {
      return ""; // Return an empty string if the date is not valid
    }

    // Format the time if the date is valid
    const formattedTime = timeMoment.format("hh:mm A");
    return formattedTime;
  };

  /**
   * Calculates time from now in one time zone relative to the current time in another time zone.
   * It can handle inputs with or without time specifics.
   * @param {string} specificDateTime - The specific date and time (e.g., "2023-07-10 09:00" or "2023-07-10").
   * @param {string} specificTimeZone - The time zone for the specific date and time (e.g., "Asia/Tokyo").
   * @param {string} referenceTimeZone - The time zone to use for calculating the current time (e.g., "America/New_York").
   * @returns {string} - A human-readable string indicating how long ago or in the future the specific time is relative to the current time in the reference time zone.
   */
  function timeFromNowInTimeZone(specificDateTime: string) {
    const timeZone = profileSettings.timezone
      ? profileSettings.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const specificTime = moment.tz(specificDateTime, timeZone);
    const currentTime = moment().tz(timeZone)?.toLocaleString();
    const daysDifference = moment(currentTime).diff(specificDateTime, "days");
    if (daysDifference === 0) {
      return specificTime.from(currentTime);
    } else if (daysDifference === 1) {
      return "Yesterday";
    } else if (daysDifference < 7) {
      return `${daysDifference} days ago`;
    } else if (daysDifference < 30) {
      const weeksAgo = Math.floor(daysDifference / 7);
      return `${weeksAgo} ${weeksAgo === 1 ? "week" : "weeks"} ago`;
    } else if (daysDifference < 365) {
      const monthsAgo = Math.floor(daysDifference / 30);
      return `${monthsAgo} ${monthsAgo === 1 ? "month" : "months"} ago`;
    } else {
      const yearsAgo = Math.floor(daysDifference / 365);
      return `${yearsAgo} ${yearsAgo === 1 ? "year" : "years"} ago`;
    }
  }

  const timeSinceFormat = (
    message_date: string,
    message_time: string
  ): string => {
    const fullDateTime = `${message_date}T${message_time}`;
    return timeFromNowInTimeZone(fullDateTime);
  };

  const timeSince = (dateString: string): string => {
    return timeFromNowInTimeZone(dateString);
  };

  return {
    formatDate,
    formatDateToDaysAgo,
    timeSince,
    daysUntil,
    timeSinceFormat,
    FormatDateTime,
    formatTime,
    timeFromNowInTimeZone,
    extractDate,
  };
}

export default useDateFormatter;
