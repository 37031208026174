import { useDispatch } from "react-redux";
import { LayerBox } from "../index";
import {
  openAddVectorLayerScreen,
  openEditVectorLayerScreen,
} from "store/geography";
import {
  doFetchAllLayers,
  openViewVectorLayerScreen,
  saveAllLayersFetchedData,
  updateSelectedItemToZoom,
  updateSelectedLayersItems,
} from "store/geography/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Toasts } from "view/components/Toasts";
import { CurrentOpenToggleType, T_VectorLayersData } from "../LayersListing";
import usePermissions from "hooks/usePermissions";

interface I_VectorLayerBox {
  data: T_VectorLayersData[] | undefined;
  actionsList: {
    label: string;
  }[];
  global?: boolean;
  setEntityType?: Dispatch<SetStateAction<string | null>>;
  setPage?: Dispatch<SetStateAction<any>>;
  entityType?: null | string;
  page?: any;
  totalPages?: number;
  totalItems?: number;
  loading: boolean;
  currentOpenToggle: CurrentOpenToggleType;
  // setCurrentOpenToggle: Dispatch<SetStateAction<CurrentOpenToggleType>>;
}

const VectorLayerBox = ({
  data,
  actionsList,
  global,
  setEntityType,
  setPage,
  entityType,
  totalPages,
  page,
  totalItems,
  loading,
  currentOpenToggle,
  // setCurrentOpenToggle,
}: I_VectorLayerBox) => {
  const [isScrollEndEventTriggered, setIsScrollEndEventTriggered] =
    useState(false);
  const [inLoadingLayer, setInLoadingLayer] = useState<null | any>(null);

  const [layers, setLayers] = useState<T_VectorLayersData[] | undefined>();
  const [doShowDataList,setDoShowDataList] = useState(false);
  const { selectedLayersItems, layersDataType, allLayers } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();
  const { communities } = usePermissions();
  const dispatch = useDispatch();

  const heading = `${!totalItems || totalItems === 0 ? "" : totalItems} Vector Layers`;


  useEffect(()=>{
    const doShow = !global && currentOpenToggle === "vector"
    ? true
    : global && currentOpenToggle === "publicVector"
      ? true
      : false;

      setDoShowDataList(doShow);
  },[global,currentOpenToggle])

  useEffect(() => {
    setLayers([...(data ?? [])]);
  }, [data, layersDataType]);

  // handle scroll end event
  useEffect(() => {
    if (isScrollEndEventTriggered) {
      if (
        setPage &&
        setEntityType &&
        totalPages &&
        page &&
        totalPages >= page[global ? "publicVector" : "vector"] + 1
      ) {
        const key = global ? "publicVector" : "vector";

        setPage((prev: any) => ({
          ...prev,
          [key]: prev[key] + 1,
        }));

        if (entityType !== key) {
          setEntityType(key);
        }
      }

      setIsScrollEndEventTriggered(false);
    }
  }, [isScrollEndEventTriggered]);

  useEffect(() => {
    if (inLoadingLayer) {
      const { id, status } = inLoadingLayer;

      setLayers((prev) =>
        prev?.map((l: any) => {
          if (l.id === id) {
            l.loading = status;
          }
          return l;
        })
      );
    }
  }, [inLoadingLayer]);

  // handlers
  const updateLayerStatusAndFetch = (
    communityId: any,
    dataItemId: any,
    archive: any
  ) => {
    const updateFunction = communityId
      ? apiLibrary.geography.updateLayerStatus(communityId, dataItemId, archive)
      : apiLibrary.geography.updateGlobalLayerStatus(dataItemId, archive);
    return updateFunction.then(() => {
      const layersItems = selectedLayersItems || [];
      const itemIndex = layersItems.findIndex(
        (itm: any) => itm.item.id === dataItemId
      );

      if (itemIndex !== -1) {
        layersItems[itemIndex].status = false;
        dispatch(updateSelectedLayersItems([...layersItems]));
      }

      dispatch(doFetchAllLayers(true));
    });
  };
  const handleClickOnMenuAction = async (action: any, dataItem: any) => {
    const currentAction = action.label.toLowerCase();
    const selectedItem = selectedLayersItems?.find(
      (itm: any) => itm?.item?.id === dataItem?.id
    );

    switch (currentAction) {
      case "edit":
        // open edit screen of raster layer
        dispatch(openEditVectorLayerScreen({ dataItem, isGlobal: global }));
        break;

      case "view information":
        // open detail screen of raster layer
        dispatch(openViewVectorLayerScreen(dataItem));
        break;

      case "zoom to selection":
        // update selected item to be dominant on map
        dispatch(updateSelectedItemToZoom({ ...(selectedItem ?? {}) }));
        // dispatch(
        //   updateSelectedItemToZoom(item)
        // );
        break;
      case "archive":
        updateLayerStatusAndFetch(communityId, dataItem.id, true);
        break;
      case "restore":
        updateLayerStatusAndFetch(communityId, dataItem.id, false);
        break;
    }
  };
  const handleClickOnAddButton = () => {
    dispatch(openAddVectorLayerScreen());
  };
  const handleClickOnCheckBox = async (e: any, item: any) => {
    const isChecked = e.target.checked;
    const layersItems = selectedLayersItems ?? [];
    const itemIndex = layersItems.findIndex(
      (itm: any) => itm.item.id === item.id
    );

    const alreadyExistedItem = layersItems[itemIndex];
    let upadatedItems;

    if (isChecked) {
      if (alreadyExistedItem) {
        // update item status to true
        alreadyExistedItem.status = true;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
      } else {
        if (communityId && !global) {
          await apiLibrary.geography
            .getSingleLayer(communityId, item.id)
            .then((data) => {
              const newItem = {
                item: data?.data,
                itemType: item.type === "point" ? item.type : "vector",
                status: true,
              };
              upadatedItems = [...layersItems, newItem];
            })
            .catch((error) => {
              Toasts.error(error.message);
            })
            .finally(() => {
              setInLoadingLayer({ id: item.id, status: false });
            });
        } else {
          await apiLibrary.geography
            .getSingleGlobalLayer(item.id)
            .then((data) => {
              const newItem = {
                item: data?.data,
                itemType: item.type === "point" ? item.type : "vector",
                status: true,
              };
              upadatedItems = [...layersItems, newItem];
            })
            .catch((error) => {
              Toasts.error(error.message);
            })
            .finally(() => {
              setInLoadingLayer({ id: item.id, status: false });
            });
        }
      }
    } else {
      if (itemIndex === -1) return;
      // update item status to false on unchecking the checkbox
      alreadyExistedItem.status = false;

      layersItems[itemIndex] = alreadyExistedItem;
      upadatedItems = layersItems;
    }

    dispatch(updateSelectedLayersItems([...(upadatedItems ?? [])]));
  };
  const handleOnScrollEnd = () => {
    setIsScrollEndEventTriggered(true);
  };
  const handleClickOnTogglerBtn = () => {
    setDoShowDataList(!doShowDataList);
    // if (currentOpenToggle) {
    //   if (!global && currentOpenToggle !== "vector") {
    //     setCurrentOpenToggle("vector");
    //   } else if (global && currentOpenToggle !== "publicVector") {
    //     setCurrentOpenToggle("publicVector");
    //   }
    // }
  };
  const updateDataList = (newLayer: any) => {
    const key = global ? "publicVector" : "vector";
    const updatedLayers = allLayers[key]?.data?.map((l: any) => {
      if (newLayer.item.id === l.id) {
        return newLayer.item;
      }
      return l;
    });

    allLayers[key].data = updatedLayers;

    dispatch(saveAllLayersFetchedData({ ...(allLayers ?? {}) }));
    // setLayers(updatedLayers ?? []);
  };

  return (
    <LayerBox
      heading={heading}
      dataList={
        global || communities.canViewGeographyCommunities ? layers : null
      }
      actionList={actionsList}
      hideAddBtn={global}
      layerType="vector"
      loading={loading}
      doShowDataList={doShowDataList}
      isGlobal={global}
      updateDataList={updateDataList}
      handleOnScrollEnd={handleOnScrollEnd}
      handleClickOnMenuAction={handleClickOnMenuAction}
      handleClickOnAddButton={handleClickOnAddButton}
      handleClickOnCheckBox={handleClickOnCheckBox}
      handleClickOnTogglerBtn={handleClickOnTogglerBtn}
    />
  );
};

export { VectorLayerBox };
