import React, { useEffect, useMemo, useState } from "react";
import { snakeToCamel } from "utils/caseConvertor";
import {
  TextField,
  Dropdown,
  MultiselectDropdown,
  RadioButtonGroup,
} from "../../Components";
import useLocationFormatter from "hooks/useLocationFormatter";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import Map from "./Map";

interface SightingDetailsProps {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  handleUpdateStatus: any;
}

interface CoordinatesValue {
  direction?: string;
  degrees?: string;
  minutes?: string;
  seconds?: string;
}

const SightingDetails: React.FC<SightingDetailsProps> = ({
  panel,
  editMode,
  formikProps,
  handleUpdateStatus,
}) => {

  const [doShowMap, setDoShowMap] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState<any>([]);
  const { formatLocation } = useLocationFormatter();

  const onChange = (eleName: string, value: any, name: string) => {
    formikProps.setFieldValue(eleName, value)
    handleUpdateStatus(name, panel.sectionId)
  }

  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name);

  function convertDMS2DDWithDirection(
    degrees: string,
    direction: string,
    minutes: string,
    seconds: string = "0"
  ) {
    // Ensure that seconds is treated as "0" if it is undefined, null, or an empty string
    seconds = seconds || "0"; // Default to "0" if seconds is falsy (including empty string, null, or undefined)

    let decimalDegrees =
      Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600;

    if (direction === "S" || direction === "W") {
      decimalDegrees = -decimalDegrees; // Make it negative for southern hemisphere
    }

    return String(decimalDegrees);
  }

  function convertDD2DMSWithDirection(
    decimalDegrees: any,
    isLongitude = false
  ) {
    let direction;
    if (isLongitude) {
      if (decimalDegrees >= 0 && decimalDegrees <= 180) {
        direction = "E";
      } else {
        direction = "W";
        decimalDegrees = Math.abs(decimalDegrees);
      }
    } else {
      if (decimalDegrees >= 0) {
        direction = "N";
      } else {
        direction = "S";
        decimalDegrees = Math.abs(decimalDegrees);
      }
    }

    const degrees = Math.floor(decimalDegrees);
    const minutesFromDecimal = (decimalDegrees - degrees) * 60;
    const minutes = Math.floor(minutesFromDecimal);
    const seconds = Math.round((minutesFromDecimal - minutes) * 60); // Rounding seconds for simplicity

    return { degrees, minutes, seconds, direction };
  }

  const [latitudeAns, longitudeAns] = useMemo(() => {
    // Directly retrieve the values without parsing as float
    const lat = formikProps.values[panelName]?.["Latitude"];
    const lng = formikProps.values[panelName]?.["Longitude"];

    // Return the values directly
    return [lat, lng];
  }, [formikProps.values]);

  useEffect(() => {
    let latitudeDD, longitudeDD;

    // Check if latitude is an object to convert from DMS to DD, otherwise use it directly
    if (typeof latitudeAns === "object" && latitudeAns !== null) {
      latitudeDD = convertDMS2DDWithDirection(
        latitudeAns.degrees,
        latitudeAns.direction,
        latitudeAns.minutes,
        latitudeAns.seconds
      );
    } else {
      latitudeDD = latitudeAns; // Use directly if it's already a decimal degree or any other format
    }

    // Check if longitude is an object to convert from DMS to DD, otherwise use it directly
    if (typeof longitudeAns === "object" && longitudeAns !== null) {
      longitudeDD = convertDMS2DDWithDirection(
        longitudeAns.degrees,
        longitudeAns.direction,
        longitudeAns.minutes,
        longitudeAns.seconds
      );
    } else {
      longitudeDD = longitudeAns; // Use directly if it's already a decimal degree or any other format
    }

    // Set map coordinates
    setMapCoordinates([longitudeDD, latitudeDD]);
  }, [latitudeAns, longitudeAns]); // Dependencies on the parsed or converted coordinates

  const formatDecimal = (value: number): string => {
    const rounded = Math.round(value * 1000) / 1000; // Round to three decimal places
    return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(3);
  };

  const getFormattedCoordinates = (
    latitude: any,
    longitude: any,
    format: "DMS" | "DD" | "DMM"
  ) => {
    if (!latitude || !longitude) return "Coordinates not available";

    if (format === "DMS") {
      if (typeof latitude !== "object") {
        return `${formatLocation(latitude, format, false, "DDMS")}, ${formatLocation(longitude, format, true, "DDMS")}`;
      } else {
        return `${latitude.direction} ${formatDecimal(latitude.degrees)}° ${formatDecimal(latitude.minutes)}' ${formatDecimal(latitude.seconds)}", ${longitude.direction} ${formatDecimal(longitude.degrees)}° ${formatDecimal(longitude.minutes)}' ${formatDecimal(longitude.seconds)}"`;
      }
    } else if (format === "DMM") {
      if (typeof latitude !== "object") {
        return `${formatLocation(latitude, format, false, "DDMM")}, ${formatLocation(longitude, format, true, "DDMM")}`;
      } else {
        // Convert and calculate latitude minutes
        let formattedLatitudeMinutes =
          parseFloat(latitude.minutes) + latitude.seconds / 60;

        // Convert and calculate longitude minutes
        let formattedLongitudeMinutes =
          parseFloat(longitude.minutes) + longitude.seconds / 60;

        return `${latitude.direction} ${formatDecimal(latitude.degrees)}° ${formatDecimal(formattedLatitudeMinutes || 0)}', ${longitude.direction} ${formatDecimal(longitude.degrees)}° ${formatDecimal(formattedLongitudeMinutes || 0)}'`;
      }
    }
  };

  // Map for linking formats to their placeholders
  const metricsPlaceholderMap: any = {
    "Degrees, minutes, seconds": getFormattedCoordinates(
      formikProps.values[panelName]?.["Latitude"],
      formikProps.values[panelName]?.["Longitude"],
      "DMS"
    ),
    "Decimal degrees": "Pick on map / get GPS data",
    "Degrees, decimal minutes": getFormattedCoordinates(
      formikProps.values[panelName]?.["Latitude"],
      formikProps.values[panelName]?.["Longitude"],
      "DMM"
    ),
  };

  let coordinatesPlaceholders: string[];


  return (
    <>
      <div className="grid grid-cols-12 gap-4 mb-5">
        {panelElements.map((element: any, index: number) => {
          const { name, title, choices, fieldAs, rows, value, placeholder, inputType, type, isRequired = false, visible = true, choicesByUrl, defaultValue, colCount } = element;
          const eleName = [panelName, element.name].join(".");
          const error = formikProps.errors[panelName]?.[element.name];
          const touched = formikProps.touched[panelName]?.[name];
          if (visible === false || type === "html" || defaultValue) {
            return;
          }


          switch (element.type) {

            case "radiogroup":
              if (name === "CoordinatesFormat") {
                coordinatesPlaceholders = choices?.map(
                  (format: any) =>
                    metricsPlaceholderMap[format?.value || format] ||
                    "Unknown format"
                );
              }

              return (
                <div className="col-span-12">
                  <RadioButtonGroup
                    key={index}
                    label={title}
                    name={eleName}
                    choicesByUrl={choicesByUrl}
                    editMode={editMode}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(item: any) => {
                      // const updatedAnswer = choices?.find(
                      //   (itemm: any) => itemm.value === item
                      // );
                      onChange(eleName, item, name);
                    }}
                    data={choices}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    dataPlaceholders={
                      name === "CoordinatesFormat" ? coordinatesPlaceholders : []
                    }
                    columns={colCount}
                    panel={panel}
                    element={element}
                  />
                </div>
              );

            case "text":
              const metrics = formikProps.values[panelName]?.["CoordinatesFormat"];
              // If coordinates format includes degrees, minutes or seconds
              const isDegreesMinutesSeconds =
                metrics &&
                (typeof metrics === "object"
                  ? metrics.value === "Degrees, minutes, seconds"
                  : metrics === "Degrees, minutes, seconds");
              const isDegreesMinutes =
                metrics &&
                (typeof metrics === "object"
                  ? metrics.value === "Degrees, decimal minutes"
                  : metrics === "Degrees, decimal minutes");

              if (
                (name === "Latitude" || name === "Longitude") &&
                (isDegreesMinutesSeconds || isDegreesMinutes)
              ) {
                let latLngDirections;

                if (name === "Latitude") {
                  latLngDirections = ["N", "S"];
                } else if (name === "Longitude") {
                  latLngDirections = ["E", "W"];
                }

                const value = formikProps.values[panelName]?.[name];

                // Construct the string by checking each part for undefined before adding it to the result
                const formatCoordinates = (
                  value: CoordinatesValue,
                  showSeconds: boolean
                ) => {
                  let result = [];

                  if (value?.direction) result.push(value.direction);
                  if (value?.degrees) result.push(`${value.degrees}°`);
                  if (value?.minutes) result.push(`${value.minutes}'`);
                  if (value?.seconds && showSeconds)
                    result.push(`${value.seconds}''`);

                  return result.join(" ");
                };

                if (typeof value !== "object") {
                  const newValue =
                    name === "Latitude"
                      ? convertDD2DMSWithDirection(value, false)
                      : convertDD2DMSWithDirection(value, true);
                  const { direction, degrees, minutes, seconds } = newValue;
                  const coordinatesObject = {
                    direction: String(direction),
                    degrees: String(degrees),
                    minutes: String(minutes),
                    seconds: String(seconds),
                  };
                  onChange(eleName, coordinatesObject, name);
                } else if (value && typeof value === "object") {
                  if (value) {
                    if (isDegreesMinutes) {
                      value.showSeconds = false;
                    } else {
                      value.showSeconds = true;
                    }
                  }
                }

                // Handler to update parts of DMS and validate them collectively
                const handleDMSChange = (part: any, val: any) => {
                  const sanitizedVal = val.replace(/-/g, "");

                  const newValue = { ...value, [part]: sanitizedVal };
                  const degrees = parseFloat(newValue.degrees || "0");
                  const minutes = parseFloat(newValue.minutes || "0") / 60;
                  const seconds = parseFloat(newValue.seconds || "0") / 3600;

                  // Calculate total degrees
                  let totalDegrees = degrees + minutes + seconds;
                  if (
                    newValue.direction === "S" ||
                    newValue.direction === "W"
                  ) {
                    totalDegrees = -totalDegrees;
                  }

                  // Validate total degrees against latitude or longitude limits
                  if (name === "Latitude" && Math.abs(totalDegrees) > 90) {
                    return; // Prevent update if latitude out of bounds
                  } else if (
                    name === "Longitude" &&
                    Math.abs(totalDegrees) > 180
                  ) {
                    return; // Prevent update if longitude out of bounds
                  }

                  if (isDegreesMinutes) {
                    newValue.seconds = "";
                  }

                  onChange(eleName, newValue, name); // Update value if valid
                };

                return (
                  <div
                    className={`col-span-6 items-end bg-bgPrimaryLight p-4 rounded-lg`}
                  >
                    {editMode ? (
                      <p className="w-full pb-1 font-semibold text-left capitalize text-md font-Overpass text-primary dark:text-caption ">
                        {title || name} {isRequired && <span>*</span>}
                      </p>
                    ) : (
                      <p className="w-full pb-1 text-left capitalize text-md font-Overpass font-regular text-primary dark:text-caption ">
                        {title || name} {isRequired && <span>*</span>}
                      </p>
                    )}

                    {editMode ? (
                      <div
                        className={`flex items-start lat-lng-fields col-span-6 gap-2 grid grid-cols-${isDegreesMinutesSeconds ? "4" : "3"}`}
                      >
                        <Dropdown
                          key={index}
                          name="direction"
                          label="Direction"
                          choices={latLngDirections}
                          choicesByUrl={choicesByUrl}
                          value={value?.direction}
                          handleChange={(item: any) =>
                            handleDMSChange("direction", item)
                          }
                          handleBlur={() =>
                            formikProps.setFieldTouched(eleName, true, true)
                          }
                          editMode={editMode}
                          required={isRequired}
                          error={!value?.direction && "Direction is required"}
                          touched={!value?.direction && true}
                          labelSize="sm"
                          panel={panel}
                          element={element}
                        />
                        <TextField
                          key={index}
                          type="number"
                          name="degrees"
                          label="Degrees"
                          value={value?.degrees && parseFloat(value?.degrees)}
                          handleChange={(value: any) =>
                            handleDMSChange("degrees", value)
                          }
                          handleBlur={() =>
                            formikProps.setFieldTouched(eleName, true, true)
                          }
                          fieldAs={fieldAs}
                          rows={rows}
                          editMode={editMode}
                          placeholder="Type"
                          required={isRequired}
                          error={!value?.degrees && "Degrees are required"}
                          touched={!value?.degrees && true}
                          labelSize="sm"
                          panel={panel}
                          element={element}
                        />
                        <TextField
                          key={index}
                          type="number"
                          name="minutes"
                          label="Minutes"
                          value={
                            value?.minutes &&
                            parseFloat(value?.minutes) +
                            (isDegreesMinutes && value?.seconds
                              ? parseFloat(value?.seconds) / 60
                              : 0)
                          }
                          handleChange={(value: any) => {
                            handleDMSChange("minutes", value);
                          }}
                          handleBlur={() =>
                            formikProps.setFieldTouched(eleName, true, true)
                          }
                          fieldAs={fieldAs}
                          rows={rows}
                          editMode={editMode}
                          placeholder="Type"
                          required={isRequired}
                          error={!value?.minutes && "Minutes are required"}
                          touched={!value?.minutes && true}
                          labelSize="sm"
                          panel={panel}
                          element={element}
                        />
                        {isDegreesMinutesSeconds && (
                          <TextField
                            key={index}
                            type="number"
                            name="seconds"
                            label="Seconds"
                            value={value?.seconds && parseFloat(value?.seconds)}
                            handleChange={(value: any) =>
                              handleDMSChange("seconds", value)
                            }
                            handleBlur={() =>
                              formikProps.setFieldTouched(eleName, true, true)
                            }
                            fieldAs={fieldAs}
                            rows={rows}
                            editMode={editMode}
                            placeholder="Type"
                            required={isRequired}
                            error={!value?.seconds && "Seconds are required"}
                            touched={!value?.seconds && true}
                            labelSize="sm"
                            panel={panel}
                            element={element}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p className="w-full max-w-full max-h-32 overflow-hidden  text-overflow-ellipsis whitespace-pre-line text-[15px] font-Overpass text-left font-normal text-textAnswer dark:text-textMain">
                          {formatCoordinates(value, isDegreesMinutesSeconds)}
                        </p>
                      </div>
                    )}
                  </div>
                );
              } else {
                const value = formikProps.values[panelName]?.[name];
                const isLatOrLong = name === "Latitude" || name === "Longitude";
                if (isLatOrLong && value && typeof value === "object") {
                  const { degrees, direction, minutes, seconds } = value;
                  onChange(
                    eleName,
                    convertDMS2DDWithDirection(
                      degrees,
                      direction,
                      minutes,
                      seconds
                    ),
                    name
                  );
                }

                return (
                  <div
                    className={`${name == "Latitude" ||
                      name == "Longitude" ||
                      name == "AltitudeAccuracy"
                      ? "col-span-6"
                      : "col-span-12"
                      }`}
                  >
                    <TextField
                      key={index}
                      label={title}
                      type={
                        inputType || name == "Latitude" || name == "Longitude"
                          ? "number"
                          : type
                      }
                      name={eleName}
                      value={formikProps.values[panelName]?.[name]}
                      handleChange={(value: any) => {
                        let newValue = value;

                        // Validate input for Latitude
                        if (name === "Latitude") {
                          const parsedValue = parseFloat(newValue);
                          if (
                            !isNaN(parsedValue) &&
                            (parsedValue < -90 || parsedValue > 90)
                          ) {
                            return; // Ignore input if it's out of the latitude range
                          }
                        }

                        // Validate input for Longitude
                        if (name === "Longitude") {
                          const parsedValue = parseFloat(newValue);
                          if (
                            !isNaN(parsedValue) &&
                            (parsedValue < -180 || parsedValue > 180)
                          ) {
                            return; // Ignore input if it's out of the longitude range
                          }
                        }

                        // Update the formik state with the new value
                        onChange(eleName, newValue, name);
                      }}
                      handleBlur={() =>
                        formikProps.setFieldTouched(eleName, true, true)
                      }
                      fieldAs={fieldAs}
                      rows={rows}
                      editMode={editMode}
                      placeholder={placeholder || `Enter ${title}`}
                      required={isRequired}
                      error={error}
                      touched={touched}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );
              }

            case "dropdown":
              return (
                <div className="col-span-12"
                >
                  <Dropdown
                    key={index}
                    label={title}
                    name={name}
                    choices={choices}
                    choicesByUrl={choicesByUrl}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(item: any) =>
                      onChange(eleName, item, name)
                    }
                    handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
                    editMode={editMode}
                    placeholder={placeholder || `Select ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    panel={panel}
                    element={element}
                  />
                </div>
              );
              return (
                <div className="col-span-6">
                  <TextField
                    key={index}
                    label={title}
                    type={inputType || type}
                    name={name}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) => onChange(eleName, value, name)}
                    handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
                    fieldAs={fieldAs}
                    rows={rows}
                    editMode={editMode}
                    placeholder={placeholder || `Enter ${title}`}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                </div>
              );

            default:
              return null; // Or render a default component for unknown types
          }
        })}

        <div className="flex items-center col-span-6 mb-4">
          <CustomizedSwitches
            label="View map"
            onChange={(e: any) => setDoShowMap(e.target.checked)}
            labelClassName="text-secondary"
          />
        </div>
        {doShowMap && (
          <div className="col-span-12">
            <Map coordiantes={mapCoordinates} editMode={editMode} />
          </div>
        )}

      </div>
    </>
  );
};

export default SightingDetails;
