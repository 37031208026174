import * as actionTypes from "./action-types";
import { Dispatch } from "redux";

// DASHBOARD DIRECTORY FILTERS ACTIONS

/**
 * Action creator for filtering dashboard by date period date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const dashboardDatePeriodAction = (from: string, to: string) => {
  return {
    type: actionTypes.DASHBOARD_DATE_PERIOD,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering dashboard by form.
 *
 * @param {any} form - The form to filter by.
 * @returns {Object} Action object.
 */
export const dashboardFormsAction = (form: any) => {
  return {
    type: actionTypes.DASHBOARD_FORMS,
    payload: form,
  };
};

/**
 * Action creator for filtering dashboard by organizations.
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const dashboardOrganizationsAction = (organization: any) => {
  return {
    type: actionTypes.DASHBOARD_ORGANIZATIONS,
    payload: organization,
  };
};
/**
 * Action creator for filtering dashboard by communities.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const dashboardCommunitiesAction = (community: any) => {
  return {
    type: actionTypes.DASHBOARD_COMMUNITIES,
    payload: community,
  };
};

/**
 * Action creator for filtering dashboard by Programs.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const dashboardProgramsAction = (program: any) => {
  return {
    type: actionTypes.DASHBOARD_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator to reset all dashboard filters.
 *
 * @returns {Object} Action object.
 */
export const dashboardResetFiltersAction = () => {
  return {
    type: actionTypes.DASHBOARD_RESET_FILTERS,
  };
};

export const setDashboardFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_DASHBOARD_FILTERS,
  };
};
// USERS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting users.
 *
 * @param {string} sortBy - The field to sort users by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const usersSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.USERS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching users.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const usersSearchAction = (searchString: string, searchBy?: string) => {
  return {
    type: actionTypes.USERS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering users by Last Active date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const usersLastActiveAction = (from: string, to: string) => {
  return {
    type: actionTypes.USERS_LAST_ACTIVE,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering users by member since date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const usersMemberSinceAction = (from: string, to: string) => {
  return {
    type: actionTypes.USERS_MEMBER_SINCE,
    payload: {
      from,
      to,
    },
  };
};
/**
 * Action creator for filtering users by member since date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const usersOpenAccessAction = (data?: any) => {
  return {
    type: actionTypes.USERS_OPEN_ACCESS,
    payload: data,
  };
};

/**
 * Action creator for filtering users by program observations.
 *
 * @param {string} name - The name of the program.
 * @param {any} min - Minimum observations count.
 * @param {any} max - Maximum observations count.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const userFormsObservationsAction = (
  name: string,
  min: any,
  max: any,
  applied = false
) => {
  return {
    type: actionTypes.USERS_FORMS_OBSERVATIONS,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

/**
 * Action creator for filtering users by communities.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const usersCommunitiesAction = (community: any) => {
  return {
    type: actionTypes.USERS_COMMUNITIES,
    payload: community,
  };
};

/**
 * Action creator for filtering users by Programs.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const usersProgramsAction = (program: any) => {
  return {
    type: actionTypes.USERS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering users by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const usersStatusAction = (status: string) => {
  return {
    type: actionTypes.USERS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting user pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const usersPaginationAction = (page: number) => {
  return {
    type: actionTypes.USERS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting user items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const usersLimitAction = (limit: number) => {
  return {
    type: actionTypes.USERS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all user filters.
 *
 * @returns {Object} Action object.
 */
export const usersResetFiltersAction = () => {
  return {
    type: actionTypes.USERS_RESET_FILTERS,
  };
};

/**
 * Action creator to reset all user filters except for community.
 *
 * @returns {Object} Action object.
 */
export const usersResetFiltersExceptCommunityAction = () => {
  return {
    type: actionTypes.USERS_RESET_EXCEPT_COMMUNITY_FILTERS,
  };
};

/**
 * Action creator to reset all user filters except for program.
 *
 * @returns {Object} Action object.
 */
export const usersResetFiltersExceptProgramAction = () => {
  return {
    type: actionTypes.USERS_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};

export const setUsersFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_USERS_FILTERS,
  };
};

// COMMUNITIES DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting communities.
 *
 * @param {string} sortBy - The field to sort communities by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const communitiesSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.COMMUNITIES_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching communities.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const communitiesSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.COMMUNITIES_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering communities by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const communitiesStatusAction = (status: string) => {
  return {
    type: actionTypes.COMMUNITIES_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting community pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const communitiesPaginationAction = (page: number) => {
  return {
    type: actionTypes.COMMUNITIES_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting community items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const communitiesLimitAction = (limit: number) => {
  return {
    type: actionTypes.COMMUNITIES_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters except for organization.
 *
 * @returns {Object} Action object.
 */
export const communitiesResetFiltersExceptOrganizationAction = () => {
  return {
    type: actionTypes.COMMUNITIES_RESET_EXCEPT_ORGANIZATION_FILTERS,
  };
};

/**
 * Action creator to reset all community filters except for Program.
 *
 * @returns {Object} Action object.
 */
export const communitiesResetFiltersExceptProgramAction = () => {
  return {
    type: actionTypes.COMMUNITIES_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};

/**
 * Action creator for filtering communities by programs and members.
 *
 * @param {string} name - The name of the program.
 * @param {any} min - Minimum members count.
 * @param {any} max - Maximum members count.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const communitiesMembersAction = (
  name: string,
  min: any,
  max: any,
  applied = false
) => {
  return {
    type: actionTypes.COMMUNITIES_MEMBERS,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

/**
 * Action creator to reset all community filters.
 *
 * @returns {Object} Action object.
 */
export const communitiesResetFiltersAction = () => {
  return {
    type: actionTypes.COMMUNITIES_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering communities by organization
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const communitiesOrganizationsAction = (organization: any) => {
  return {
    type: actionTypes.COMMUNITIES_ORGANIZATIONS,
    payload: organization,
  };
};

/**
 * Action creator for filtering communities by program
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const communitiesProgramAction = (program: any) => {
  return {
    type: actionTypes.COMMUNITIES_PROGRAM,
    payload: program,
  };
};

/**
 * Action creator for filtering communities by organization
 *
 * @param {any} tags - The tags to filter by.
 * @returns {Object} Action object.
 */
export const communitiesTagsAction = (tags: any) => {
  return {
    type: actionTypes.COMMUNITIES_TAGS,
    payload: tags,
  };
};

/**
 * Action creator for filtering communities by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const communitiesDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.COMMUNITIES_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering communities by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const communitiesTypeAction = (type: string) => {
  return {
    type: actionTypes.COMMUNITIES_TYPE,
    payload: type,
  };
};

/**
 * Action creator for filtering communities by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const communitiesViewTypeAction = (type: string) => {
  return {
    type: actionTypes.COMMUNITIES_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator for setting program view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const setCommunitiesFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_COMMUNITIES_FILTERS,
  };
};

/// ORGANIZATIONS  FILTERS ACTIONS

/**
 * Action creator for sorting orgzanizations.
 *
 * @param {string} sortBy - The field to sort orgzanizations by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const orgzanizationsSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.ORGANIZATIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching orgzanizations.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const orgzanizationsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.ORGANIZATIONS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering orgzanizations by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const organizationsStatusAction = (status: string) => {
  return {
    type: actionTypes.ORGANIZATIONS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting community pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const orgzanizationsPaginationAction = (page: number) => {
  return {
    type: actionTypes.ORGANIZATIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting community items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const orgzanizationsLimitAction = (limit: number) => {
  return {
    type: actionTypes.ORGANIZATIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters except for organization.
 *
 * @returns {Object} Action object.
 */
export const orgzanizationsResetFiltersExceptCommunityAction = () => {
  return {
    type: actionTypes.ORGANIZATIONS_RESET_EXCEPT_COMMUNITY_FILTERS,
  };
};

/**
 * Action creator to reset all community filters except for Program.
 *
 * @returns {Object} Action object.
 */
export const orgzanizationsResetFiltersExceptProgramAction = () => {
  return {
    type: actionTypes.ORGANIZATIONS_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};

/**
 * Action creator for filtering orgzanizations by programs and members.
 *
 * @param {string} name - The name of the program.
 * @param {any} min - Minimum members count.
 * @param {any} max - Maximum members count.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const orgzanizationsMembersAction = (
  name: string,
  min: any,
  max: any,
  applied = false
) => {
  return {
    type: actionTypes.ORGANIZATIONS_MEMBERS,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

/**
 * Action creator to reset all organizations filters.
 *
 * @returns {Object} Action object.
 */
export const orgzanizationsResetFiltersAction = () => {
  return {
    type: actionTypes.ORGANIZATIONS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering orgzanizations by organization
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const orgzanizationsCommunitiesAction = (organization: any) => {
  return {
    type: actionTypes.ORGANIZATIONS_COMMUNITIES,
    payload: organization,
  };
};

/**
 * Action creator for filtering orgzanizations by program
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const orgzanizationsProgramAction = (program: any) => {
  return {
    type: actionTypes.ORGANIZATIONS_PROGRAM,
    payload: program,
  };
};

/**
 * Action creator for filtering orgzanizations by organization
 *
 * @param {any} tags - The tags to filter by.
 * @returns {Object} Action object.
 */
export const orgzanizationsTagsAction = (tags: any) => {
  return {
    type: actionTypes.ORGANIZATIONS_TAGS,
    payload: tags,
  };
};

/**
 * Action creator for filtering orgzanizations by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const orgzanizationsDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.ORGANIZATIONS_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering orgzanizations by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const orgzanizationsTypeAction = (type: string) => {
  return {
    type: actionTypes.ORGANIZATIONS_TYPE,
    payload: type,
  };
};

/**
 * Action creator for filtering orgzanizations by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const orgzanizationsViewTypeAction = (type: string) => {
  return {
    type: actionTypes.ORGANIZATIONS_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator for setting program view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const setOrgzanizationsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_ORGANIZATIONS_FILTERS,
  };
};

/// dynamic forms

/**
 * Action creator for sorting dynamic forms.
 *
 * @param {string} sortBy - The field to sort dynamic forms by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const dynamicFormsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching dynamic forms.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const dynamicFormsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering dynamic forms by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const dynamicFormsStatusAction = (status: string) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting dynamic forms pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const dynamicFormsPaginationAction = (page: number) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting dynamic forms items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const dynamicFormsLimitAction = (limit: number) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all dynamic forms filters.
 *
 * @returns {Object} Action object.
 */
export const dynamicFormsResetFiltersAction = () => {
  return {
    type: actionTypes.DYNAMIC_FORMS_RESET_FILTERS,
  };
};

/**
 * Action creator to reset all dynamic forms filters.
 *
 * @returns {Object} Action object.
 */
export const dynamicFormsResetFilterExceptProgramsAction = () => {
  return {
    type: actionTypes.DYNAMIC_FORMS_RESET_EXCEPT_PROGRAMS_FILTERS,
  };
};

/**
 * Action creator for filtering dynamic forms by programs.
 *
 * @param {object} program - The program to add or remove from filters.
 * @returns {Object} Action object.
 */
export const dynamicFormsProgramsAction = (program: any) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering dynamic forms by programs.
 *
 * @param {object} program - The program to add or remove from filters.
 * @returns {Object} Action object.
 */
export const dynamicFormsProtocolAction = (protocol: any) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_PROTOCOLS,
    payload: protocol,
  };
};

/**
 * Action creator for filtering dynamic forms by created by.
 *
 * @param {any} createdBy - The created by filter value.
 * @returns {Object} Action object.
 */
export const dynamicFormsCreatedByAction = (createdBy: any) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_CREATED_BY,
    payload: createdBy,
  };
};

/**
 * Action creator for filtering dynamic forms by archived by.
 *
 * @param {any} archivedBy - The archived by filter value.
 * @returns {Object} Action object.
 */
export const dynamicFormsArchivedByAction = (archivedBy: any) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_ARCHIVED_BY,
    payload: archivedBy,
  };
};

/**
 * Action creator for filtering dynamic forms by date created, updated, or archived.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateCreated', 'dateUpdated', or 'dateArchived').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const dynamicFormsDateCreatedUpdatedArchivedAction = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_DATE_CREATED_UPDATED_ARCHIVED,
    payload: {
      name,
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering dynamic forms by observations.
 *
 * @param {string} name - The name of the observation filter.
 * @param {number} min - Minimum number of observations.
 * @param {number} max - Maximum number of observations.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const dynamicFormsObservationsAction = (
  name: string,
  min: number,
  max: number,
  applied = false
) => {
  return {
    type: actionTypes.DYNAMIC_FORMS_OBSERVASTIONS,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

export const dynamicFormsCommunitiesAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.DYNAMIC_FORMS_COMMUNITY,
    payload: community,
  };
};

export const setDynamicFormsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_DYNAMIC_FORMS_FILTERS,
  };
};

/**
 * Action creator for sorting programs.
 *
 * @param {string} sortBy - The field to sort programs by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const programsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.PROGRAMS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching programs.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const programsSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.PROGRAMS_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for filtering programs by organization
 *
 * @param {any} tags - The tags to filter by.
 * @returns {Object} Action object.
 */
export const programsTagsAction = (tags: any) => {
  return {
    type: actionTypes.PROGRAMS_TAGS,
    payload: tags,
  };
};

/**
 * Action creator for filtering programs by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const programsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.PROGRAMS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting program pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const programsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.PROGRAMS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting program items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const programsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.PROGRAMS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all program filters except for organization.
 *
 * @returns {Object} Action object.
 */
export const programsResetFiltersExceptOrganizationAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.PROGRAMS_RESET_EXCEPT_ORGANIZATION_FILTERS,
  };
};
/**
 * Action creator to reset all program filters except for organization.
 *
 * @returns {Object} Action object.
 */
export const programsResetFiltersExceptCommunityAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.PROGRAMS_RESET_EXCEPT_COMMUNITY_FILTERS,
  };
};
/**
 * Action creator for filtering programs by programs and members.
 *
 * @param {string} name - The name of the program.
 * @param {any} min - Minimum members count.
 * @param {any} max - Maximum members count.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const programsMembersObservationsFormsAction = (
  name: string,
  min: any,
  max: any,
  applied = false
): {
  type: string;
  payload: { name: string; min: any; max: any; applied: boolean };
} => {
  return {
    type: actionTypes.PROGRAMS_MEMBERS_OBSERVATIONS_FORMS,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

/**
 * Action creator to reset all program filters.
 *
 * @returns {Object} Action object.
 */
export const programsResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.PROGRAMS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering programs by organization.
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const programsOrganizationsAction = (
  organization: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.PROGRAMS_ORGANIZATIONS,
    payload: organization,
  };
};

/**
 * Action creator for filtering programs by community.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const programsCommunityAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.PROGRAMS_COMMUNITY,
    payload: community,
  };
};

/**
 * Action creator for filtering programs by Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const programsTimeFrameAction = (
  from: string,
  to: string
): { type: string; payload: { from: string; to: string } } => {
  return {
    type: actionTypes.PROGRAMS_TIMEFRAME,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for setting program view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const programsViewTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.PROGRAMS_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator for setting program view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const setProgramFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_PROGRAMS_FILTERS,
  };
};

/**
 * Action creator for sorting Protocols.
 *
 * @param {string} sortBy - The field to sort Protocols by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const protocolsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.PROTOCOLS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching Protocols.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const protocolsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.PROTOCOLS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering Protocols by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const protocolsStatusAction = (status: string) => {
  return {
    type: actionTypes.PROTOCOLS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting Protocols pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const protocolsPaginationAction = (page: number) => {
  return {
    type: actionTypes.PROTOCOLS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Protocols items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const protocolsLimitAction = (limit: number) => {
  return {
    type: actionTypes.PROTOCOLS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all Protocols filters.
 *
 * @returns {Object} Action object.
 */
export const protocolsResetFiltersAction = () => {
  return {
    type: actionTypes.PROTOCOLS_RESET_FILTERS,
  };
};

/**
 * Action creator to reset all Protocols filters.
 *
 * @returns {Object} Action object.
 */
export const protocolsResetFilterExceptProgramsAction = () => {
  return {
    type: actionTypes.PROTOCOLS_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};

/**
 * Action creator to reset all Protocols filters.
 *
 * @returns {Object} Action object.
 */
export const protocolsResetFilterExceptFormsAction = () => {
  return {
    type: actionTypes.PROTOCOLS_RESET_EXCEPT_FORM_FILTERS,
  };
};

/**
 * Action creator for filtering Protocols by programs.
 *
 * @param {object} program - The program to add or remove from filters.
 * @returns {Object} Action object.
 */
export const protocolsProgramsAction = (program: any) => {
  return {
    type: actionTypes.PROTOCOLS_PROGRAM,
    payload: program,
  };
};

/**
 * Action creator for filtering Protocols by forms.
 *
 * @param {object} form - The form to add or remove from filters.
 * @returns {Object} Action object.
 */
export const protocolsFormsAction = (form: any) => {
  return {
    type: actionTypes.PROTOCOLS_FORM,
    payload: form,
  };
};

/**
 * Action creator for filtering Protocols by created by.
 *
 * @param {any} createdBy - The created by filter value.
 * @returns {Object} Action object.
 */
export const protocolsCreatedByAction = (createdBy: any) => {
  return {
    type: actionTypes.PROTOCOLS_CREATED_BY,
    payload: createdBy,
  };
};

/**
 * Action creator for filtering Protocols by archived by.
 *
 * @param {any} archivedBy - The archived by filter value.
 * @returns {Object} Action object.
 */
export const protocolsArchivedByAction = (archivedBy: any) => {
  return {
    type: actionTypes.PROTOCOLS_ARCHIVED_BY,
    payload: archivedBy,
  };
};

/**
 * Action creator for filtering Protocols by deleted by.
 *
 * @param {any} deletedBy - The deleted by filter value.
 * @returns {Object} Action object.
 */
export const protocolsDeletedByAction = (deletedBy: any) => {
  return {
    type: actionTypes.PROTOCOLS_DELETED_BY,
    payload: deletedBy,
  };
};

/**
 * Action creator for filtering Protocols by updated by.
 *
 * @param {any} updatedBy - The updated by filter value.
 * @returns {Object} Action object.
 */
export const protocolsUpdatedByAction = (updatedBy: any) => {
  return {
    type: actionTypes.PROTOCOLS_UPDATED_BY,
    payload: updatedBy,
  };
};

/**
 * Action creator for filtering Protocols by date created, updated, or archived.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateCreated', 'dateUpdated', or 'dateArchived').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const protocolsDateCreatedUpdatedArchivedDeletedAction = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: actionTypes.PROTOCOLS_DATE_CREATED_UPDATED_ARCHIVED_DELETED,
    payload: {
      name,
      from,
      to,
    },
  };
};

export const setProtocolsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_PROTOCOLS_FILTERS,
  };
};

/**
 * Action creator to reset all program filters.
 *
 * @returns {Object} Action object.
 */
// export const protocolResetFiltersAction = (): { type: string } => {
//   return {
//     type: actionTypes.resetproto,
//   };
// };
/**
 * Action creator for sorting observations.
 *
 * @param {string} sortBy - The field to sort observations by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const observationsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.OBSERVATIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching observations.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const observationsSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.OBSERVATIONS_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for filtering observations by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const observationsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.OBSERVATIONS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting observations pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const observationsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.OBSERVATIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting observations items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const observationsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.OBSERVATIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all observations filters except for program
 *
 * @returns {Object} Action object.
 */
export const observationsResetFiltersExceptProgramAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.OBSERVATIONS_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};
/**
 * Action creator to reset all observations filters except for community.
 *
 * @returns {Object} Action object.
 */
export const observationsResetFiltersExceptCommunityAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.OBSERVATIONS_RESET_EXCEPT_COMMUNITY_FILTERS,
  };
};

/**
 * Action creator to reset all observations filters.
 *
 * @returns {Object} Action object.
 */
export const observationsResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.OBSERVATIONS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering observations by observer.
 
 * @param {any} observer - The observer to filter by.
 * @returns {Object} Action object.
 */
export const observationsObserverAction = (
  observer: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBSERVATIONS_OBSERVER,
    payload: observer,
  };
};

/**
 * Action creator for filtering observations by program.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const observationsProgramsAction = (
  program: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBSERVATIONS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering observations by community.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const observationsCommunityAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBSERVATIONS_COMMUNITY,
    payload: community,
  };
};

/**
 * Action creator for filtering observations by form.

 * @param {any} form - The form to filter by.
 * @returns {Object} Action object.
 */
export const observationsFormAction = (
  form: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBSERVATIONS_FORMS,
    payload: form,
  };
};

/**
 * Action creator for filtering observations by species.
 *
 * @param {any} species - The species to filter by.
 * @returns {Object} Action object.
 */
export const observationsSpeciesAction = (
  species: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBSERVATIONS_SPECIES,
    payload: species,
  };
};

/**
 * Action creator for filtering observations by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const observationsDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.OBSERVATIONS_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for setting observations view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const observationsViewTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.OBSERVATIONS_VIEW_TYPE,
    payload: type,
  };
};

export const setObservationsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_OBSERVATIONS_FILTERS,
  };
};

/**
 * Action creator for Searching messages in the chat.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const messagesSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.MESSAGES_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for setting observations pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const messagesPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.MESSAGES_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting observations items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const messagesLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.MESSAGES_ITEMS_LIMIT,
    payload: limit,
  };
};

export const messagesFilterReset = (): { type: string } => {
  return {
    type: actionTypes.MESSAGES_RESET_FILTER,
  };
};

/**
 * Action creator for Searching contact persons.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const messagesContactPersonSearchAction = (
  searchString: string
): { type: string; payload: { searchString: string } } => {
  return {
    type: actionTypes.MESSAGES_CONTACT_PERSON_SEARCH,
    payload: {
      searchString,
    },
  };
};

/**
 * Action creator for filtering messages by status (Contact List).
 *
 * @param {boolean} archived - The status to filter by.
 * @returns {Object} Action object.
 */
export const messagesStatusAction = (
  archived: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.MESSAGES_STATUS,
    payload: archived,
  };
};

/**
 * Action creator for filtering messages by status New Chat.
 *
 * @param {boolean} newChat - The status to filter by.
 * @returns {Object} Action object.
 */
export const messagesNewChatAction = (
  newChat: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.MESSAGES_NEW_CHAT,
    payload: newChat,
  };
};

/**
 * Action creator for filtering messages by APP Id.
 *
 * @param {boolean} appId - The status to filter by.
 * @returns {Object} Action object.
 */
export const messagesAppIdAction = (
  appId: string
): { type: string; payload: string } => {
    return {
    type: actionTypes.MESSAGES_APP_ID,
    payload: appId,
  };
};

/**
 * Action creator for sorting QA observations.
 *
 * @param {string} sortBy - The field to sort QA observations by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching QA observations.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for filtering QA observations by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting QA observations pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting QA observations items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all QA observations filters.
 *
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering QA observations by status.
 *
 * @param {any} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsFilterStatusAction = (
  status: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_FILTER_STATUS,
    payload: status,
  };
};

/**
 * Action creator for filtering QA observations by observer.
 
 * @param {any} observer - The observer to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsObserverAction = (
  observer: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_OBSERVER,
    payload: observer,
  };
};

/**
 * Action creator for filtering QA observations by program.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsProgramsAction = (
  program: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering QA observations by community.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsCommunityAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_COMMUNITY,
    payload: community,
  };
};

/**
 * Action creator for filtering QA observations by reviewer.

 * @param {any} reviewer - The reviewer to filter by.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsReviewerAction = (
  reviewer: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_REVIEWER,
    payload: reviewer,
  };
};

/**
 * Action creator for filtering QA observations by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsDateUpdatedAction = (
  from: string,
  to: string
) => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_DATE_UPDATED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for setting QA observations view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const qualityAssuranceObservationsViewTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_VIEW_TYPE,
    payload: type,
  };
};

export const setQualityAssuranceObservationsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_QUALITY_ASSURANCE_OBSERVATIONS_FILTERS,
  };
};

// MANAGER DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting users.
 *
 * @param {string} sortBy - The field to sort users by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */

export const managesortingActions = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.MANAGER_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching users.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const managerSearchAction = (searchString: string) => {
  return {
    type: actionTypes.MANAGER_SEARCH,
    payload: {
      searchString,
    },
  };
};

/**
 * Action creator for filtering manager by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const managerTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.MANAGER_TYPE,
    payload: type,
  };
};

/**
 * Action creator for setting manager pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const managerPaginationAction = (page: number) => {
  return {
    type: actionTypes.MANAGER_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting manager items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */

export const managerLimitAction = (limit: number) => {
  return {
    type: actionTypes.MANAGER_ITEMS_LIMIT,
    payload: limit,
  };
};

export const managerResetFilter = () => {
  return {
    type: actionTypes.MANAGER_FILTER_RESET,
  };
};

export const setManagersFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_MANAGER_FILTERS,
  };
};

// CATEGORIES DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting categories.
 *
 * @param {string} sortBy - The field to sort categories by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const categoriesSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.CATEGORIES_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching categories.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const categoriesSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.CATEGORIES_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering categories by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const categoriesStatusAction = (status: string) => {
  return {
    type: actionTypes.CATEGORIES_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const categoriesPaginationAction = (page: number) => {
  return {
    type: actionTypes.CATEGORIES_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const categoriesLimitAction = (limit: number) => {
  return {
    type: actionTypes.CATEGORIES_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters.
 *
 * @returns {Object} Action object.
 */
export const categoriesResetFiltersAction = () => {
  return {
    type: actionTypes.CATEGORIES_RESET_FILTERS,
  };
};

export const setCategoriesFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_CATEGORIES_FILTERS,
  };
};

//   ROLES FILTER ACTIONS
/**
 * Action creator for searching users.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const rolesSearchAction = (searchString: string) => {
  return {
    type: actionTypes.ROLES_SEARCH,
    payload: {
      searchString,
    },
  };
};

/**
 * Action creator for filtering observations by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const rolesTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.ROLES_TYPE,
    payload: type,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const rolesLimitAction = (limit: number) => {
  return {
    type: actionTypes.ROLES_ITEMS_LIMIT,
    payload: limit,
  };
};

// TYPES DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting types.
 *
 * @param {string} sortBy - The field to sort types by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const typesSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.TYPES_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching types.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const typesSearchAction = (searchString: string, searchBy?: string) => {
  return {
    type: actionTypes.TYPES_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering types by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const typesStatusAction = (status: string) => {
  return {
    type: actionTypes.TYPES_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const typesPaginationAction = (page: number) => {
  return {
    type: actionTypes.TYPES_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const typesLimitAction = (limit: number) => {
  return {
    type: actionTypes.TYPES_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all type filters.
 *
 * @returns {Object} Action object.
 */
export const typesResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.TYPES_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering QA observations by program.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const typesCategoriesAction = (
  category: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.TYPES_CATEGORIES,
    payload: category,
  };
};

export const setTypesFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_TYPES_FILTERS,
  };
};

// ADMIN_TRACKING DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting adminTracking.
 *
 * @param {string} sortBy - The field to sort adminTracking by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const adminTrackingSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.ADMIN_TRACKING_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching adminTracking.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const adminTrackingSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.ADMIN_TRACKING_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering adminTracking by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const adminTrackingStatusAction = (status: string) => {
  return {
    type: actionTypes.ADMIN_TRACKING_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const adminTrackingPaginationAction = (page: number) => {
  return {
    type: actionTypes.ADMIN_TRACKING_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const adminTrackingLimitAction = (limit: number) => {
  return {
    type: actionTypes.ADMIN_TRACKING_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all type filters.
 *
 * @returns {Object} Action object.
 */
export const adminTrackingResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.ADMIN_TRACKING_RESET_FILTERS,
  };
};

export const setAdminTrackingFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_ADMIN_TRACKING_FILTERS,
  };
};

// NOTIFICATIONS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting notifications.
 *
 * @param {string} sortBy - The field to sort notifications by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const notificationsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.NOTIFICATIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching notifications.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const notificationsSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.NOTIFICATIONS_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for filtering notifications by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const notificationsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.NOTIFICATIONS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting notifications pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const notificationsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.NOTIFICATIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting notifications items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const notificationsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.NOTIFICATIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator for setting notifications view type.
 *
 * @param {string} type - The view type to set.
 * @returns {Object} Action object.
 */
export const notificationsViewTypeAction = (
  type: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.NOTIFICATIONS_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const notificationsResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.NOTIFICATIONS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering notifications by program.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const notificationsProgramsAction = (
  program: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NOTIFICATIONS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering notifications by form.
 
 * @param {any} form - The form to filter by.
 * @returns {Object} Action object.
 */
export const notificationsFormAction = (
  form: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NOTIFICATIONS_FORMS,
    payload: form,
  };
};

/**
 * Action creator for filtering notifications by community.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const notificationsCommunityAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NOTIFICATIONS_COMMUNITY,
    payload: community,
  };
};

/**
 * Action creator for filtering notifications by member.

 * @param {any} member - The member to filter by.
 * @returns {Object} Action object.
 */
export const notificationsMemberAction = (
  member: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NOTIFICATIONS_MEMBER,
    payload: member,
  };
};

/**
 * Action creator for filtering notifications by created by.

 * @param {any} createdBy - The created by to filter by.
 * @returns {Object} Action object.
 */
export const notificationsCreatedByAction = (
  createdBy: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.NOTIFICATIONS_CREATED_BY,
    payload: createdBy,
  };
};

/**
 * Action creator for filtering Notifications by scheduled date.
 *
 * @returns {Object} Action object.
 */
export const notificationsScheduledForAction = (scheduledFor: string) => {
  return {
    type: actionTypes.NOTIFICATIONS_SCHEDULED_FOR,
    payload: {
      scheduledFor,
    },
  };
};

export const setNotificationsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_NOTIFICATIONS_FILTERS,
  };
};

// REPORTS DIRECTORY FITLER ACTIONS

/**
 * Action creator for sorting notifications.
 *
 * @param {string} sortBy - The field to sort notifications by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const reportsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.REPORTS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching notifications.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const reportsSearchAction = (
  searchString: string,
  searchBy = ""
): { type: string; payload: { searchString: string; searchBy: string } } => {
  return {
    type: actionTypes.REPORTS_SEARCH,
    payload: {
      searchString,
      searchBy,
    },
  };
};

/**
 * Action creator for filtering notifications by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const reportsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.REPORTS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting notifications pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const reportsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting notifications items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const reportsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const reportsResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.REPORTS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering reports by program.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const reportsProgramsAction = (
  program: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_PROGRAMS,
    payload: program,
  };
};

/**
 * Action creator for filtering reports by community.
 *
 * @param {any} community - The community to filter by.
 * @returns {Object} Action object.
 */
export const reportsCommunityAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_COMMUNITY,
    payload: community,
  };
};

/**
 * Action creator for filtering reports by organization.
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const reportsOrganizationAction = (
  organization: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_ORGANIZATION,
    payload: organization,
  };
};

/**
 * Action creator for filtering reports by form.
 
 * @param {any} form - The form to filter by.
 * @returns {Object} Action object.
 */
export const reportsFormAction = (
  form: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_FORMS,
    payload: form,
  };
};

/**
 * Action creator for filtering reports by created by.
 *
 * @param {any} createdBy - The created by filter value.
 * @returns {Object} Action object.
 */
export const reportsCreatedByAction = (createdBy: any) => {
  return {
    type: actionTypes.REPORTS_CREATED_BY,
    payload: createdBy,
  };
};

/**
 * Action creator for filtering reports by date created, added, last run and next run.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateAdded', 'dateUpdated', or 'lastRun', 'nextRun').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsDateAddedUpdatedLastNextAction = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: actionTypes.REPORTS_DATE_ADDED_UPDATED_LAST_RUN_NEXT_RUN,
    payload: {
      name,
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering reports by date added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.REPORTS_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering reports by updated date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsUpdatedDateAction = (from: string, to: string) => {
  return {
    type: actionTypes.REPORTS_UPDATED_DATE,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering reports by last run.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsLastRunAction = (from: string, to: string) => {
  return {
    type: actionTypes.REPORTS_LAST_RUN,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering reports by next report.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsNextRunAction = (from: string, to: string) => {
  return {
    type: actionTypes.REPORTS_NEXT_RUN,
    payload: {
      from,
      to,
    },
  };
};

export const setReportsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_REPORT_FILTERS,
  };
};

export const setSpeciesFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_SPECIES_FILTERS,
  };
};

// Reports Processed Filter Actions

/**
 * Action creator for sorting notifications.
 *
 * @param {string} sortBy - The field to sort notifications by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const reportsProfileSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.REPORTS_PROFILE_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};
/**
 * Action creator for filtering notifications by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const reportsProfileStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.REPORTS_PROFILE_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting notifications pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const reportsProfilePaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_PROFILE_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting notifications items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const reportsProfileLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_PROFILE_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const reportsProfileResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.REPORTS_PROFILE_RESET_FILTERS,
  };
};
/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const reportsVersionResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.REPORTS_VERSION_RESET_FILTERS,
  };
};

export const reportsVersionsViewtypeActions = (type: string) => {
  return {
    type: actionTypes.REPORTS_VERSIONS_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator for filtering communities by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const reportsVersionLocationsAction = (type: string) => {
  return {
    type: actionTypes.REPORTS_VERSION_LOCATIONS,
    payload: type,
  };
};

/**
 * Action creator for searching communities.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const reportsVersionSearchAction = (searchString: string) => {
  return {
    type: actionTypes.REPORTS_VETSION_SEARCH,
    payload: searchString,
  };
};

export const reportsVersionsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_VERIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

export const reportsItemsPerPageLimitAction = (
  itemsPerPage: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_ITEMS_PER_PAGE_LIMIT,
    payload: itemsPerPage,
  };
};
/**
 * Action creator for setting reports versions pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const reportsVersionPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_VERSION_PAGINATION,
    payload: page,
  };
};
/**
 * Action creator for setting reports versions total pages.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const reportsVersionTotalPagesAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_VERIONS_TOTAL_PAGES,
    payload: page,
  };
};

export const setResultReportsAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_REPORTS_VERSION_FILTERS,
  };
};

// Run Reports actions

/**
 * Action creator for filtering reports by date created, added, last run and next run.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateAdded', 'dateUpdated', or 'lastRun', 'nextRun').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const runReportsDateRangeAction = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: "RUN_REPORTS_DATE_SELECT",
    payload: {
      name,
      from,
      to,
    },
  };
};

/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const runReportsResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: "RUN_REPORT_RESET_FILTER",
  };
};

// Reports Analytics Filter Actions

/**
 * Action creator for sorting notifications.
 *
 * @param {string} sortBy - The field to sort notifications by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const reportsAnalyticsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};
/**
 * Action creator for filtering notifications by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsStatusAction = (
  status: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting notifications pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting notifications items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all notifications filters.
 *
 * @returns {Object} Action object.
 */
export const reportsAnalyticsResetFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering reports analytics by version.
 *
 * @param {any} version - The version to filter by.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsVersionAction = (
  version: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_VERSION,
    payload: version,
  };
};

/**
 * Action creator for filtering reports analytics by member.
 *
 * @param {any} member - The member to filter by.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsMemberAction = (
  member: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_MEMBER,
    payload: member,
  };
};

/**
 * Action creator for filtering reports analytics by activity.
 *
 * @param {any} activity - The activity to filter by.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsActivityAction = (
  activity: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_ACTIVITY,
    payload: activity,
  };
};

/**
 * Action creator for filtering reports analytics  by Date.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const reportsAnalyticsDateAction = (
  from: string,
  to: string
): { type: string; payload: { from: string; to: string } } => {
  return {
    type: actionTypes.REPORTS_ANALYTICS_DATE,
    payload: {
      from,
      to,
    },
  };
};

export const setReportsAnalyticsAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_REPORTS_ANALYTICS_FILTERS,
  };
};

/**
 * Action creator for filtering reports by organization.
 *
 * @param {any} organization - The organization to filter by.
 * @returns {Object} Action object.
 */
export const reportsVersionsAction = (
  versions: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.REPORTS_VERSIONS,
    payload: versions,
  };
};

// SPECIES DIRECTORY FILTERS ACTIONS
/**
 * Action creator for sorting species.
 *
 * @param {string} sortBy - The field to sort species by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const speciesSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.SPECIES_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};
/**
 * Action creator for searching species.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const speciesSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.SPECIES_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};
/**
 * Action creator for filtering species by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const speciesStatusAction = (status: string) => {
  return {
    type: actionTypes.SPECIES_STATUS,
    payload: status,
  };
};
/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const speciesPaginationAction = (page: number) => {
  return {
    type: actionTypes.SPECIES_PAGINATION,
    payload: page,
  };
};
/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const speciesLimitAction = (limit: number) => {
  return {
    type: actionTypes.SPECIES_ITEMS_LIMIT,
    payload: limit,
  };
};
/**
 * Action creator to reset all type filters.
 *
 * @returns {Object} Action object.
 */
export const speciesResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.SPECIES_RESET_FILTERS,
  };
};
/**
 * Action creator for filtering species by type.
 *
 * @param {any} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const speciesTypesAction = (
  type: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.SPECIES_TYPES,
    payload: type,
  };
};
/**
 * Action creator for filtering species by categories.
 *
 * @param {any} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const speciesCategoriesAction = (
  type: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.SPECIES_CATEGORIES,
    payload: type,
  };
};
/**
 * Action creator for filtering species by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const speciesDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.SPECIES_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};
/**
 * Action creator for filtering species by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const speciesViewTypeAction = (type: string) => {
  return {
    type: actionTypes.SPECIES_VIEW_TYPE,
    payload: type,
  };
};

export const speciesCommunitiesAction = (
  community: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.SPECIES_COMMUNITY,
    payload: community,
  };
};

// OBJECTS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting objects.
 *
 * @param {string} sortBy - The field to sort objects by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const objectsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.OBJECTS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};
/**
 * Action creator for searching objects.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const objectsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.OBJECTS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};
/**
 * Action creator for filtering objects by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const objectsStatusAction = (status: string) => {
  return {
    type: actionTypes.OBJECTS_STATUS,
    payload: status,
  };
};
/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const objectsPaginationAction = (page: number) => {
  return {
    type: actionTypes.OBJECTS_PAGINATION,
    payload: page,
  };
};
/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const objectsLimitAction = (limit: number) => {
  return {
    type: actionTypes.OBJECTS_ITEMS_LIMIT,
    payload: limit,
  };
};
/**
 * Action creator to reset all type filters.
 *
 * @returns {Object} Action object.
 */
export const objectsResetFiltersAction = (): { type: string } => {
  return {
    type: actionTypes.OBJECTS_RESET_FILTERS,
  };
};
/**
 * Action creator for filtering objects by type.
 *
 * @param {any} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const objectsTypesAction = (
  type: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBJECTS_TYPES,
    payload: type,
  };
};
/**
 * Action creator for filtering objects by type.
 *
 * @param {any} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const objectsCategoriesAction = (
  type: any
): { type: string; payload: any } => {
  return {
    type: actionTypes.OBJECTS_CATEGORIES,
    payload: type,
  };
};
/**
 * Action creator for filtering objects by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const objectsDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.OBJECTS_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};
/**
 * Action creator for filtering objects by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const objectsViewTypeAction = (type: string) => {
  return {
    type: actionTypes.OBJECTS_VIEW_TYPE,
    payload: type,
  };
};

export const setObjectsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_OBJECTS_FILTERS,
  };
};

// TAGS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting tags.
 *
 * @param {string} sortBy - The field to sort tags by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const tagsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.TAGS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching tags.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const tagsSearchAction = (searchString: string, searchBy?: string) => {
  return {
    type: actionTypes.TAGS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const tagsPaginationAction = (page: number) => {
  return {
    type: actionTypes.TAGS_PAGINATION,
    payload: page,
  };
};

export const tagFilterResetAction = () => {
  return {
    type: actionTypes.TAG_RESET_ACTION,
  };
};
/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const tagsLimitAction = (limit: number) => {
  return {
    type: actionTypes.TAGS_ITEMS_LIMIT,
    payload: limit,
  };
};

export const setTagsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_TAGS_FILTERS,
  };
};

/**
 * Action creator for sorting NotificationsRecipients.
 *
 * @param {string} sortBy - The field to sort Protocols by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const notificationrecipientsSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching NotificationsRecipients.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const notificationsRecipientsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for setting NotificationsRecipients pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const notificationsRecipientsPaginationAction = (page: number) => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting NotificationsRecipients items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const notificationsRecipientsLimitAction = (limit: number) => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all NotificationsRecipients filters.
 *
 * @returns {Object} Action object.
 */
export const notificationsRecipientsResetFiltersAction = () => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering NotificationsRecipients by programs.
 *
 * @param {object} program - The program to add or remove from filters.
 * @returns {Object} Action object.
 */
export const notificationsRecipientsRecievedDateAction = (date: any) => {
  console.log(date, "date applied is here");

  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_ACTIONS,
    payload: { date },
  };
};

/**
 * Action creator for filtering NotificationsRecipients by deleted by.
 *
 * @param {any} deletedBy - The deleted by filter value.
 * @returns {Object} Action object.
 */
export const NotificationsRecipientsRemoveByAction = (data: any) => {
  return {
    type: actionTypes.NOTIFICATIONSRECIPIENTS_REMOVE,
    payload: data,
  };
};

/**
 * Action creator for filtering Protocols by date created, updated, or archived.
 *
 * @param {string} date - The date.
 * @returns {Object} Action object.
 */
export const notificationsRecepientsDateCreatedUpdatedArchivedDeletedAction = (
  date: string
) => {
  return {
    type: actionTypes.NOTIFICATIONS_RECIPIENTS_DATE_CREATED_UPDATED_ARCHIVED_DELETED,
    payload: {
      date,
    },
  };
};

/**
 * Action creator to reset all Protocols filters.
 *
 * @returns {Object} Action object.
 */
export const notificationsRecipientsResetFilterExceptProgramsAction = () => {
  return {
    type: actionTypes.NOTIFICATIONS_RECIPIENTS_RESET_EXCEPT_PROGRAM_FILTERS,
  };
};

// GEOGRAPHY DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting geography.
 *
 * @param {string} sortBy - The field to sort geography by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const geographySortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.GEOGRAPHY_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching geography.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const geographySearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.GEOGRAPHY_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for setting community pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const geographyPaginationAction = (page: number) => {
  return {
    type: actionTypes.GEOGRAPHY_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting community items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const geographyLimitAction = (limit: number) => {
  return {
    type: actionTypes.GEOGRAPHY_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters.
 *
 * @returns {Object} Action object.
 */
export const geographyResetFiltersAction = () => {
  return {
    type: actionTypes.GEOGRAPHY_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering geography by  Date Added.
 *
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const geographyDateAddedAction = (from: string, to: string) => {
  return {
    type: actionTypes.GEOGRAPHY_DATE_ADDED,
    payload: {
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering geography by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const geographyTypeAction = (type: string) => {
  return {
    type: actionTypes.GEOGRAPHY_TYPE,
    payload: type,
  };
};

/**
 * Action creator for filtering geography by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const geographyViewTypeAction = (type: string) => {
  return {
    type: actionTypes.GEOGRAPHY_VIEW_TYPE,
    payload: type,
  };
};

/**
 * Action creator for filtering  by created by.
 *
 * @param {any} createdBy - The created by filter value.
 * @returns {Object} Action object.
 */
export const geographyCreatedByAction = (createdBy: any) => {
  return {
    type: actionTypes.GEOGRAPHY_CREATED_BY,
    payload: createdBy,
  };
};

export const setGeographyFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_GEOGRAPHY_FILTERS,
  };
};

// Observation Activities
/**
 * Action creator for setting observation activites pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const activitiesPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.ACTIVITIES_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting observation activities items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const activitiesLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.ACTIVITIES_ITEMS_LIMIT,
    payload: limit,
  };
};

export const activitiesFilterReset = (): { type: string } => {
  return {
    type: actionTypes.ACTIVITIES_RESET_FILTER,
  };
};

/**
 * Action creator for filtering observation activities by type.
 *
 * @param {string} activityType - The status to filter by.
 * @returns {Object} Action object.
 */
export const activityTypeAction = (
  activityType: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.ACTIVITY_TYPE,
    payload: activityType,
  };
};

// IMPORTS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting imports.
 *
 * @param {string} sortBy - The field to sort imports by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const importsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.IMPORTS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching imports.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const importsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.IMPORTS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering Imports by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const importsStatusAction = (status: string) => {
  return {
    type: actionTypes.IMPORTS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting Imports pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const importsPaginationAction = (page: number) => {
  return {
    type: actionTypes.IMPORTS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Imports items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const importsLimitAction = (limit: number) => {
  return {
    type: actionTypes.IMPORTS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator for searching imports property dropdowns.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const importsPropertyDropdownSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.IMPORTS_PROPERTY_DROPDOWN_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy || "",
    },
  };
};

export const importsFilterReset = () => {
  return {
    type: actionTypes.IMPORTS_FILTER_RESET,
  };
};

export const setImportsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_IMPORTS_FILTERS,
  };
};

// Roles Permissions Filter Actions

/**
 * Action creator for sorting roles permissions.
 *
 * @param {string} sortBy - The field to sort Roles Permissions by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const rolesPermissionsSortingAction = (
  sortBy: string,
  sortType: string
): { type: string; payload: { sortBy: string; sortType: string } } => {
  return {
    type: actionTypes.ROLES_PERMISSIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching roles permissions.
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const rolesPermissionsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.ROLES_PERMISSIONS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for setting roles permissions pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const rolesPermissionsPaginationAction = (
  page: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.ROLES_PERMISSIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting roles permissions items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const rolesPermissionsLimitAction = (
  limit: number
): { type: string; payload: number } => {
  return {
    type: actionTypes.ROLES_PERMISSIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

export const rolesPermissionResetFilterActions = () => {
  return {
    type: actionTypes.ROLES_PERMISSIONS_RESET_FILTER,
  };
};

export const setRolesPermissionsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_ROLES_PERMISSIONS_FILTERS,
  };
};
// IMPORTS VIEW DATA DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting imports file data.
 *
 * @param {string} sortBy - The field to sort imports by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const importsViewDataSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching imports.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const importsViewDataSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering Imports by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const importsViewDataStatusAction = (status: string) => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting Imports pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const importsViewDataPaginationAction = (page: number) => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Imports items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const importsViewDataLimitAction = (limit: number) => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_ITEMS_LIMIT,
    payload: limit,
  };
};

export const importsViewDataResetFilter = () => {
  return {
    type: actionTypes.IMPORTS_VIEW_DATA_RESET_FILTERS,
  };
};

/**
 * Action creator for sorting imports Summary Data.
 *
 * @param {string} sortBy - The field to sort imports by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const importsSummaryDataSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.IMPORTS_SUMMARY_DATA_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for setting Imports pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const importsSummaryDataPaginationAction = (page: number) => {
  return {
    type: actionTypes.IMPORTS_SUMMARY_DATA_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Imports Summary items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const importsSummaryDataLimitAction = (limit: number) => {
  return {
    type: actionTypes.IMPORTS_SUMMARY_DATA_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all import summary filters.
 *
 * @returns {Object} Action object.
 */
export const importsSummaryDataResetAction = () => {
  return {
    type: actionTypes.IMPORTS_SUMMARY_DATA_RESET_FILTERS,
  };
};

// NOTIFICATION CENTER FILTERS ACTIONS

/**
 * Action creator for filtering Notification by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */

export const notificationCenterStatusAction = (status: string) => {
  return {
    type: actionTypes.NOTIFICATION_CENTER_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting Notifications pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const notificationCenterPaginationAction = (page: number) => {
  return {
    type: actionTypes.NOTIFICATION_CENTER_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for Notification items limit.
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const notificationCenterLimitAction = (limit: number) => {
  return {
    type: actionTypes.NOTIFICATION_CENTER_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all notification filters.
 * @returns {Object} Action object.
 */
export const notificationCenterAction = () => {
  return {
    type: actionTypes.NOTIFICATION_CENTER_RESET_FILTERS,
  };
};

/// Polls and Surveys

/**
 * Action creator for sorting Polls and Surveys.
 *
 * @param {string} sortBy - The field to sort Polls and Surveys by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const pollsAndSurveysSortingAction = (
  sortBy: string,
  sortType: string
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching Polls and Surveys.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const pollsAndSurveysSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering Polls and Surveys by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */

export const pollsAndSurveysStatusAction = (status: string) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for filtering dashboard by Programs.
 *
 * @param {any} program - The program to filter by.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysProgramsAction = (program: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_PROGRAMS,
    payload: program,
  };
};
/**
 * Action creator for setting Polls and Surveys pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysPaginationAction = (page: number) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Polls and Surveys items limit.
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */

export const pollsAndSurveysLimitAction = (limit: number) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all Polls and Surveys filters.
 *
 * @returns {Object} Action object.
 */

export const pollsAndSurveysResetFiltersAction = (data?: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESET_FILTERS,
    payload: data,
  };
};

/**
 * Action creator for filtering Polls and Surveys by created by.
 *
 * @param {any} createdBy - The created by filter value.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysCreatedByAction = (createdBy: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_CREATED_BY,
    payload: createdBy,
  };
};

/**
 * Action creator for filtering Polls and Surveys by archived by.
 *
 * @param {any} archivedBy - The archived by filter value.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysArchivedByAction = (archivedBy: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_ARCHIVED_BY,
    payload: archivedBy,
  };
};

/**
 * Action creator for filtering Polls and Surveys by date created, updated, or archived.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateCreated', 'dateUpdated', or 'dateArchived').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysDateCreatedUpdatedArchivedAction = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_DATE_CREATED_UPDATED_ARCHIVED,
    payload: {
      name,
      from,
      to,
    },
  };
};

/**
 * Action creator for filtering Polls and Surveys by Responses.
 *
 * @param {string} name - The name of the observation filter.
 * @param {number} min - Minimum number of Responses.
 * @param {number} max - Maximum number of Responses.
 * @param {boolean} applied - Indicates if the filter is applied.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysResponsesAction = (
  name: string,
  min: number,
  max: number,
  applied = false
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESPONSES,
    payload: {
      name,
      min,
      max,
      applied,
    },
  };
};

// POLLS AND SURVEYS RESULTS FILTER ACTIONS
/**
 * Action creator for searching Poll and Survey Results.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const pollAndSurveyResultsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};



/**
 * Action creator for setting Polls and Surveys pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const pollAndSurveyResultsPaginationAction = (page: number) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting Polls and Surveys items limit.
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */

export const pollAndSurveyResultsLimitAction = (limit: number) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator for setting Polls and Surveys items VersionId.
 * @param {number} VersionId - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */

export const pollAndSurveyResultsVersionAction = (Version: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_VERSION,
    payload: Version,
  };
};

/**
 * Action creator for filtering Polls and Surveys by date created, updated, or archived.
 *
 * @param {string} name - The name of the date filter (e.g., 'dateCreated', 'dateUpdated', or 'dateArchived').
 * @param {string} from - The start date.
 * @param {string} to - The end date.
 * @returns {Object} Action object.
 */
export const pollsAndSurveysResultResponseDate = (
  name: string,
  from: string,
  to: string
) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_RESPONSE_DATE,
    payload: {
      name,
      from,
      to,
    },
  };
};

export const setPollsAnSurveysFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_POLLS_AND_SURVEYS_FILTERS,
  };
};

// BEHAVIORS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting behaviors.
 *
 * @param {string} sortBy - The field to sort behaviors by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const behaviorsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.BEHAVIORS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching behaviors.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const behaviorsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.BEHAVIORS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering behaviors by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const behaviorsStatusAction = (status: string) => {
  return {
    type: actionTypes.BEHAVIORS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const behaviorsPaginationAction = (page: number) => {
  return {
    type: actionTypes.BEHAVIORS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const behaviorsLimitAction = (limit: number) => {
  return {
    type: actionTypes.BEHAVIORS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters.
 *
 * @returns {Object} Action object.
 */
export const behaviorsResetFiltersAction = () => {
  return {
    type: actionTypes.BEHAVIORS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering behaviors by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const behaviorsTypeAction = (type: string) => {
  return {
    type: actionTypes.BEHAVIORS_TYPES,
    payload: type,
  };
};

export const setBehaviorsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_BEHAVIORS_FILTERS,
  };
};

// CONDITIONS DIRECTORY FILTERS ACTIONS

/**
 * Action creator for sorting conditions.
 *
 * @param {string} sortBy - The field to sort conditions by.
 * @param {string} sortType - The type of sorting (e.g., 'asc' or 'desc').
 * @returns {Object} Action object.
 */
export const conditionsSortingAction = (sortBy: string, sortType: string) => {
  return {
    type: actionTypes.CONDITIONS_SORTING,
    payload: {
      sortBy,
      sortType,
    },
  };
};

/**
 * Action creator for searching conditions.
 *
 * @param {string} searchString - The search query string.
 * @param {string} searchBy - The field to search within (optional).
 * @returns {Object} Action object.
 */
export const conditionsSearchAction = (
  searchString: string,
  searchBy?: string
) => {
  return {
    type: actionTypes.CONDITIONS_SEARCH,
    payload: {
      searchString,
      searchBy: searchBy ? searchBy : "",
    },
  };
};

/**
 * Action creator for filtering conditions by status.
 *
 * @param {string} status - The status to filter by.
 * @returns {Object} Action object.
 */
export const conditionsStatusAction = (status: string) => {
  return {
    type: actionTypes.CONDITIONS_STATUS,
    payload: status,
  };
};

/**
 * Action creator for setting CATEGORY pagination.
 *
 * @param {number} page - The current page number.
 * @returns {Object} Action object.
 */
export const conditionsPaginationAction = (page: number) => {
  return {
    type: actionTypes.CONDITIONS_PAGINATION,
    payload: page,
  };
};

/**
 * Action creator for setting CATEGORY items limit.
 *
 * @param {number} limit - The maximum number of items to display per page.
 * @returns {Object} Action object.
 */
export const conditionsLimitAction = (limit: number) => {
  return {
    type: actionTypes.CONDITIONS_ITEMS_LIMIT,
    payload: limit,
  };
};

/**
 * Action creator to reset all community filters.
 *
 * @returns {Object} Action object.
 */
export const conditionsResetFiltersAction = () => {
  return {
    type: actionTypes.CONDITIONS_RESET_FILTERS,
  };
};

/**
 * Action creator for filtering conditions by type.
 *
 * @param {string} type - The type to filter by.
 * @returns {Object} Action object.
 */
export const conditionsTypeAction = (type: string) => {
  return {
    type: actionTypes.CONDITIONS_TYPES,
    payload: type,
  };
};

export const setConditionsFiltersAction = (): {
  type: string;
} => {
  return {
    type: actionTypes.SET_CONDITIONS_FILTERS,
  };
};

/**
 * Action creator to reset all Polls and Surveys filters.
 *
 * @returns {Object} Action object.
 */

export const pollAndSurveyResultsResetFiltersAction = (data?: any) => {
  return {
    type: actionTypes.POLLS_AND_SURVEYS_RESULTS_RESET_FILTERS,
    payload: data,
  };
};

export const redirectionFromDashboardAction =
  (entity: string) => (dispatch: Dispatch, getState: any) => {
    const currentState = getState();

    dispatch({
      type: redturnRedirectionTypes(entity),
      payload: currentState.Filters.dashboardFilter,
    });
  };

function redturnRedirectionTypes(entity: string) {
  switch (entity) {
    case "observations":
      return actionTypes.OBSERVATIONS_REDIRECTION_FROM_DASHBOARD;
    case "species":
      return actionTypes.OBSERVATIONS_REDIRECTION_FROM_DASHBOARD;
    case "forms":
      return actionTypes.FORMS_REDIRECTION_FROM_DASHBOARD;
    case "programs":
      return actionTypes.PROGRAMS_REDIRECTION_FROM_DASHBOARD;
    case "users":
      return actionTypes.USERS_REDIRECTION_FROM_DASHBOARD;
    case "communities":
      return actionTypes.COMMUNITIES_REDIRECTION_FROM_DASHBOARD;
    default:
      return;
  }
}
