import { useEffect, useRef, useState } from "react";

import {
  useDrawAllGlobalLayersOnMap,
  useDropPinsOnMap,
  useHandleControlsForEditMode,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
} from "../../hooks";

import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";

import mapboxgl from "mapbox-gl";
import { useInitializeCustomLayersListControl } from "../../DrawPolygon/useInitailizeCustomLayersListControl";

const Map = ({
  element,
  editMode,
  answer,
  eleName,
  setFieldValue,
  markers,
  setMarkers,
}: any) => {
  const mapContainer = useRef<any>(null);
  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const [isFeaturesUpdated, setIsFeauresUpdateed] = useState(false);
  const [draggedMarker, setDraggedMarker] = useState<any>();
  const [newPopup, setNewPopup] = useState<any>();
  const [popups, setPopups] = useState<any>([]);

  const customControlId = "droppin" + element.id;
  const customDeleteControlId = "droppin#delete" + element.id;

  // initializing the map
  const { map, isMapFullyLoaded, draw } = useInitializeMap(
    mapContainer,
    customControlId,
    customDeleteControlId,
    questionTypes.dropPin,
    editMode,
    element?.dropMultiple,
    element?.enableSnapping,
    setIsFeauresUpdateed
  );

  // initializing custom shapes dropdown to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    selectedGlobalLayers,
    customControlId,
    element?.type,
    editMode
  );

  // painting the all global layers on the map
  usePaintAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    "red",
    draw,
    popups,
    setPopups,
    setNewPopup,
    editMode,
    element?.type
  );

  // draw all global layers
  useDrawAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    draw,
    editMode
  );

  // handling the drop pin and delete button
  useHandleControlsForEditMode(isMapFullyLoaded, editMode);

  // draw all answer points
  useDropPinsOnMap(
    answer,
    map,
    isMapFullyLoaded,
    editMode,
    draw,
    markers,
    setMarkers,
    setDraggedMarker
  );

  useEffect(() => {
    if (!isFeaturesUpdated) return;

    const geometryCollection = draw?.getAll();

    if (geometryCollection) {
      const data = geometryCollection.features
        ?.map((f: any) => {
          let id = f.id;

          if (id?.includes("draw")) {
            id = "draw#" + f.id.split("draw#").at(-1);
          }

          const name = answer?.find((ans: any) => ans.id === id)?.name ?? "";

          const mapData = {
            features: [f],
            type: "FeatureCollection",
          };

          return {
            type: "point",
            vectorLayers: [],
            legendFileId: null,
            name,
            geoFilePath: null,
            id,
            mapData,
          };
        })
        .filter((f: any) => !f.id.includes("global"));
      // converting the point into marker
      const droppedPin = data[0];

      if (droppedPin) {
        draw.delete([droppedPin.id]);

        const coordinates =
          droppedPin.mapData?.features[0]?.geometry?.coordinates;

        if (coordinates) {
          const marker = new mapboxgl.Marker({ draggable: editMode })
            .setLngLat(coordinates)
            .addTo(map);

          const data = { reference: marker, id: droppedPin.id };

          marker.on("dragend", () => setDraggedMarker(data));

          if (element?.dropMultiple) {
            setFieldValue(eleName, [...(answer ?? []), droppedPin]);
            setMarkers(() => [...markers, data]);
          } else {
            markers.forEach((marker: any) => {
              marker.reference.remove();
            });
            setMarkers(() => [data]);

            setFieldValue(eleName, [droppedPin]);
          }
        }
      }
    }

    setIsFeauresUpdateed(false);
  }, [isFeaturesUpdated]);

  useEffect(() => {
    if (!draggedMarker) return;

    const updatedAnswers = answer.map((ans: any) => {
      if (ans.id === draggedMarker.id) {
        const { lng, lat } = draggedMarker.reference.getLngLat();
        ans.mapData.features[0].geometry.coordinates = [lng, lat];
      }
      return ans;
    });

    setFieldValue(eleName, updatedAnswers);
  }, [draggedMarker]);

  // display popup of points whenever click on it
  useEffect(() => {
    if (!newPopup || editMode) return;

    const { coordinates, html, id } = newPopup;

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);

    setPopups((prev: any) => [...popups, { id, item: popup }]);
  }, [newPopup]);

  useEffect(() => {
    if (popups.length > 0) {
      popups.forEach((p: any) => {
        p.item.remove();
      });

      setPopups([]);
    }
    setNewPopup(null);
  }, [editMode]);

  useEffect(() => {
    if (element.selectedLayers && element.selectedLayers.length > 0) {
      setSelectedGlobalLayers(
        element.selectedLayers.map((item: any) => ({ ...item, status: false }))
      );
    }
  }, [element.selectedLayers]);

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
