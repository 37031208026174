import IsnProfilePlaceholder from 'assets/images/Placeholders/isnProfilePlaceholder.png';
import SignProfilePlaceholder from 'assets/images/Placeholders/signProfilePlaceholder.png';
import IsnUserProfilePlaceholder from 'assets/images/Placeholders/isnUserProfilePlaceholder.png';
import SignUserProfilePlaceholder from 'assets/images/Placeholders/signUserProfilePlaceholder.svg';
import IsnCoverPlaceholder from 'assets/images/Placeholders/isnCoverPlaceholder.png';
import SignCoverPlaceholder from 'assets/images/Placeholders/signCoverPlaceholder.png';
import IsnListProfilePlaceholder from 'assets/images/Placeholders/isnListProfilePlaceholder.png';
import SignListProfilePlaceholder from 'assets/images/Placeholders/signListProfilePlaceholder.png';
import IsnModuleProfilePlaceholder from 'assets/images/Placeholders/isnModuleProfilePlaceholder.png';
import SignModuleProfilePlaceholder from 'assets/images/Placeholders/signModuleProfilePlaceholder.png';
import IsnModuleCoverPlaceholder from 'assets/images/Placeholders/isnModuleCoverPlaceholder.png';
import SignModuleCoverPlaceholder from 'assets/images/Placeholders/signModuleCoverPlaceholder.png';
import IsnModalCoverPlaceholder from 'assets/images/Placeholders/isnModalCoverPlaceholder.png';
import SignModalCoverPlaceholder from 'assets/images/Placeholders/signModalCoverPlaceholder.png';
import IsnOrganizationProfilePlaceholder from 'assets/images/Placeholders/isnOrganizationProfilePlaceholder.png';
import SignOrganizationProfilePlaceholder from 'assets/images/Placeholders/signOrganizationProfilePlaceholder.png';
import IsnMessageArchivePlaceholder from 'assets/images/Placeholders/isnMessageArchivePlaceholder.png';
import SignMessageArchivePlaceholder from 'assets/images/Placeholders/signMessageArchivePlaceholder.svg';

export const brandNames: any = {
  isn: 'Indigenous Sentinels Network',
  sign: 'Seacoast Indigenous Guardians Network',
};

export const getCurrentBranding = () => {
  return process.env.REACT_APP_BRAND_NAME ?? '';
};

export const invitationApps = [
  { value: 'isn', label: 'ISN' },
  { value: 'sign', label: 'SIGN' },
];

export const getAppName = (isSuperAdmin: boolean) => {
  return isSuperAdmin
    ? { value: '', label: '' }
    : {
        value: process.env.REACT_APP_BRAND_NAME,
        label: process.env.REACT_APP_BRAND_NAME,
      };
};

type BrandTypes =
  | 'profile'
  | 'userProfile'
  | 'listingProfile'
  | 'moduleProfile'
  | 'organizationProfile'
  | 'cover'
  | 'modalCover'
  | 'moduleCover'
  | 'messageArchive';
type ProfileKeys = 'isn' | 'sign';

const brandPlaceholders: Record<BrandTypes, Record<ProfileKeys, string>> = {
  profile: {
    isn: IsnProfilePlaceholder,
    sign: SignProfilePlaceholder,
  },
  userProfile: {
    isn: IsnUserProfilePlaceholder,
    sign: SignUserProfilePlaceholder,
  },

  listingProfile: {
    isn: IsnListProfilePlaceholder,
    sign: SignListProfilePlaceholder,
  },
  moduleProfile: {
    isn: IsnModuleProfilePlaceholder,
    sign: SignModuleProfilePlaceholder,
  },

  organizationProfile: {
    isn: IsnOrganizationProfilePlaceholder,
    sign: SignOrganizationProfilePlaceholder,
  },

  cover: {
    isn: IsnCoverPlaceholder,
    sign: SignCoverPlaceholder,
  },
  moduleCover: {
    isn: IsnModuleCoverPlaceholder,
    sign: SignModuleCoverPlaceholder,
  },
  modalCover: {
    isn: IsnModalCoverPlaceholder,
    sign: SignModalCoverPlaceholder,
  },

  messageArchive: {
    isn: IsnMessageArchivePlaceholder,
    sign: SignMessageArchivePlaceholder,
  },
};

export const getBrandPlaceholder = (type: BrandTypes) => {
  const brandName = process.env.REACT_APP_BRAND_NAME as ProfileKeys;

  if (type in brandPlaceholders && brandName in brandPlaceholders[type]) {
    return brandPlaceholders[type][brandName];
  }

  return undefined;
};
