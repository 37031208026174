import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "../handlers";
import { handlePaintingOfRasterLayersOnMap } from "./handlePaintingOfRasterLayersOnMap";

const usePaintAllGlobalLayersOnMap = (
  layers: any,
  map: any,
  isMapFullyLoaded: boolean,
  LAYER_DEFAULT_COLOR: string,
  draw?: any,
  popups?: any[],
  setPopups?: Dispatch<SetStateAction<any[]>>,
  setNewPopup?: Dispatch<SetStateAction<any[]>>,
  isEditMode?: boolean,
  type?: string
) => {
  useEffect(() => {
    if (layers && map && isMapFullyLoaded) {
      layers.forEach((layer: any) => {
        const status = setStatusIs(type, layer.status, isEditMode);
        const item = {
          item: layer,
          itemType: layer.type,
          status,
        };

        handlePaintingOfVectorRegionShapeLayersOnMap(
          item,
          map,
          draw,
          LAYER_DEFAULT_COLOR,
          (item: any) => {},
          popups,
          setPopups,
          setNewPopup,
          isEditMode
        );
      });
    }
  }, [map, isMapFullyLoaded, layers, isEditMode, type]);

  return null;
};

export { usePaintAllGlobalLayersOnMap };

function setStatusIs(
  type: any = null,
  layerStatus: boolean,
  isEditMode: boolean = false
) {
  if (type && ["drawpolygon", "droppin"].includes(type)) {
    return isEditMode === true ? false : layerStatus;
  } else {
    return layerStatus;
  }
}
