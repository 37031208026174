import {
  ColumnDef,
  ColumnOrderState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";
import axios from "axios";
import { camelCase, sentenceCase } from "change-case";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IDirectoryState } from "store/directoriesOrders/initialState";
import {
  communitiesDirectoryColumnsOrderAction,
  communitiesDirectoryColumnsVisibilityAction,
  usersDirectoryColumnsOrderAction,
  usersDirectoryColumnsVisibilityAction,
  dynamicFormsDirectoryColumnsOrderAction,
  dynamicFormsDirectoryColumnsVisibilityAction,
  organizationsDirectoryColumnsVisibilityAction,
  organizationsDirectoryColumnsOrderAction,
  programsDirectoryColumnsVisibilityAction,
  programsDirectoryColumnsOrderAction,
  protocolsDirectoryColumnsOrderAction,
  protocolsDirectoryColumnsVisibilityAction,
  notificationsRecipientsDirectoryColumnsVisibilityAction,
  notificationsRecipientsDirectoryColumnsOrderAction,
  geographiesDirectoryColumnsOrderAction,
  geographiesDirectoryColumnsVisibilityAction,
  reportsDirectoryColumnsVisibilityAction,
  reportsDirectoryColumnsOrderAction,
  importsDirectoryColumnsVisibilityAction,
  importsDirectoryColumnsOrderAction,
  resetpollsAndSurveysDirectoryOrderAction,
  pollsAndSurveysDirectoryColumnsVisibilityAction,
  pollsAndSurveysDirectoryColumnsOrderAction,
} from "store/directoriesOrders/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { reportsVersionTotalPagesAction } from "store/filters/reducer.actions";
import { fetchImportsFile } from "store/importsViewData/initialState";
import { fetchPollAndSurveyResults } from "store/pollsAndSurveyResults/initialState";
import { IfetchResultReports } from "store/reportsResult/initialState";
import {
  convertKeysToCamelCase,
  snakeToCamel,
  toCamelCase,
} from "utils/caseConvertor";
import createColumnsConfig, {
  createDefaultColumnsConfig,
} from "utils/createColumnsDynamically";

// Types
export type TColumnVisibility = Record<string, boolean>;
export type TSetColumnVisibility = Dispatch<SetStateAction<TColumnVisibility>>;
export type TSetColumnOrder = Dispatch<SetStateAction<ColumnOrderState>>;
export type TColumnConfig = ColumnDef<any>[];
export type TSetColumnConfig = Dispatch<SetStateAction<TColumnConfig>>;

const getRowId = (row: any, relativeIndex: any) => {
  return row.id ?? relativeIndex;
};

export type TUseTable = (
  data: any,
  defaultsColumns: any
) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

const useTable: TUseTable = (data, defaultsColumns) => {
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    if (data.length !== 0) {
      setColumnsConfig(createColumnsConfig<(typeof data)[0]>(data[0]));
      setColumnOrder(
        createColumnsConfig<(typeof data)[0]>(data[0]).map((config: any) =>
          config?.id !== undefined ? config?.id.toString() : ""
        )
      );
    } else {
      setColumnsConfig(createDefaultColumnsConfig(defaultsColumns));
      setColumnOrder(
        createDefaultColumnsConfig(defaultsColumns).map((config: any) =>
          config?.id !== undefined ? config?.id.toString() : ""
        )
      );
      // defaultsColumns.map((columns: any) => {
      //   setColumnVisibility((pre: any) => {
      //     return {
      //       ...pre,
      //       [columns]: true,
      //     };
      //   });
      // });
    }
  }, [data, defaultsColumns]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export default useTable;

export type TUserTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const UserTable: TUserTable = (data) => {
  const { usersDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: usersDirectory.columnVisibility,
      columnOrder: usersDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(usersDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(usersDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(usersDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(usersDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(usersDirectory.columnVisibility);
  }, [data, usersDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TCommunitiesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const CommunitiesTable: TCommunitiesTable = (data) => {
  const { communitiesDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: communitiesDirectory.columnVisibility,
      columnOrder: communitiesDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(communitiesDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(communitiesDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(communitiesDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(communitiesDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(communitiesDirectory.columnVisibility);
  }, [data, communitiesDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TOrganizationsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const OrganizationsTable: TOrganizationsTable = (data) => {
  const { organizationsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: organizationsDirectory.columnVisibility,
      columnOrder: organizationsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(organizationsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(organizationsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(organizationsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(organizationsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(organizationsDirectory.columnVisibility);
  }, [data, organizationsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TPollsAndSurveysTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const PollsAndSurveysTable: TPollsAndSurveysTable = (data) => {
  const { pollsAndSurveysDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: pollsAndSurveysDirectory?.columnVisibility,
      columnOrder: pollsAndSurveysDirectory?.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(pollsAndSurveysDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(resetpollsAndSurveysDirectoryOrderAction());
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(pollsAndSurveysDirectory?.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(pollsAndSurveysDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(pollsAndSurveysDirectory?.columnVisibility);
  }, [data, pollsAndSurveysDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TPollAndSurveyResultsTable = (data?: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const PollAndSurveyResultsTable: TPollAndSurveyResultsTable = (
  selectedData
) => {
  const { data } = useSelector<RootState, fetchPollAndSurveyResults>(
    (state) => state?.pollAndSurveyResults
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const initialPollAndSurveyResultsDirectoryOrder: any = {
    columnVisibility: {},
    columnOrder: [],
  };
  const [csvHeader, setCsvHeader] = useState<any>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const columnHeader = (fields: any[]) => {
    if (fields.length > 0) {
      const convertSentenCase = fields.map((field: any) =>
        camelCase(field ?? "")
      );

      if (convertSentenCase?.length > 0) {
        convertSentenCase?.forEach((header: any) => {
          initialPollAndSurveyResultsDirectoryOrder.columnVisibility[header] =
            true;
        });
        setCsvHeader(convertSentenCase);
      }
    }
  };
  useEffect(() => {
    columnHeader(data?.questions);
  }, [data.questions]);

  const table = useReactTable({
    data: selectedData,
    columns: csvHeader?.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility:
        initialPollAndSurveyResultsDirectoryOrder.columnVisibility,
      columnOrder: csvHeader,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(pollsAndSurveysDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(pollsAndSurveysDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(csvHeader));
    setColumnOrder(
      createDefaultColumnsConfig(csvHeader).map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(
      initialPollAndSurveyResultsDirectoryOrder.columnVisibility
    );
  }, [selectedData]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TDynamicFormsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const DynamicFormsTable: TDynamicFormsTable = (data) => {
  const { dynamicFormDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: dynamicFormDirectory.columnVisibility,
      columnOrder: dynamicFormDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(dynamicFormsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(dynamicFormsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(dynamicFormDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(dynamicFormDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(dynamicFormDirectory.columnVisibility);
  }, [data, dynamicFormDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TProgramsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ProgramsTable: TProgramsTable = (data) => {
  const { programsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: programsDirectory?.columnVisibility,
      columnOrder: programsDirectory?.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(programsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(programsDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(programsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(programsDirectory.columnVisibility);
  }, [data, programsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TFilesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const FilesTable: TFilesTable = (data) => {
  const FilesDirectory = {
    columnOrder: ["fileName", "fileType", "fileSize", "dateUpdated"],
    columnVisibility: {
      fileName: true,
      fileType: true,
      fileSize: true,
      dateUpdated: true,
    },
  };
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: FilesDirectory.columnVisibility,
      columnOrder: FilesDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createFilesColumnsConfig(FilesDirectory?.columnOrder));
    setColumnOrder(
      createFilesColumnsConfig(FilesDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(FilesDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TProtocolsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ProtocolsTable: TProtocolsTable = (data) => {
  const { protocolsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: protocolsDirectory.columnVisibility,
      columnOrder: protocolsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(protocolsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(protocolsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(protocolsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(protocolsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(protocolsDirectory.columnVisibility);
  }, [data, protocolsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};
export type TNotificationsRecipients = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const NotificationsRecipientsTable: TNotificationsRecipients = (
  data
) => {
  const { notificationsRecipientsDirectory } = useSelector<
    RootState,
    IDirectoryState
  >((state) => state?.directoriesOrders);
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: notificationsRecipientsDirectory.columnVisibility,
      columnOrder: notificationsRecipientsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(
        notificationsRecipientsDirectoryColumnsVisibilityAction(newVisibility)
      );
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(notificationsRecipientsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(notificationsRecipientsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(
        notificationsRecipientsDirectory.columnOrder
      ).map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(notificationsRecipientsDirectory.columnVisibility);
  }, [data, notificationsRecipientsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TNotificationsStatusTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const NotificationsStatusTable: TNotificationsStatusTable = (data) => {
  const NotificationsStatusDirectory = {
    columnOrder: ["notificationsDate", "status"],
    columnVisibility: {
      notificationsDate: true,
      fileTstatusype: true,
    },
  };
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: NotificationsStatusDirectory.columnVisibility,
      columnOrder: NotificationsStatusDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createFilesColumnsConfig(NotificationsStatusDirectory?.columnOrder)
    );
    setColumnOrder(
      createFilesColumnsConfig(NotificationsStatusDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(NotificationsStatusDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};
export type TObservationsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ObservationsTable: TObservationsTable = (data) => {
  const { observationsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: observationsDirectory?.columnVisibility,
      columnOrder: observationsDirectory?.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(programsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(observationsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(observationsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(observationsDirectory.columnVisibility);
  }, [data, observationsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TImportsFileDataTable = (data?: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const useImportsFileDataTable: TImportsFileDataTable = (
  selectedData
) => {
  const { importFileDataDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const { data, stateIs } = useSelector<RootState, fetchImportsFile>(
    (state) => state?.importsFileData
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const initialImportsDirectoryOrder: any = {
    columnVisibility: {},
    columnOrder: [],
  };
  const [csvHeader, setCsvHeader] = useState<any>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const columnHeader = (fields: any[]) => {
    if (fields.length > 0) {
      const convertSentenCase = fields.map((field: any) => camelCase(field));
      if (convertSentenCase?.length > 0) {
        convertSentenCase?.forEach((header: any) => {
          initialImportsDirectoryOrder.columnVisibility[header] = true;
        });
        setCsvHeader(convertSentenCase);
      }
    }
  };
  useEffect(() => {
    columnHeader(data?.fields);
  }, [data.fields]);

  const table = useReactTable({
    data: selectedData,
    columns: csvHeader?.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: initialImportsDirectoryOrder.columnVisibility,
      columnOrder: csvHeader,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(importsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(importsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(csvHeader));
    setColumnOrder(
      createDefaultColumnsConfig(csvHeader).map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(initialImportsDirectoryOrder.columnVisibility);
  }, [selectedData]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TReportProfileTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ReportProfileTable: TReportProfileTable = (data) => {
  const { reportsProfileDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: reportsProfileDirectory?.columnVisibility,
      columnOrder: reportsProfileDirectory?.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(programsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(reportsProfileDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(reportsProfileDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(reportsProfileDirectory.columnVisibility);
  }, [data, reportsProfileDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export const ReportResultTable: any = () => {
  const reportsResult = useSelector<RootState, IfetchResultReports>(
    (state) => state?.reportsResult
  );
  const { reportsVersionFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const initialResultsDirectoryOrder: any = {
    columnVisibility: {},
    columnOrder: [],
  };

  const ITEMS_PER_PAGE = reportsVersionFilter.limit;

  const [currentPage, setCurrentPage] = useState(1);
  const [csvContent, setCSVContent] = useState("");
  const [csvHeader, setCSVHeader] = useState<string[]>([]); // Initialize as an empty array
  const [csvData, setCSVData] = useState<{ [key: string]: string }[]>([]); // Initialize as an empty array
  const [sliceData, setSliceData] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const searchString = reportsVersionFilter.searchString.trim().toLowerCase();
  const downloadCSVFromS3 = (response: any) => {
    setCSVContent(response);
  };
  const parseCSV = () => {
    const lines = csvContent.split("\n"); // Split CSV content by lines
    if (lines.length > 0) {
      const originalHeaders: string[] = lines[0].split(",").map((header) =>
        header
          .toLowerCase() // Convert to lower case to standardize
          .split(/\s+/) // Split the header into words based on spaces
          .map(
            (word, index) =>
              index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word after the first
          )
          .join("")
      ); // Assuming headers are in the first line separated by commas

      const firstVarIndex = originalHeaders.indexOf("observationCode"); // Change 'firstVariable' to your desired variable name
      if (firstVarIndex !== -1 && firstVarIndex !== 0) {
        // Reorder the headers based on the position of the first variable
        const reorderedHeaders = [...originalHeaders];
        const firstVarHeader = reorderedHeaders.splice(firstVarIndex, 1)[0];
        reorderedHeaders.unshift(firstVarHeader);

        setCSVHeader(reorderedHeaders);
      } else {
        setCSVHeader(originalHeaders);
      }
      const filteredLines = lines.filter((items) => items !== "");
      const data: { [key: string]: string }[] = [];
      const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
      for (let i = 1; i < filteredLines.length; i++) {
        const row = filteredLines[i].split(regex);
        const obj: { [key: string]: string } = {};
        for (let j = 0; j < originalHeaders.length; j++) {
          obj[originalHeaders[j]] = row[j];
        }
        data.push(obj);
      }
      setCSVData(data);
    }
  };

  useEffect(() => {
    if (reportsResult.data.responseData) {
      downloadCSVFromS3(reportsResult.data.responseData);
    }
    if (csvContent !== "") {
      parseCSV();
    }
    if (csvHeader.length > 0) {
      csvHeader.forEach((header: any) => {
        initialResultsDirectoryOrder.columnVisibility[header] = true;
      });
    }
  }, [csvContent, currentPage, reportsResult.data]);
  const sliceDataForPagination = (data: any) => {
    const startIndex =
      (reportsVersionFilter.page - currentPage) * ITEMS_PER_PAGE;
    const endIndex =
      currentPage === reportsVersionFilter.page
        ? ITEMS_PER_PAGE
        : currentPage < reportsVersionFilter.page
          ? reportsVersionFilter.itemsPerPage
          : data.length;
    const currentSlice = data.slice(
      startIndex,
      Math.min(endIndex, data.length)
    );
    setSliceData(currentSlice);
  };

  useEffect(() => {
    const filteredData = searchString
      ? csvData.filter(
        (item) =>
          item.observationCode &&
          item.observationCode
            .toLowerCase()
            .includes(searchString.toLowerCase())
      )
      : csvData;

    if (reportsVersionFilter.viewType === "map") {
      setSliceData(filteredData);
    } else {
      sliceDataForPagination(filteredData);
    }
  }, [
    searchString,
    csvData,
    ITEMS_PER_PAGE,
    reportsVersionFilter.page,
    reportsVersionFilter.viewType,
  ]);

  useEffect(() => {
    const totalItems = csvData.length; // Assuming sliceData is your full dataset
    const pages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    dispatch(reportsVersionTotalPagesAction(pages));
  }, [csvData, ITEMS_PER_PAGE]);

  const changePageByInput = (pageNumberInput: any) => {
    const pageNumber = parseInt(pageNumberInput); // Convert input value to integer
    if (!isNaN(pageNumber) && pageNumber > 0) {
      setCurrentPage(pageNumber); // Set the current page to the input value
    } else {
      alert("Please enter a valid positive number for the page");
    }
  };
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const table = useReactTable({
    data: sliceData,
    columns: csvHeader.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: initialResultsDirectoryOrder.columnVisibility,
      columnOrder: csvHeader,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(programsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(csvHeader));
    setColumnOrder(
      createDefaultColumnsConfig(csvHeader).map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(initialResultsDirectoryOrder.columnVisibility);
  }, [csvData, csvHeader]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
    changePageByInput,
    currentPage,
    csvData,
    sliceData,
  };
};

export type TQualityAssuranceObservationsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const QualityAssuranceObservationsTable: TQualityAssuranceObservationsTable =
  (data) => {
    const { qualityAssuranceObservationsDirectory } = useSelector<
      RootState,
      IDirectoryState
    >((state) => state?.directoriesOrders);
    const dispatch = useDispatch();
    const columnHelper = createColumnHelper<any>();
    const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
      {}
    );
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
    const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [rowSelection, setRowSelection] = useState({});

    const [sorting, setSorting] = useState<SortingState>([]);
    const [selectAll, setSelectAll] = useState(false);
    const table = useReactTable({
      data: data,
      columns: columnOrder.map((columnId: string) =>
        columnHelper.accessor(columnId as string, {
          ...(columnsConfig.find((config) => config.id === columnId) || {}),
        })
      ),

      state: {
        sorting,
        columnVisibility:
          qualityAssuranceObservationsDirectory?.columnVisibility,
        columnOrder: qualityAssuranceObservationsDirectory?.columnOrder,
        rowSelection,
      },

      onSortingChange: setSorting,
      onColumnVisibilityChange: (newVisibility) => {
        dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
        setColumnVisibility(newVisibility);
      },
      onRowSelectionChange: setRowSelection,
      onColumnOrderChange: (newOrder) => {
        dispatch(programsDirectoryColumnsOrderAction(newOrder));
        setColumnOrder(newOrder);
      },
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });

    useEffect(() => {
      setColumnsConfig(
        createDefaultColumnsConfig(
          qualityAssuranceObservationsDirectory.columnOrder
        )
      );
      setColumnOrder(
        createDefaultColumnsConfig(
          qualityAssuranceObservationsDirectory.columnOrder
        ).map((config: any) =>
          config?.id !== undefined ? config?.id.toString() : ""
        )
      );
      setColumnVisibility(
        qualityAssuranceObservationsDirectory.columnVisibility
      );
    }, [data, qualityAssuranceObservationsDirectory]);

    return {
      flexRender,
      columnVisibility,
      columnOrder,
      columnsConfig,
      selectedRows,
      selectAll,
      table,
      setColumnsConfig,
      setSelectAll,
      setSelectedRows,
      setColumnOrder,
      setColumnVisibility,
      setSorting,
      sorting,
    };
  };

type DefaultCustomColumnDef<T> = {
  id: string;
  header: any; // Assuming the header can be a string or React node
  accessor: (data: T) => any;
  canSort: boolean;
};

function createFilesColumnsConfig<T>(
  defaultColumns: string[]
): DefaultCustomColumnDef<T>[] {
  const columns: DefaultCustomColumnDef<T>[] = [];
  // Add the select column as the first column

  defaultColumns?.forEach((columnName, index) => {
    const header = columnName.replace(/([A-Z])/g, " $1"); // Add space before capital letters
    const capitalizedHeader = header.charAt(0).toUpperCase() + header.slice(1); // Capitalize the first letter
    if (columnName !== "select") {
      columns.push({
        id: columnName, // You can generate unique IDs based on the index or any other criteria
        header: capitalizedHeader,
        accessor: (data: T) => data[columnName as keyof T],
        canSort: defaultColumns.includes(columnName), // Check if it's a default column
      });
    }
  });
  return columns;
}
export type TNotTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export type TManagerTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ManagerTable: TManagerTable = (data) => {
  const { managersDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: managersDirectory?.columnVisibility,
      columnOrder: managersDirectory?.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(programsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(programsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(managersDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(managersDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(managersDirectory.columnVisibility);
  }, [data, managersDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TCategoriesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const CategoriesTable: TCategoriesTable = (data) => {
  const { categoriesDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: categoriesDirectory.columnVisibility,
      columnOrder: categoriesDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(categoriesDirectory?.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(categoriesDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(categoriesDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TBehaviorsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const BehaviorsTable: TBehaviorsTable = (data) => {
  const { behaviorsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);

  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: behaviorsDirectory.columnVisibility,
      columnOrder: behaviorsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(behaviorsDirectory?.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(behaviorsDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(behaviorsDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TConditionsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ConditionsTable: TConditionsTable = (data) => {
  const { conditionsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: conditionsDirectory.columnVisibility,
      columnOrder: conditionsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(conditionsDirectory?.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(conditionsDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(conditionsDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TImportsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ImportsTable: TImportsTable = (data) => {
  const { importsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: importsDirectory.columnVisibility,
      columnOrder: importsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(importsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(importsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(importsDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(importsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(importsDirectory.columnVisibility);
  }, [data, importsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TProgramAdminsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ProgramAdminsTable: TImportsTable = (data) => {
  const { programAdminsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: programAdminsDirectory.columnVisibility,
      columnOrder: programAdminsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(importsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(importsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(programAdminsDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(programAdminsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(programAdminsDirectory.columnVisibility);
  }, [data, programAdminsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TImportSummaryTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ImportSummaryTable: TImportSummaryTable = (data) => {
  const { importSummaryDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: importSummaryDirectory.columnVisibility,
      columnOrder: importSummaryDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createFilesColumnsConfig(importSummaryDirectory?.columnOrder)
    );
    setColumnOrder(
      createFilesColumnsConfig(importSummaryDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(importSummaryDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TTypesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const TypesTable: TTypesTable = (data) => {
  const { typesDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: typesDirectory.columnVisibility,
      columnOrder: typesDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(typesDirectory?.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(typesDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(typesDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TAdminTrackingTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const AdminTrackingTable: TAdminTrackingTable = (data) => {
  const { adminTrackingDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: adminTrackingDirectory.columnVisibility,
      columnOrder: adminTrackingDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(adminTrackingDirectory?.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(adminTrackingDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(adminTrackingDirectory?.columnVisibility);
  }, []);

  console.log("columnOrdercolumnOrder", columnOrder);
  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TSpeciesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const SpeciesTable: TSpeciesTable = (data) => {
  const { speciesDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: speciesDirectory.columnVisibility,
      columnOrder: speciesDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(speciesDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(speciesDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(speciesDirectory.columnVisibility);
  }, [data, speciesDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TNotificationsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const NotificationsTable: TNotificationsTable = (data) => {
  const { notificationsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder?.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: notificationsDirectory.columnVisibility,
      columnOrder: notificationsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(protocolsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(protocolsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(notificationsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(notificationsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(notificationsDirectory.columnVisibility);
  }, [data, notificationsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};
export type TObjectsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ObjectsTable: TObjectsTable = (data) => {
  const { objectsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: objectsDirectory.columnVisibility,
      columnOrder: objectsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(objectsDirectory?.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(objectsDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(objectsDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};
export type TTagsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const TagsTable: TTagsTable = (data) => {
  const { tagsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: tagsDirectory.columnVisibility,
      columnOrder: tagsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createFilesColumnsConfig(tagsDirectory?.columnOrder));
    setColumnOrder(
      createFilesColumnsConfig(tagsDirectory?.columnOrder).map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(tagsDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TGeographiesTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const GeographiesTable: TGeographiesTable = (data) => {
  const { geographyDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: geographyDirectory.columnVisibility,
      columnOrder: geographyDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(geographiesDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(geographiesDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(geographyDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(geographyDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(geographyDirectory.columnVisibility);
  }, [data, geographyDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TReportsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ReportsTable: TReportsTable = (data) => {
  const { reportsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: reportsDirectory.columnVisibility,
      columnOrder: reportsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(reportsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(reportsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(createDefaultColumnsConfig(reportsDirectory.columnOrder));
    setColumnOrder(
      createDefaultColumnsConfig(reportsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(reportsDirectory.columnVisibility);
  }, [data, reportsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TReportsAnalyticsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ReportsAnalyticsTable: TReportsAnalyticsTable = (data) => {
  const { reportsAnalyticsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: reportsAnalyticsDirectory.columnVisibility,
      columnOrder: reportsAnalyticsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(reportsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(reportsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(reportsAnalyticsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(reportsAnalyticsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(reportsAnalyticsDirectory.columnVisibility);
  }, [data, reportsAnalyticsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TRolesPermissionsTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const RolesPermissionsTable: TRolesPermissionsTable = (data) => {
  const { rolesPermissionsDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder?.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: rolesPermissionsDirectory.columnVisibility,
      columnOrder: rolesPermissionsDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      dispatch(protocolsDirectoryColumnsVisibilityAction(newVisibility));
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      dispatch(protocolsDirectoryColumnsOrderAction(newOrder));
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createDefaultColumnsConfig(rolesPermissionsDirectory.columnOrder)
    );
    setColumnOrder(
      createDefaultColumnsConfig(rolesPermissionsDirectory.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(rolesPermissionsDirectory.columnVisibility);
  }, [data, rolesPermissionsDirectory]);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TProgramStatusHistoryTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ProgramStatusHistoryTable: TProgramStatusHistoryTable = (data) => {
  const programStatusHistoryDirectory = {
    columnOrder: ["seasonStatus", "date", "updatedByAdmin"],
    columnVisibility: {
      seasonStatus: true,
      date: true,
      updatedByAdmin: true,
    },
  };
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: programStatusHistoryDirectory.columnVisibility,
      columnOrder: programStatusHistoryDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  // useEffect(() => {
  //   setColumnsConfig(createFilesColumnsConfig(programStatusHistoryDirectory?.columnOrder));
  //   setColumnOrder(
  //     createFilesColumnsConfig(programStatusHistoryDirectory?.columnOrder).map(
  //       (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
  //     )
  //   );
  //   setColumnVisibility(programStatusHistoryDirectory?.columnVisibility);
  // }, []);

  useEffect(() => {
    const updatedColumnsConfig = createFilesColumnsConfig(
      programStatusHistoryDirectory?.columnOrder
    );
    // Update header text for the "date" column
    const dateColumnIndex = updatedColumnsConfig.findIndex(
      (config) => config.id === "date"
    );
    if (dateColumnIndex !== -1) {
      updatedColumnsConfig[dateColumnIndex].header = "paused/resumed since";
    }
    setColumnsConfig(updatedColumnsConfig);
    setColumnOrder(
      updatedColumnsConfig.map((config: any) =>
        config?.id !== undefined ? config?.id.toString() : ""
      )
    );
    setColumnVisibility(programStatusHistoryDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};

export type TReportVersionDataTable = (data: any) => {
  columnVisibility: TColumnVisibility;
  setColumnVisibility: TSetColumnVisibility;
  flexRender: any;
  columnOrder: any;
  columnsConfig: TColumnConfig;
  selectedRows: any;
  selectAll: any;
  table: any;
  setColumnsConfig: TSetColumnConfig;
  setSelectAll: any;
  setSelectedRows: any;
  setColumnOrder: TSetColumnOrder;
  setSorting: any;
  sorting: any;
};

export const ReportVersionDataTable: TReportVersionDataTable = (data) => {
  const { reportVersionDataDirectory } = useSelector<RootState, IDirectoryState>(
    (state) => state?.directoriesOrders
  );
  const columnHelper = createColumnHelper<any>();
  const [columnVisibility, setColumnVisibility] = useState<TColumnVisibility>(
    {}
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnsConfig, setColumnsConfig] = useState<TColumnConfig>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectAll, setSelectAll] = useState(false);
  const table = useReactTable({
    data: data,
    columns: columnOrder.map((columnId: string) =>
      columnHelper.accessor(columnId as string, {
        ...(columnsConfig.find((config) => config.id === columnId) || {}),
      })
    ),
    getRowId,
    state: {
      sorting,
      columnVisibility: reportVersionDataDirectory.columnVisibility,
      columnOrder: reportVersionDataDirectory.columnOrder,
      rowSelection,
    },

    onSortingChange: setSorting,
    onColumnVisibilityChange: (newVisibility) => {
      setColumnVisibility(newVisibility);
    },
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: (newOrder) => {
      setColumnOrder(newOrder);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    setColumnsConfig(
      createFilesColumnsConfig(reportVersionDataDirectory?.columnOrder)
    );
    setColumnOrder(
      createFilesColumnsConfig(reportVersionDataDirectory?.columnOrder).map(
        (config: any) => (config?.id !== undefined ? config?.id.toString() : "")
      )
    );
    setColumnVisibility(reportVersionDataDirectory?.columnVisibility);
  }, []);

  return {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    selectedRows,
    selectAll,
    table,
    setColumnsConfig,
    setSelectAll,
    setSelectedRows,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  };
};