import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

interface TooltipProps {
  title: string;
  children: any;
  showTooltip?: boolean; // Receive showTooltip from props
  toggleTooltip?: () => void;
}

const CustomToolTip: React.FC<TooltipProps> = ({
  title,
  children,
  showTooltip,
  toggleTooltip,
}) => {
  const { primary } = useSelector((state: any) => state.theme.colors);
  return (
    <Tooltip
      title={title}
      arrow
      open={showTooltip}
      onOpen={toggleTooltip}
      onClose={toggleTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: primary,
            "& .MuiTooltip-arrow": {
              color: primary,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
