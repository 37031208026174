import { snakeCase } from "change-case";
import { apiClient } from "../config";

async function getUsersFilterDropdowns(
  page: number,
  entity: any,
  search?: string
) {
  // const queryString = search ? `&search_string=${search}` : "";
  const params = {
    page: page,
    entity: snakeCase(entity),
    searchString: search,
  };
  const res = await apiClient.get(`/admin/users/drop-down`, {
    params,
  });
  return res.data;
}

export default getUsersFilterDropdowns;
