function registerImageOnMap(map: any, imageUrl: string, imageName: string) {
  return new Promise((resolve, reject) => {
    // Check if the image is already registered
    if (map.hasImage(imageName)) {
      return resolve("Image already registered");
    }
    
    // Load and register the image if it's not already registered
    map.loadImage(imageUrl, (error: any, image: any) => {
      if (error) {
        return reject(error.message);
      }

      // Add the image to the map's style
      map.addImage(imageName, image);

      return resolve("Image successfully registered");
    });
  });
}

export default registerImageOnMap;
