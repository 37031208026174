interface dashboardDirectoryFilterState {
  datePeriod: {
    from: string;
    to: string;
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  organizationIds: {
    value: any[];
    applied: boolean;
  };
}

interface userDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  lastActive: {
    from: string;
    to: string;
    applied: boolean;
  };
  memberSince: {
    from: string;
    to: string;
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  forms: {
    min: number;
    max: number;
    applied: boolean;
  };
  observations: {
    min: number;
    max: number;
    applied: boolean;
  };
  entityOpenAccess?: boolean;
}
interface communitiesDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  organizationIds: {
    value: any[];
    applied: boolean;
  };
  type: {
    value: string;
    applied?: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  members: {
    min: number;
    max: number;
    applied: boolean;
  };
  tagIds: {
    value: any[];
    applied: boolean;
  };
  path: string;
}

export interface pollsAndSurveysDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  createdByIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  archivedByIds: {
    value: any[];
    applied: boolean;
  };

  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  dateArchived: {
    from: string;
    to: string;
    applied: boolean;
  };

  dateUpdated: {
    from: string;
    to: string;
    applied: boolean;
  };

  responses: {
    min: number;
    max: number;
    applied: boolean;
  };

  path: string;
}

export interface pollAndSurveyResultsDirectoryFilterState {
  searchString: string;
  page: number;
  limit: number;
  responseDate: {
    from: string;
    to: string;
    applied: boolean;
  };
  versionIds: {
    value: any[];
    applied: boolean;
  };
}

export interface programsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  communityIds: {
    value: any[];
    applied: boolean;
  };
  organizationIds: {
    value: any[];
    applied: boolean;
  };
  date: {
    from: string;
    to: string;
    applied: boolean;
  };
  numberOfObservations: {
    min: number;
    max: number;
    applied: boolean;
  };
  members: {
    min: number;
    max: number;
    applied: boolean;
  };
  connectedForms: {
    min: number;
    max: number;
    applied: boolean;
  };
  tagIds: {
    value: any[];
    applied: boolean;
  };
  path: string;
}

export interface managersDirectoryFilterState {
  id: number;
  searchString: string;
  limit: number;
  type: string;
  sortBy: string;
  sortType: string;
  page: number;
  status: string;
}

interface organizationsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  tagIds: {
    value: any[];
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  members: {
    min: number;
    max: number;
    applied: boolean;
  };
  path: string;
}

export interface protocolsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  programIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  createdByIds: {
    value: any[];
    applied: boolean;
  };
  updatedByIds: {
    value: any[];
    applied: boolean;
  };
  deletedByIds: {
    value: any[];
    applied: boolean;
  };
  archivedByIds: {
    value: any[];
    applied: boolean;
  };
  created: {
    from: string;
    to: string;
    applied: boolean;
  };

  updated: {
    from: string;
    to: string;
    applied: boolean;
  };
  deleted: {
    from: string;
    to: string;
    applied: boolean;
  };
  archived: {
    from: string;
    to: string;
    applied: boolean;
  };
}

export interface notificationRecipientsDirectoryFilterState {
  searchString: string;
  limit: number;
  page: number;
  id: number;
  dateRecieved: {
    date: string;
    applied: boolean;
  };
}

export interface notificationsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  page: number;
  limit: number;
  scheduledFor: string;
  createdById: number;
  communityIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  roleIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  memberIds: {
    value: any[];
    applied: boolean;
  };
  sortBy: string;
  sortType: string;
}

export interface dynamicFormsDirectoryFilterState {
  searchString: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  programIds: {
    value: any[];
    applied: boolean;
  };
  protocolIds: {
    value: any[];
    applied: boolean;
  };
  createdByIds: {
    value: any[];
    applied: boolean;
  };
  archivedByIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  dateUpdated: {
    from: string;
    to: string;
    applied: boolean;
  };
  dateArchived: {
    from: string;
    to: string;
    applied: boolean;
  };
  observations: {
    min: number;
    max: number;
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  path: string;
}

export interface observationsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  observerIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  specieIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };

  path: string;
}

export interface qualityAssuranceObservationsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  observerIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  reviewerIds: {
    value: any[];
    applied: boolean;
  };
  dateUpdated: {
    from: string;
    to: string;
    applied: boolean;
  };
  filterStatus: {
    value: any[];
    applied: boolean;
  };
  path: string;
}

export interface messagesStatusFilterState {
  archived: boolean;
}

export interface messagesNewChatFilterState {
  newChat: boolean;
}

export interface messagesAppIdFilterState {
  appId: string;
}

export interface messagesContactPersonSearchFilterState {
  searchString: string;
}

export interface messagesDetailFilterState {
  searchString: string;
  searchBy: string;
  page: number;
  limit: number;
}

export interface notificationDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  programIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  memberIds: {
    value: any[];
    applied: boolean;
  };
  createdByIds: {
    value: any[];
    applied: boolean;
  };
  scheduledFor: {
    value: string;
    applied: boolean;
  };
}

export interface reportsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  page: number;
  limit: number;
  status: string;
  viewType: string;
  programIds: {
    value: number[];
    applied: boolean;
  };
  communityIds: {
    value: number[];
    applied: boolean;
  };
  organizationIds: {
    value: number[];
    applied: boolean;
  };
  formIds: {
    value: number[];
    applied: boolean;
  };
  createdByIds: {
    value: number[];
    applied: boolean;
  };
  addedDate: {
    from: string;
    to: string;
    applied: boolean;
  };
  updatedDate: {
    from: string;
    to: string;
    applied: boolean;
  };

  lastRun: {
    from: string;
    to: string;
    applied: boolean;
  };
  nextRun: {
    from: string;
    to: string;
    applied: boolean;
  };
  sortBy: string;
  sortType: string;
}
export interface reportsAnalyticsDirectoryFilterState {
  versionIds: {
    value: number[];
    applied: boolean;
  };
  memberIds: {
    value: number[];
    applied: boolean;
  };
  activityType: string;
  date: {
    from: string;
    to: string;
    applied: boolean;
  };
  page: number;
  limit: number;
  sortBy: string;
  sortType: string;
}
export interface reportsRunReportsDirectoryFilterState {
  date: {
    from: string;
    to: string;
    applied: boolean;
  };
}

export interface reportsProfileDirectoryFilterState {
  page: number;
  limit: number;
  status: {
    value: string;
    applied?: any;
  };
  sortBy: string;
  sortType: string;
}

export interface reportsVersionFilterState {
  versionIds: {
    value: string;
    applied?: boolean;
    id?: any;
  };
  viewType: string;
  searchString: string;
  locations: any;
  limit: number;
  itemsPerPage: number;
  page: any;
  totalPages: number;
}

const reportsVersionInitialState: reportsVersionFilterState = {
  versionIds: {
    value: "",
    applied: false,
    id: 0,
  },
  viewType: "list",
  searchString: "",
  locations: "Observation Locations",
  limit: 10,
  itemsPerPage: 10,
  page: 1,
  totalPages: 0,
};

const dashboardDirectoryInitialState: dashboardDirectoryFilterState = {
  datePeriod: {
    from: "",
    to: "",
    applied: false,
  },
  communityIds: {
    value: [],
    applied: false,
  },
  programIds: {
    value: [],
    applied: false,
  },
  formIds: {
    value: [],
    applied: false,
  },
  organizationIds: {
    value: [],
    applied: false,
  },
};

const userDirectoryInitialState: userDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "all",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  lastActive: {
    from: "",
    to: "",
    applied: false,
  },
  memberSince: {
    from: "",
    to: "",
    applied: false,
  },
  communityIds: {
    value: [],
    applied: false,
  },
  programIds: {
    value: [],
    applied: false,
  },
  forms: {
    min: 0,
    max: 10000,
    applied: false,
  },
  observations: {
    min: 0,
    max: 10000,
    applied: false,
  },
  entityOpenAccess: false,
};

const communitiesDirectoryInitialState: communitiesDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  viewType: "list",
  path: "directory",
  type: {
    value: "",
    applied: false,
  },
  organizationIds: {
    value: [],
    applied: false,
  },

  dateAdded: {
    from: "",
    to: "",
    applied: false,
  },
  programIds: {
    value: [],
    applied: false,
  },
  tagIds: {
    value: [],
    applied: false,
  },

  members: {
    min: 0,
    max: 10000,
    applied: false,
  },
};

const pollsAndSurveysDirectoryInitialState: pollsAndSurveysDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    status: "published",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    viewType: "list",
    path: "directory",
    dateAdded: {
      from: "",
      to: "",
      applied: false,
    },
    createdByIds: {
      value: [],
      applied: false,
    },

    programIds: {
      value: [],
      applied: false,
    },
    archivedByIds: {
      value: [],
      applied: false,
    },

    dateArchived: {
      from: "",
      to: "",
      applied: false,
    },
    dateUpdated: {
      from: "",
      to: "",
      applied: false,
    },

    responses: {
      min: 0,
      max: 10000,
      applied: false,
    },
  };

const pollAndSurveyResultsDirectoryInitialState: pollAndSurveyResultsDirectoryFilterState =
  {
    searchString: "",
    page: 1,
    limit: 10,

    responseDate: {
      from: "",
      to: "",
      applied: false,
    },
    versionIds: {
      value: [],
      applied: false,
    },
  };

const organizationsDirectoryFiltersInitialState: organizationsDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    status: "active",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    viewType: "list",
    tagIds: {
      value: [],
      applied: false,
    },
    communityIds: {
      value: [],
      applied: false,
    },
    dateAdded: {
      from: "",
      to: "",
      applied: false,
    },
    programIds: {
      value: [],
      applied: false,
    },
    members: {
      min: 0,
      max: 1000,
      applied: false,
    },
    path: "",
  };

const protocolsDirectoryFiltersInitialState: protocolsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  viewType: "list",
  programIds: {
    value: [],
    applied: false,
  },
  formIds: {
    value: [],
    applied: false,
  },
  createdByIds: {
    value: [],
    applied: false,
  },
  updatedByIds: {
    value: [],
    applied: false,
  },
  deletedByIds: {
    value: [],
    applied: false,
  },
  archivedByIds: {
    value: [],
    applied: false,
  },
  created: {
    from: "",
    to: "",
    applied: false,
  },

  updated: {
    from: "",
    to: "",
    applied: false,
  },
  deleted: {
    from: "",
    to: "",
    applied: false,
  },
  archived: {
    from: "",
    to: "",
    applied: false,
  },
};

const notificationRecipientsDirectoryFiltersInitialState: notificationRecipientsDirectoryFilterState =
  {
    searchString: "",
    limit: 10,
    page: 1,
    id: 6,
    dateRecieved: {
      date: "",
      applied: false,
    },
  };

const NoticationsDirectoryFilterInitialState: notificationsDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    page: 1,
    limit: 10,
    scheduledFor: "",
    createdById: 1,
    communityIds: {
      value: [],
      applied: false,
    },
    programIds: {
      value: [],
      applied: false,
    },
    roleIds: {
      value: [],
      applied: false,
    },
    formIds: {
      value: [],
      applied: false,
    },
    memberIds: {
      value: [],
      applied: false,
    },
    sortBy: "",
    sortType: "",
  };

const initialDynamicFormsDirectoryFilterState: dynamicFormsDirectoryFilterState =
  {
    searchString: "",
    status: "published",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    programIds: {
      value: [],
      applied: false,
    },
    createdByIds: {
      value: [],
      applied: false,
    },
    protocolIds: {
      value: [],
      applied: false,
    },
    archivedByIds: {
      value: [],
      applied: false,
    },
    dateAdded: {
      from: "",
      to: "",
      applied: false,
    },
    dateUpdated: {
      from: "",
      to: "",
      applied: false,
    },
    dateArchived: {
      from: "",
      to: "",
      applied: false,
    },
    observations: {
      min: 0,
      max: 10000,
      applied: false,
    },
    communityIds: {
      value: [],
      applied: false,
    },
    path: "",
  };

export default initialDynamicFormsDirectoryFilterState;

const programsDirectoryFilterInitialState: programsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  viewType: "list",
  communityIds: {
    value: [],
    applied: false,
  },
  organizationIds: {
    value: [],
    applied: false,
  },

  tagIds: {
    value: [],
    applied: false,
  },
  date: {
    from: "",
    to: "",
    applied: false,
  },
  numberOfObservations: {
    min: 0,
    max: 10000,
    applied: false,
  },
  members: {
    min: 1,
    max: 10000,
    applied: false,
  },
  connectedForms: {
    min: 0,
    max: 10000,
    applied: false,
  },
  path: "",
};

const managersDirectoryFilterInitialState: managersDirectoryFilterState = {
  id: 1,
  searchString: "",
  limit: 10,
  type: "internal",
  sortBy: "",
  sortType: "",
  page: 1,
  status: "",
};

const observationsDirectoryFilterInitialState: observationsDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    status: "all",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    viewType: "list",

    observerIds: {
      value: [],
      applied: false,
    },
    programIds: {
      value: [],
      applied: false,
    },
    communityIds: {
      value: [],
      applied: false,
    },
    formIds: {
      value: [],
      applied: false,
    },
    specieIds: {
      value: [],
      applied: false,
    },
    dateAdded: {
      from: "",
      to: "",
      applied: false,
    },
    path: "",
  };

export interface observationActivityFilterState {
  activityType: string;
  page: number;
  limit: number;
}

const qualityAssuranceObservationsDirectoryFilterInitialState: qualityAssuranceObservationsDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    status: "assigned_to_me",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    viewType: "list",

    observerIds: {
      value: [],
      applied: false,
    },
    programIds: {
      value: [],
      applied: false,
    },
    communityIds: {
      value: [],
      applied: false,
    },
    reviewerIds: {
      value: [],
      applied: false,
    },
    dateUpdated: {
      from: "",
      to: "",
      applied: false,
    },
    filterStatus: {
      value: [],
      applied: false,
    },
    path: "",
  };

const messageStatusFilterInitialState: messagesStatusFilterState = {
  archived: false,
};

const messageNewChatFilterInitialState: messagesNewChatFilterState = {
  newChat: false,
};

const mesageAppIdInitialState: messagesAppIdFilterState = {
  appId: "",
};

const messageContactPersonSearchFilterInitialState: messagesContactPersonSearchFilterState =
  {
    searchString: "",
  };

const messagesDetailFilterInitialState: messagesDetailFilterState = {
  searchString: "",
  searchBy: "",
  page: 1,
  limit: 20,
};

const observationActivityFilterInitialState: observationActivityFilterState = {
  activityType: "",
  page: 1,
  limit: 20,
};

interface categoriesDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
}

const categoriesDirectoryInitialState: categoriesDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
};

interface behaviorsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  typeIds: {
    value: any[];
    applied: boolean;
  };
}

const behaviorsDirectoryInitialState: behaviorsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  typeIds: {
    value: [],
    applied: false,
  },
};

interface conditionsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  typeIds: {
    value: any[];
    applied: boolean;
  };
}

const conditionsDirectoryInitialState: conditionsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  typeIds: {
    value: [],
    applied: false,
  },
};

interface importsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
}

const importsDirectoryInitialState: importsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "imported",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
};

interface typesDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  categoryIds: {
    value: any[];
    applied: boolean;
  };
}

const typesDirectoryInitialState: typesDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  categoryIds: {
    value: [],
    applied: false,
  },
};

interface adminTrackingDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
}

const adminTrackingDirectoryInitialState: adminTrackingDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
};

const notificationsDirectoryInitialState: notificationDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  viewType: "list",
  programIds: {
    value: [],
    applied: false,
  },
  formIds: {
    value: [],
    applied: false,
  },
  communityIds: {
    value: [],
    applied: false,
  },
  memberIds: {
    value: [],
    applied: false,
  },
  createdByIds: {
    value: [],
    applied: false,
  },
  scheduledFor: {
    value: "",
    applied: false,
  },

  // scheduledFor: "",
};
interface speciesDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  typeIds: {
    value: any[];
    applied: boolean;
  };
  categoryIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  viewType: string;
}

const speciesDirectoryInitialState: speciesDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  typeIds: {
    value: [],
    applied: false,
  },
  categoryIds: {
    value: [],
    applied: false,
  },
  dateAdded: {
    from: "",
    to: "",
    applied: false,
  },

  communityIds: {
    value: [],
    applied: false,
  },

  viewType: "list",
};

interface objectsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  typeIds: {
    value: any[];
    applied: boolean;
  };
  categoryIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  viewType: string;
}

const objectsDirectoryInitialState: objectsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  status: "active",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  typeIds: {
    value: [],
    applied: false,
  },
  categoryIds: {
    value: [],
    applied: false,
  },
  dateAdded: {
    from: "",
    to: "",
    applied: false,
  },
  viewType: "list",
};

const reportsDirectoryInitialState: reportsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  page: 1,
  limit: 10,
  status: "submitted",
  viewType: "list",
  programIds: {
    value: [],
    applied: false,
  },
  communityIds: {
    value: [],
    applied: false,
  },
  organizationIds: {
    value: [],
    applied: false,
  },
  formIds: {
    value: [],
    applied: false,
  },
  createdByIds: {
    value: [],
    applied: false,
  },
  addedDate: {
    from: "",
    to: "",
    applied: false,
  },
  updatedDate: {
    from: "",
    to: "",
    applied: false,
  },
  lastRun: {
    from: "",
    to: "",
    applied: false,
  },
  nextRun: {
    from: "",
    to: "",
    applied: false,
  },

  sortBy: "",
  sortType: "",
};

const reportsProfileDirectoryInitialState: reportsProfileDirectoryFilterState =
  {
    page: 1,
    limit: 10,
    status: {
      value: "",
      applied: false,
    },
    sortBy: "",
    sortType: "",
  };
const reportsAnalyticsDirectoryInitialState: reportsAnalyticsDirectoryFilterState =
  {
    versionIds: {
      value: [],
      applied: false,
    },
    memberIds: {
      value: [],
      applied: false,
    },
    activityType: "",
    date: {
      from: "",
      to: "",
      applied: false,
    },
    page: 1,
    limit: 10,
    sortBy: "",
    sortType: "",
  };
const reportsRunReportsDirectoryInitialState: reportsRunReportsDirectoryFilterState =
  {
    date: {
      from: "",
      to: "",
      applied: false,
    },
  };

interface tagsDirectoryFilterState {
  searchString: string;
  searchBy: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
}

const tagsDirectoryInitialState: tagsDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
};

interface geographyDirectoryFilterState {
  searchString: string;
  searchBy: string;
  type: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  createdByIds: {
    value: any[];
    applied: boolean;
  };

  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
}
const geographyDirectoryInitialState: geographyDirectoryFilterState = {
  searchString: "",
  searchBy: "",
  type: "raster",
  sortBy: "",
  sortType: "",
  page: 1,
  limit: 10,
  viewType: "list",
  createdByIds: {
    value: [],
    applied: false,
  },
  dateAdded: {
    from: "",
    to: "",
    applied: false,
  },
};

interface rolesFilterState {
  searchString: string;
  page: number;
  limit: number;
  type: string;
}

const rolesInitialState: rolesFilterState = {
  searchString: "",
  page: 1,
  limit: 10,
  type: "",
};

interface targetMembersFilterState {
  searchString: string;
  limit: number;
  page: number;
  sortBy: string;
  communityIds: number[];
  programIds: number[];
  roleIds: string[];
}

const targetMembersInitialState: targetMembersFilterState = {
  searchString: "",
  limit: 10,
  page: 1,
  sortBy: "",
  communityIds: [0],
  programIds: [0],
  roleIds: [""],
};

interface rolesPermissionsFilterState {
  searchString: string;
  searchBy: string;
  page: number;
  limit: number;
}

const rolesPermissionsInitialState: rolesPermissionsFilterState = {
  searchString: "",
  searchBy: "",
  page: 1,
  limit: 10,
};
interface importsPropertyDropdownsFilterState {
  searchString: string;
  searchBy?: string;
}

const importsPropertyDropdownsInitialState: importsPropertyDropdownsFilterState =
  {
    searchString: "",
    searchBy: "",
  };

export interface importsViewDataDirectoryFilterState {
  searchString: string;
  searchBy: string;
  status: string;
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
  viewType: string;
  observerIds: {
    value: any[];
    applied: boolean;
  };
  programIds: {
    value: any[];
    applied: boolean;
  };
  communityIds: {
    value: any[];
    applied: boolean;
  };
  formIds: {
    value: any[];
    applied: boolean;
  };
  specieIds: {
    value: any[];
    applied: boolean;
  };
  dateAdded: {
    from: string;
    to: string;
    applied: boolean;
  };
  path: string;
}

const importsViewDataDirectoryFilterInitialState: importsViewDataDirectoryFilterState =
  {
    searchString: "",
    searchBy: "",
    status: "",
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
    viewType: "list",

    observerIds: {
      value: [],
      applied: false,
    },
    programIds: {
      value: [],
      applied: false,
    },
    communityIds: {
      value: [],
      applied: false,
    },
    formIds: {
      value: [],
      applied: false,
    },
    specieIds: {
      value: [],
      applied: false,
    },
    dateAdded: {
      from: "",
      to: "",
      applied: false,
    },
    path: "",
  };

export interface importsSummaryDataTableFilterState {
  sortBy: string;
  sortType: string;
  page: number;
  limit: number;
}

const importsSummaryDataTableFilterInitialState: importsSummaryDataTableFilterState =
  {
    sortBy: "",
    sortType: "",
    page: 1,
    limit: 10,
  };

export interface notificationCenterFilterState {
  status: string;
  page: number;
  limit: number;
}

const notificationCenterFilterInitialState: notificationCenterFilterState = {
  status: "",
  page: 1,
  limit: 10,
};

export interface filtersInitialState {
  dashboardFilter: dashboardDirectoryFilterState;
  usersFilter: userDirectoryFilterState;
  communitiesFilter: communitiesDirectoryFilterState;
  organizationsFilter: organizationsDirectoryFilterState;
  dynamicFormsFilter: dynamicFormsDirectoryFilterState;
  programsFilter: programsDirectoryFilterState;
  protocolsFilter: protocolsDirectoryFilterState;
  notificationsRecipientsFilter: notificationRecipientsDirectoryFilterState;
  notificationsProfilePreferencesFilter: notificationsDirectoryFilterState;
  observationsFilter: observationsDirectoryFilterState;
  managerFilter: managersDirectoryFilterState;
  messageStatusFilter: messagesStatusFilterState;
  messageNewChatFilter: messagesNewChatFilterState;
  messageAppIdFilter: messagesAppIdFilterState;
  messageContactPersonSearchFilter: messagesContactPersonSearchFilterState;
  messageDetailFilter: messagesDetailFilterState;
  qualityAssuranceObservationsFilter: qualityAssuranceObservationsDirectoryFilterState;
  categoriesFilter: categoriesDirectoryFilterState;
  behaviorsFilter: behaviorsDirectoryFilterState;
  conditionsFilter: conditionsDirectoryFilterState;

  typesFilter: typesDirectoryFilterState;
  adminTrackingFilter: adminTrackingDirectoryFilterState;
  notificationsFilter: notificationDirectoryFilterState;
  speciesFilter: speciesDirectoryFilterState;
  objectsFilter: objectsDirectoryFilterState;
  tagsFilter: tagsDirectoryFilterState;
  geographyFilter: geographyDirectoryFilterState;
  rolesFilter: rolesFilterState;
  targetMembers: targetMembersFilterState;
  reportsFilter: reportsDirectoryFilterState;
  reportProfileFilter: reportsProfileDirectoryFilterState;
  observationActivityFilter: observationActivityFilterState;
  reportsAnalyticsFilter: reportsAnalyticsDirectoryFilterState;
  reportsRunReportsFilter: reportsRunReportsDirectoryFilterState;
  importsFilter: importsDirectoryFilterState;
  rolesPermissionsFilter: rolesPermissionsFilterState;
  importsPropertyDropdownsFilter: importsPropertyDropdownsFilterState;
  importsViewDataFilter: importsViewDataDirectoryFilterState;
  importsSummaryDataFilter: importsSummaryDataTableFilterState;
  notificationCenterFilter: notificationCenterFilterState;
  pollsAndSurveysFilter: pollsAndSurveysDirectoryFilterState;
  pollAndSurveyResultsFilter: pollAndSurveyResultsDirectoryFilterState;
  reportsVersionFilter: reportsVersionFilterState;
}

// Combined initial state
export const initialState: filtersInitialState = {
  dashboardFilter: dashboardDirectoryInitialState,
  usersFilter: userDirectoryInitialState,
  communitiesFilter: communitiesDirectoryInitialState,
  organizationsFilter: organizationsDirectoryFiltersInitialState,
  dynamicFormsFilter: initialDynamicFormsDirectoryFilterState,
  programsFilter: programsDirectoryFilterInitialState,
  protocolsFilter: protocolsDirectoryFiltersInitialState,
  notificationsProfilePreferencesFilter: NoticationsDirectoryFilterInitialState,
  observationsFilter: observationsDirectoryFilterInitialState,
  managerFilter: managersDirectoryFilterInitialState,
  messageStatusFilter: messageStatusFilterInitialState,
  messageNewChatFilter: messageNewChatFilterInitialState,
  messageAppIdFilter: mesageAppIdInitialState,
  messageContactPersonSearchFilter:
    messageContactPersonSearchFilterInitialState,
  messageDetailFilter: messagesDetailFilterInitialState,
  qualityAssuranceObservationsFilter:
    qualityAssuranceObservationsDirectoryFilterInitialState,
  categoriesFilter: categoriesDirectoryInitialState,
  behaviorsFilter: behaviorsDirectoryInitialState,
  conditionsFilter: conditionsDirectoryInitialState,

  typesFilter: typesDirectoryInitialState,
  adminTrackingFilter: adminTrackingDirectoryInitialState,

  notificationsFilter: notificationsDirectoryInitialState,
  speciesFilter: speciesDirectoryInitialState,
  objectsFilter: objectsDirectoryInitialState,
  tagsFilter: tagsDirectoryInitialState,
  notificationsRecipientsFilter:
    notificationRecipientsDirectoryFiltersInitialState,
  geographyFilter: geographyDirectoryInitialState,
  rolesFilter: rolesInitialState,
  targetMembers: targetMembersInitialState,
  observationActivityFilter: observationActivityFilterInitialState,
  reportsFilter: reportsDirectoryInitialState,
  reportProfileFilter: reportsProfileDirectoryInitialState,
  reportsAnalyticsFilter: reportsAnalyticsDirectoryInitialState,
  reportsRunReportsFilter: reportsRunReportsDirectoryInitialState,
  importsFilter: importsDirectoryInitialState,
  rolesPermissionsFilter: rolesPermissionsInitialState,
  importsPropertyDropdownsFilter: importsPropertyDropdownsInitialState,
  importsViewDataFilter: importsViewDataDirectoryFilterInitialState,
  importsSummaryDataFilter: importsSummaryDataTableFilterInitialState,
  notificationCenterFilter: notificationCenterFilterInitialState,
  pollsAndSurveysFilter: pollsAndSurveysDirectoryInitialState,
  pollAndSurveyResultsFilter: pollAndSurveyResultsDirectoryInitialState,
  reportsVersionFilter: reportsVersionInitialState,
};
