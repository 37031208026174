import { combineReducers } from "redux";
import persistedAuthReducer from "store/auth/reducer";
import { dynamicFormsReducer } from "store/dynamicForms";
import { modalsReducer } from "store/modals/reducer";
import userProfileReducer from "store/userProfile/reducer";
import userProfileettingsReducer from "store/userProfilesettings/reducer";
import Filters from "../filters/reducer";
import { usersReducer } from "store/users";
import { communitiesReducer } from "store/communities";
import communityReducer from "store/communityProfile/reducer";
import { organizationsReducer } from "store/oranizations";
import organizationReducer from "store/organizationProfile/reducer";
import formDetailsReducer from "store/formDetails/reducer";
import { directoryReducer } from "store/directoriesOrders";
import { programsReducer } from "store/programs";
import { programProfileReducer } from "store/programProfile";
import { filesReducer } from "store/files";
import { protocolsReducer } from "store/protocols";
import { protocolProfileReducer } from "store/protocolProfile";
import { geographyReducer } from "store/geography/reducer";
import { dataAccessPolicyReducer } from "store/DataAccessPolicy";
import { observationsActivitiesReducer } from "store/observationsActivities";
import { observationProfileReducer } from "store/observationProfile";
import { observationsReducer } from "store/observations";
import { managerReducer } from "store/managers";
import { recipientsReducer } from "store/messages";
import { qualityAssuranceObservationsReducer } from "store/qualityAssurance";
import { chatDetailReducer } from "store/messagesDetail";
import { contactPersonsReducer } from "store/messages/reducer";
import { categoriesReducer } from "store/categories";
import { typesReducer } from "store/types";
import { SurveyJSReducer } from "store/surveyJS/reducer";
import { speciesReducer } from "store/species";
import { notificationsReducer } from "store/notifications";
import { specieProfileReducer } from "store/specieProfile";
import { objectsReducer } from "store/objects";
import { objectProfileReducer } from "store/objectProfile";
import { tagsReducer } from "store/tagging";
import { notificationRecipientsReducer } from "store/notificationsRecipients/reducer";
import { adminGeographyReducer } from "store/adminGeography";
import { notificationStatusReducer } from "store/notificationsStatus";
import notificationsSummaryReducer from "store/notificationSummary/reducer";
import { rolesReducer } from "store/roles";
import {
  profileAdditionalInformationReducer,
  profileRolesReducer,
} from "store/profileRoles/reducer";
import reportsSummaryReducer from "store/reportsSummary/reducer";
import { loadingBarReducer } from "store/loadingBar";
import { reportsReducer } from "store/reports";
import { analyticsReportsReducer } from "store/reportsAnalytics";
import { reportsProfileReducer } from "store/reportsProfile";
import { dashboardReducer } from "store/dashboard";
import { reportsResultReducer } from "store/reportsResult";
import { importsReducer } from "store/imports";
import importSummaryReducer from "store/importSummary/reducer";
import { addImportStepperReducer } from "store/addImportStepper";
import { importsPropertyDropdownReducer } from "store/addImport";
import { runReportDataReducer } from "store/reportsRun/reducer";
import { importsFileDataReducer } from "store/importsViewData";
import { rolesPermissionsReducer } from "store/rolesPermissions";
import { loadingReducer } from "store/Loader";
import darkModeReducer from "store/darkMode/reducer";
import importStatusDetailReducer from "store/importStatusDetail/reducer";
import { pollsAndSurveysReducer } from "store/pollsAndSurveys/reducer";
import { notificationCenterReducer } from "store/notificationCenter";
import { pollAndSurveyResultsReducer } from "store/pollsAndSurveyResults";
import importInfoReducer from "store/importInfo/reducer";
import sidebarReducer from "store/sideBar/reducer";
import { behaviorsReducer } from "store/behaviors";
import { conditionsReducer } from "store/conditions";
import { programStatusHistoryReducer } from "store/programStatusHistory";
import { adminTrackingReducer } from "store/adminTracking";
import { communitiesGeographiesReducer } from "store/communityGeography/reducer";
import { addReportStepperReducer } from "store/addReportStepper/reducer";
import formSurveyJsDetailsReducer from "store/formDetailsSurveyJsFields/reducer";
import themeReducer from "store/theme/reducer";
import { runReportReducer } from "store/runReport";

const rootReducer: any = combineReducers({
  auth: persistedAuthReducer,
  userProfile: userProfileReducer,
  modals: modalsReducer,
  userProfileSettings: userProfileettingsReducer,
  allUsers: usersReducer,
  allCommunities: communitiesReducer,
  allOrganizations: organizationsReducer,
  allforms: dynamicFormsReducer,
  Filters,
  communityProfile: communityReducer,
  organizationProfile: organizationReducer,
  formDetails: formDetailsReducer,
  directoriesOrders: directoryReducer,
  allprograms: programsReducer,
  programProfile: programProfileReducer,
  allFiles: filesReducer,
  allProtocols: protocolsReducer,
  protocolProfile: protocolProfileReducer,
  geography: geographyReducer,
  dataAccessPolicy: dataAccessPolicyReducer,
  observationActivities: observationsActivitiesReducer,
  observationProfile: observationProfileReducer,
  allObservations: observationsReducer,
  allManagers: managerReducer,
  allRecipients: recipientsReducer,
  allQualityAssuranceObservations: qualityAssuranceObservationsReducer,
  chatDetails: chatDetailReducer,
  allContactPersons: contactPersonsReducer,
  allCategories: categoriesReducer,
  allTypes: typesReducer,
  surveyJS: SurveyJSReducer,
  allSpecies: speciesReducer,
  allNotifications: notificationsReducer,
  specieProfile: specieProfileReducer,
  allObjects: objectsReducer,
  objectProfile: objectProfileReducer,
  allTags: tagsReducer,
  notificationRecipients: notificationRecipientsReducer,
  allGeographies: adminGeographyReducer,
  notificationStatus: notificationStatusReducer,
  notificationSummary: notificationsSummaryReducer,
  roles: rolesReducer,
  profileRoles: profileRolesReducer,
  profileAdditionalInfo: profileAdditionalInformationReducer,
  reportSummary: reportsSummaryReducer,
  loadingBar: loadingBarReducer,  
  reportsProfile: reportsProfileReducer,
  reportStepper: addReportStepperReducer,
  allReports: reportsReducer,
  allImports: importsReducer,
  importSummary: importSummaryReducer,
  reportsResult: reportsResultReducer,
  reportsAnalytics: analyticsReportsReducer,
  importStepper: addImportStepperReducer,
  dashboard: dashboardReducer,
  importsPropertyDropdowns: importsPropertyDropdownReducer,
  reportVersions: runReportReducer,
  runReport: runReportDataReducer,
  importsFileData: importsFileDataReducer,
  allRolesPermissions: rolesPermissionsReducer,
  Loader: loadingReducer,
  darkMode: darkModeReducer,
  importStatusDetail: importStatusDetailReducer,
  pollsAndSurveys: pollsAndSurveysReducer,
  notificationCenter: notificationCenterReducer,
  pollAndSurveyResults: pollAndSurveyResultsReducer,
  importInfo: importInfoReducer,
  sideBar: sidebarReducer,
  allBehaviors: behaviorsReducer,
  allConditions: conditionsReducer,
  programStatusHistory: programStatusHistoryReducer,
  communityGeographies: communitiesGeographiesReducer,
  allAdminTracking: adminTrackingReducer,
  formSurveyJsDetails: formSurveyJsDetailsReducer,
  theme: themeReducer,
});
export default rootReducer;
