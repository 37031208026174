import { useEffect } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useDropPinsOnMap = (
  answer: any,
  map: any,
  isMapFullyLoaded: any,
  editMode: any,
  draw: any,
  markers: any,
  setMarkers: any,
  setDraggedMarker: any
) => {
  useEffect(() => {
    if (map && isMapFullyLoaded && answer && draw) {
      // draw.deleteAll();
      if (markers.length > 0) {
        markers.forEach((marker: any) => marker.reference.remove());
      }

      if (answer && Array.isArray(answer) && draw) {
        const newMarkers: any = [];

        answer?.forEach((markerData: any) => {
          const coordinates =
            markerData.mapData?.features[0]?.geometry?.coordinates;

          if (coordinates) {
            const marker = new mapboxgl.Marker({ draggable: editMode })
              .setLngLat(coordinates)
              .addTo(map);

            if (!editMode) {
              // Create a popup for this marker
              const popup = new mapboxgl.Popup({ offset: 25 })
                .setHTML(`<p class="break-all">${markerData.name}</p>`);

              // Attach the popup to the marker
              marker.setPopup(popup);
            }

            const data = { reference: marker, id: markerData.id };

            newMarkers.push(data);

            marker.on("dragend", () => {
              setDraggedMarker(data);
            });

            // const geometry = draw.getAll();

            // geometry.features = [...geometry.features, ...markerData.mapData.features];

            // geometry.features = geometry.features.map((f: any) => {
            //   f.properties = {
            //     ...(f.properties ?? {}),
            //     isEditable: editMode
            //   };

            //   return f;
            // });

            // draw.set(geometry);
          }
        });

        setMarkers(newMarkers);
      }
    }
  }, [map, isMapFullyLoaded, editMode]);

  return null;
};

export { useDropPinsOnMap };
