function __paintShapeByGeoJsonOnMap__(
    map: any,
    draw: any,
    sourceId: string,
    layerId: string,
    geoJson: any,
    defaultColor:any
  ) {
    if (!map || !sourceId || !layerId || !geoJson) {
      return;
    }
  
    // adding geojson source
    map.addSource(sourceId, {
      type: "geojson",
      data: geoJson,
    });
  
    // design and register the added geojson source layer
    map.addLayer({
      id: layerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": defaultColor,
        "fill-opacity": 0.6,
      },
    });
  };

  export {__paintShapeByGeoJsonOnMap__};