import { useEffect } from "react";

import { __changeColorOfZoomedShapeLayer__ } from "../../../mapWorkerMehods";

import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useCustomizeColorOfHigilightedPoint = (
  map: any,
  isMapFullyLoaded: any,
  currentQuestionEle: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string,
  markers: any,
  setMarkers: any,
  setClickedPoint: any
) => {
  useEffect(() => {
    if (currentQuestionEle && markers && map && isMapFullyLoaded) {
      const newMarkers = markers.map((markerData: any) => {
        const isMarkerSelected =
          currentQuestionEle.selectedPointsIdToHighlight?.find(
            (marker: any) => marker.id == markerData.id
          );

        const color = isMarkerSelected
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        // get coordinates of marker
        const coordinates = markerData.marker.getLngLat();

        // Remove the old marker
        markerData.marker.remove();

        // Create a new marker with the new color
        const newMarker = new mapboxgl.Marker({ color })
          .setLngLat(coordinates)
          .addTo(map);

        newMarker
          .getElement()
          .addEventListener("click", () => setClickedPoint(markerData.data));

        markerData.marker = newMarker;

        return markerData;
      });

      setMarkers(newMarkers);
    }
  }, [currentQuestionEle?.selectedPointsIdToHighlight]);

  return null;
};

export { useCustomizeColorOfHigilightedPoint };
