import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { PageHeading } from "./components";

// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";

import { IformDetails } from "store/formDetails";
import apiLibrary from "services/api";
import { useNavigate } from "react-router-dom";
import { dynamicFormsStatusAction } from "store/filters/reducer.actions";
import { useDispatch } from "react-redux";
import { panelsJson } from "../..";
import structureFormJSON from "../../utils/structureFormJSON";
import { panelsName } from "../../panelsTitle";
import questionTypes from "../../questionTypes";
import { IfetchFormDatails } from "store/formDetails/initialState";
import {
  displayGeographyView,
  displaySurveyView,
  setFormJson,
} from "store/surveyJS";
import moment from "moment";
import usePermissions from "hooks/usePermissions";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";

const generateDynamicUserLinks = (formDetails: IformDetails) => {
  return [
    { path: `/forms/list`, label: `Forms` },
    { path: `/forms/${formDetails.id}/edit`, label: `Edit` },
    { path: ``, label: formDetails?.name },
  ];
};

export const Header = ({ creator, dataSourcesData }: any) => {
  
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchFormDatails
  >((state) => state.formSurveyJsDetails);
  const surveyJSStates = useSelector((state: RootState) => state.surveyJS);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [allow, setAllow] = useState(true);

  const { dynamicForms } = usePermissions();

  const dynamicUsersLinks = generateDynamicUserLinks(formDetails);

  // Handlers

  // handle Click on Publish button
  const handleClickOnPublishOrSaveAsDraftsBtn = async (
    status: string,
    surveyJSStates: any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    const {
      shapePickers,
      pointPickers,
      drawPolygons,
      dropPins,
      getGpsDatas,
      harvestPanelMapQuestions,
      geographicalPanelMapQuestions,
      addedQuestionState,
    } = surveyJSStates;

    let jsonData = { ...creator.JSON };

    if (jsonData) {
      delete jsonData.logoPosition;
    }

    const isError = validation(jsonData);

    if (isError) {
      return;
    }

    if (verifyExistenceOfGeospatialQuestions(jsonData)) {
      dispatch(displayGeographyView());
      dispatch(setFormJson({ json: jsonData, panelJson: panelsJson, status }));
      return;
    } else {
      dispatch(displaySurveyView());
    }

    // setLoading(true);
    try {
      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      setLoading(true);

      const formFields = structureFormJSON(
        jsonData,
        panelsJson,
        dataStoredInGlobalState,
        "request",
        dataSourcesData
      );

      const res = await apiLibrary.Forms.updateFormFields(formDetails.id, {
        form_fields: formFields,
        status: status,
      });

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const { displayView } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );


  // handlers
  const goBackToAllLayersScreen = () => {
    if (displayView === "geography") {
      dispatch(displaySurveyView());
    } else if (displayView === "survey") {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="w-full pt-4 pb-6 ">
      <div className="flex flex-col items-start justify-start flex-grow gap-2">
        <Breadcrumb links={dynamicUsersLinks}  handleClick={goBackToAllLayersScreen} />
        <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <PageHeading communityId={""} name={formDetails.name} />
          <div className="relative flex items-center justify-end gap-2 ">
            <button
              onClick={() =>
                handleClickOnPublishOrSaveAsDraftsBtn("draft", surveyJSStates)
              }
              disabled={loading || !dynamicForms.canEditDynamicFormFields}
              className={`${
                loading ? "opacity-50 cursor-progress" : ""
              } flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary  hover:bg-primaryExtraLight cursor-pointer`}
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary">
                  Save as Draft
                </p>
              </div>
            </button>
            <button
              onClick={() =>
                handleClickOnPublishOrSaveAsDraftsBtn(
                  "published",
                  surveyJSStates
                )
              }
              disabled={loading || !dynamicForms.canEditDynamicFormFields}
              className={`${
                loading ? "opacity-50 cursor-progress" : ""
              } flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 py-1 rounded-3xl border-2 border-primary bg-primary hover:bg-primaryDark cursor-pointer`}
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-textWhite">
                  Publish
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function isValidPattern(pattern: string) {
  // Regex definitions for date and time with optional am/pm
  const validDateRegex =
    /^(?:(d{1,2}|m{1,2}|y{2}|y{4})(?:[-/])){2}(d{1,2}|m{1,2}|y{2}|y{4})$/;
  const validTimeRegex = /^(h{1,2}|H{1,2}):MM:ss$/;
  const amPmRegex = /^(tt|TT)$/;

  // Split pattern by spaces to separate date, time, and optional am/pm parts
  const parts = pattern.trim().split(/\s+/);

  // Ensure there are at most three parts (date, time, and optional am/pm)
  if (parts.length > 3) {
    return { valid: false, error: "Pattern contains invalid extra elements." };
  }

  // Identify date, time, and am/pm parts
  let datePart = null;
  let timePart = null;
  let amPmPart = null;

  for (const part of parts) {
    if (validDateRegex.test(part)) {
      datePart = part;
    } else if (validTimeRegex.test(part)) {
      timePart = part;
    } else if (amPmRegex.test(part)) {
      amPmPart = part;
    }
  }

  // If there are three parts, the third part must be am/pm
  if (parts.length === 3 && !amPmPart) {
    return { valid: false, error: "Pattern contains invalid extra elements." };
  }

  // Both date and time are required
  if (!datePart || !timePart) {
    return {
      valid: false,
      error:
        "Both a valid date and time must be included in the pattern. Please click on the help icon next to Value Pattern to see available formats.",
    };
  }

  // Check combined date and time with optional am/pm
  if (datePart && timePart) {
    return validateDateComponents(datePart);
  }

  // Handling specific errors based on content
  if (!datePart) {
    return {
      valid: false,
      error:
        "A valid date must be included in the pattern. Please click on the help icon next to Value Pattern to see available date formats.",
    };
  }

  if (timePart && !validTimeRegex.test(timePart)) {
    return {
      valid: false,
      error:
        "A valid time must be included in the pattern. Please click on the help icon next to Value Pattern to see available time formats.",
    };
  }

  return {
    valid: false,
    error:
      "Pattern is incorrectly formatted. Ensure it matches a valid date and time format.",
  };
}

// Helper function to check for duplicates and missing components in date patterns
function validateDateComponents(pattern: string) {
  const placeholders = pattern.split(/[-/]/); // Updated regex to allow only - and .
  const counts = { d: 0, m: 0, y: 0 };
  placeholders.forEach((p) => {
    if (p === "d" || p === "dd") counts.d++;
    if (p === "m" || p === "mm") counts.m++;
    if (p === "yy" || p === "yyyy") counts.y++;
  });

  if (counts.d > 1 || counts.m > 1 || counts.y > 1) {
    return {
      valid: false,
      error: "Duplicate placeholders detected for day, month, or year.",
    };
  }
  if (counts.d === 0 || counts.m === 0 || counts.y === 0) {
    return {
      valid: false,
      error: "Each date component (day, month, year) must appear exactly once.",
    };
  }

  return { valid: true };
}

const validateDateRange = (minDate: any, maxDate: any) => {
  if (minDate && maxDate && new Date(minDate) > new Date(maxDate)) {
    return {
      valid: false,
      error: `The maximum value cannot be less than the minimum value.`,
    };
  }
  return { valid: true };
};

function validation(jsonData: any) {
  let isError = false;

  if (!jsonData.pages) {
    Toasts.error("Please add at least one question");
    isError = true;
  } else if (!jsonData?.pages[0]?.elements) {
    Toasts.error("Please add at least one question");
    isError = true;
  } else {
    // Iterate through each element within the first page
    for (const element of jsonData.pages[0]?.elements) {
      if (element.type === "panel") {
        // Check if "panel" has elements
        if (!element.elements || element.elements.length === 0) {
          Toasts.error(
            `Panel with name "${element.name}" should have at least one element.`
          );
          isError = true;
        }
        if (element.elements && element.elements.length) {
          element.elements.forEach((subElement: any) => {
            if (
              subElement.maskType === "datetime" &&
              subElement.maskSettings?.pattern
            ) {
              // Check if the pattern matches the strict regex and moment validation
              let validationResult = isValidPattern(
                subElement?.maskSettings?.pattern
              );

              if (!validationResult.valid) {
                Toasts.error(
                  `Element with name "${subElement.name}" has an invalid value pattern: ${validationResult?.error}`
                );
                isError = true;
                return;
              }

              // Validate min and max dates
              const { min, max } = subElement.maskSettings || {};
              const dateRangeValidation = validateDateRange(min, max);

              if (!dateRangeValidation.valid) {
                Toasts.error(
                  `Element with name "${subElement.name}" has an invalid date range: ${dateRangeValidation?.error}`
                );
                isError = true; // Return true to indicate an error
                return;
              }
            }
          });
        }
      } else if (element.type === "paneldynamic") {
        // Check if "paneldynamic" has template elements
        if (
          !element.templateElements ||
          element.templateElements.length === 0
        ) {
          Toasts.error(
            `Dynamic panel with name "${element.name}" should have at least one question.`
          );
          isError = true;
        }

        if (element.templateElements && element.templateElements.length) {
          element.templateElements.forEach((subElement: any) => {
            if (
              subElement.maskType === "datetime" &&
              subElement.maskSettings?.pattern
            ) {
              // Check if the pattern matches the strict regex and moment validation
              let validationResult = isValidPattern(
                subElement.maskSettings.pattern
              );
              if (!validationResult.valid) {
                Toasts.error(
                  `Element with name "${subElement.name}" has an invalid value pattern: ${validationResult?.error}`
                );
                isError = true;
                return;
              }

              // Validate min and max dates
              const { min, max } = subElement.maskSettings || {};
              const dateRangeValidation = validateDateRange(min, max);

              if (!dateRangeValidation.valid) {
                Toasts.error(
                  `Element with name "${subElement.name}" has an invalid date range: ${dateRangeValidation?.error}`
                );
                isError = true; // Return true to indicate an error
                return;
              }
            }
          });
        }
      }
    }
  }

  return isError;
}

function verifyExistenceOfGeospatialQuestions(jsonData: any) {
  const geospatialComponentsName = [
    questionTypes.shapePicker,
    questionTypes.pointPicker,
    questionTypes.drawPolygon,
    questionTypes.dropPin,
  ];

  const elements = jsonData?.pages?.[0]?.elements;

  for (let index = 0; index < elements?.length; index++) {
    const element = elements[index];

    if (
      geospatialComponentsName.includes(element.type) ||
      element.name === panelsName.HARVEST ||
      element.name === panelsName.GEOGRAPHICAL_INFORMATION
    ) {
      return true;
    }

    if (
      element.type === questionTypes.paneldynamic ||
      element.type === questionTypes.panel
    ) {
      const panelElements =
        element.type === questionTypes.paneldynamic
          ? element.templateElements
          : element.elements;

      for (let index = 0; index < panelElements?.length; index++) {
        const panelEle = panelElements[index];

        if (geospatialComponentsName.includes(panelEle.type)) {
          return true;
        }
      }
    }
  }

  return false;
}
