import LinkDisconnectIcon from "assets/icons/HeroIcons/LinkDisconnectIcon";
import { FormikProps, FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { FileUpload } from "../../components/fileUpload";
import SearchInput from "../../components/SearchInput";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useSelector } from "react-redux";
import { RootState } from "store";
import CustomCheckbox from "view/components/CheckBox";
import usePermissions from "hooks/usePermissions";

// Program Interface
interface ProgramItem {
  value: string;
  label: string;
}

// Form Interface
interface FormItem {
  value: string;
  label: string;
}

// Interface For Modal Form extending Formik values
interface ProtocolModalFormProps extends FormikProps<FormikValues> {
  handleClose: () => void;
  handleOnDropFiles: (files: any) => void;
  handleDeleteFile: (id: any) => void;
  handleUpdateFile: (file: any, id: any) => void;
  files: any;
  acceptedFileTypes: any;
  onFileDrop: (files: any) => void;
  editMode: boolean;
  duplicateMode: boolean;
  programList: ProgramItem[];
  formsList: FormItem[];
  handleProgramSearch: (search: string) => void;
  fetchProtocolFormsByPrograms: (
    programId: number,
    searchString?: string
  ) => void;
  setFormsList: any;
  handleMenuScrollToBottom?: () => void;
  loading: boolean;
}

const ProtocolModalForm: React.FC<ProtocolModalFormProps> = ({
  handleClose,
  setFieldValue,
  handleChange,
  values,
  handleBlur,
  errors,
  touched,
  handleOnDropFiles,
  handleDeleteFile,
  handleUpdateFile,
  files,
  acceptedFileTypes,
  onFileDrop,
  editMode,
  duplicateMode,
  programList,
  isSubmitting,
  handleProgramSearch,
  fetchProtocolFormsByPrograms,
  formsList,
  setFormsList,
  handleMenuScrollToBottom,
  loading
}) => {
  // States
  const [inputValue, setInputValue] = useState("");
  const [forminputValue, setFormInputValue] = useState("");
  const { addEditDuplicateProtocolModal } = useSelector(
    (state: RootState) => state.modals
  );
  // Making program and form values empty on disconnecting protocol
  const disconnectProtocol = () => {
    if (values?.programId?.label !== "" || values?.formId?.label !== "") {
      setFieldValue("programId", { value: "", label: "" });
      setFieldValue("formId", []);
    }
    return;
  };

  const filteredForms = values?.formId?.filter((form: any) => form.isInherit === false || form.isInherit === undefined);

  const form = filteredForms && filteredForms.length > 0
    ? filteredForms.map((form: any) => ({
      label: form?.name || form?.label,
      value: form?.id || form?.value
    }))
    : null;

  const isFormAttachedAlready = addEditDuplicateProtocolModal.data?.formId?.some((form: any) => form?.isInherit === false);

  return (
    <div className="p-6 w-[900px] rounded-lg overflow-y-auto bg-bgWhite dark:bg-secondaryLight">
      <div className="relative flex flex-col items-start justify-start gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
        <div className="flex justify-between items-start self-stretch py-0.5">
          <div className="relative flex items-center justify-start">
            <p className="flex-grow text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
              {editMode
                ? "Edit Protocol"
                : duplicateMode
                  ? "Duplicate Protocol"
                  : "Create Protocol"}
            </p>
          </div>
          <button onClick={handleClose} type="button">
            <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
          </button>
        </div>
      </div>
      <div className="w-full overflow-y-auto px-1 pb-4 max-h-[70vh]">
        <div className="mt-4">
          <p className="pt-2 text-textMidLight font-Overpass dark:text-textMain">
            Choose the program and form you want to connect to this protocol.
          </p>
        </div>
        <div>
          <div className="flex w-full gap-4">
            <div className="w-1/2">
              <SearchInput
                isDisable={editMode && addEditDuplicateProtocolModal.data?.programId.label !== ""}
                inputValue={inputValue}
                name="programId"
                label="Program*"
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleProgramSearch(value);
                }}
                options={programList}
                defaultValue={{ value: "", label: "" }}
                isMulti={false}
                value={values?.programId?.value ? [values?.programId] : []}
                onChange={(selectedOption: any) => {
                  if (selectedOption && selectedOption?.value) {
                    fetchProtocolFormsByPrograms(selectedOption.value);
                  } else if (selectedOption == null) {
                    setFormsList([]);
                  }
                  setFieldValue("formId", null);

                  setFieldValue("programId", selectedOption);
                }}
                placeholder="Program Name"
                touched={touched.programId}
                error={errors?.programId}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                isLoading={loading}
              />
            </div>
            <div className="w-1/2">
              <SearchInput
                isDisable={isFormAttachedAlready}
                inputValue={forminputValue}
                name="formId"
                label="Form"
                onInputChange={(value: string) => {
                  setFormInputValue(value);
                  if (values?.programId?.value) {
                    fetchProtocolFormsByPrograms(values.programId.value, value);
                  }
                }}
                options={(values?.programId && values?.programId?.label) ? formsList : []}
                defaultValue={{ value: "", label: "" }}
                isMulti={false}
                value={form}
                onChange={(selectedOption: any) => {
                  setFieldValue("formId", [selectedOption]);
                }}
                placeholder="Form Name"
                touched={touched.formId}
                error={errors?.formId}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={
              ((values?.programId == null || values?.programId?.label == "") &&
                (values?.formId && values.formId.length < 1)) || editMode
            }
            className="inline-flex gap-1 py-4 font-medium cursor-pointer disabled:text-secondaryLight text-textMid disabled:cursor-not-allowed dark:text-textMain"
            onClick={disconnectProtocol}
          >
            <LinkDisconnectIcon />
            Disconnect Protocol
          </button>

        </div>
        <div>
          <TextInput
            label="Title*"
            type="text"
            placeholder="Protocol title"
            name="title"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values?.title}
            error={errors?.title}
            touched={touched.title}
          />
        </div>
        <div>
          <TextInput
            label="Description*"
            type="text"
            rows={5}
            fieldAs="textarea"
            placeholder="Add your description..."
            name="description"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.description}
            error={errors?.description}
            touched={touched.description}
          />
        </div>
        <FileUpload
          handleOnDropFiles={handleOnDropFiles}
          handleDeleteFile={handleDeleteFile}
          handleUpdateFile={handleUpdateFile}
          files={files}
          editMode={editMode}
          acceptedFileTypes={acceptedFileTypes}
          onFileDrop={onFileDrop}
          isSubmitting={isSubmitting}
          duplicateMode={duplicateMode}
        />
      </div>
      <div className={`flex items-center self-stretch gap-2 mt-2 relative ${editMode ? 'justify-between' : 'justify-end'}`}>
        {editMode == true && <div className="">
          <CustomCheckbox
            name="notify"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // Assuming 'value' is the selected choice
              setFieldValue("notify", !values.notify);
            }}
            checked={values.notify}
            label="Yes, notify user that the protocol is updated"
            labelPlacement="end"
            labelClassName="text-left text-textMidLight font-Overpass font-medium leading-6 text-base mt-1"
          />
        </div>}
        <div className="flex gap-2 relative right-0">
          <Button
            type="button"
            text="Cancel"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="w-24 h-11"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="outlined"
            onClick={handleClose}
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            text="Save"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="w-24 h-11 border-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="filled"
          />
        </div>
      </div>
    </div>
  );
};

export default ProtocolModalForm;
