export interface UsersDirectoryOrder {
  columnVisibility: {
    personId: boolean;
    name: boolean;
    lastActive: boolean;
    status: boolean;
    programs: boolean;
    forms: boolean;
    usedApps: boolean;
    communities: boolean;
    observations: boolean;
    memberSince: boolean;
    email: boolean;
    phoneNumber: boolean;
    city: boolean;
    country: boolean;
    zipCode: boolean;
    state: boolean;
    address: boolean;
    apartment: boolean;
    select: boolean;
    role: boolean;
  };
  columnOrder: string[];
}

export const initialUsersDirectoryOrder: UsersDirectoryOrder = {
  columnVisibility: {
    personId: true,
    name: true,
    lastActive: true,
    status: true,
    usedApps: true,
    programs: true,
    forms: true,
    communities: true,
    observations: true,
    memberSince: true,
    role: true,
    email: false,
    phoneNumber: false,
    city: false,
    country: false,
    zipCode: false,
    state: false,
    address: false,
    apartment: false,
    select: true,
  },
  columnOrder: [
    "select",
    "personId",
    "name",
    "role",
    "usedApps",
    "lastActive",
    "status",
    "programs",
    "forms",
    "communities",
    "observations",
    "memberSince",
    "email",
    "phoneNumber",
    "city",
    "country",
    "zipCode",
    "state",
    "address",
    "apartment",
  ],
};

export interface OrganizationsDirectoryOrder {
  columnVisibility: {
    name: boolean;
    tags: boolean;
    programs: boolean;
    communities: boolean;
    members: boolean;
    dateAdded: boolean;
    id: boolean;
  };
  columnOrder: string[];
}

export const initialOrganizationsDirectoryOrder: OrganizationsDirectoryOrder = {
  columnVisibility: {
    name: true,
    tags: true,
    programs: true,
    communities: true,
    members: true,
    dateAdded: true,
    id: false,
  },
  columnOrder: [
    "select",
    "name",
    "tags",
    "programs",
    "communities",
    "members",
    "dateAdded",
    "id",
  ],
};

export interface CommunitiesDirectoryOrder {
  columnVisibility: {
    communityName: boolean;
    organizations: boolean;
    type: boolean;
    programs: boolean;
    members: boolean;
    dateAdded: boolean;
    communityAdmins: boolean;
  };
  columnOrder: string[];
}

export const initialCommunitiesDirectoryOrder: CommunitiesDirectoryOrder = {
  columnVisibility: {
    communityName: true,
    organizations: true,
    type: true,
    programs: true,
    members: true,
    dateAdded: true,
    communityAdmins: false,
  },
  columnOrder: [
    "select",
    "communityName",
    "organizations",
    "type",
    "programs",
    "members",
    "dateAdded",
    "communityAdmins",
  ],
};

export interface DynamicFormDirectoryOrder {
  columnVisibility: {
    formName: boolean;
    programName: boolean;
    createdBy: boolean;
    protocol: boolean;
    numberOfObservations: boolean;
    dateAdded: boolean;
    dateUpdated: boolean;
    archivedBy: boolean;
    dateArchived: boolean;
  };
  columnOrder: string[];
}

export const initialDynamicFormsDirectoryOrder: DynamicFormDirectoryOrder = {
  columnVisibility: {
    formName: true,
    programName: true,
    createdBy: true,
    protocol: true,
    numberOfObservations: true,
    dateAdded: true,
    dateUpdated: true,
    archivedBy: false,
    dateArchived: false,
  },
  columnOrder: [
    "select",
    "formName",
    "programName",
    "createdBy",
    "archivedBy",
    "protocol",
    "numberOfObservations",
    "dateAdded",
    "dateArchived",
    "dateUpdated",
  ],
};

export interface ProgramsDirectoryOrder {
  columnVisibility: {
    programName: boolean;
    dataAccessPolicy: boolean;
    members: boolean;
    type: boolean;
    numberOfObservations: boolean;
    connectedForms: boolean;
    protocol: boolean;
    timeframe: boolean;
    pausedSince: boolean;
    programCode: boolean;
    organizationName: boolean;
    communityName: boolean;
  };
  columnOrder: string[];
}

export const initialProgramsDirectoryOrder: ProgramsDirectoryOrder = {
  columnVisibility: {
    programName: true,
    dataAccessPolicy: true,
    members: true,
    type: true,
    numberOfObservations: true,
    connectedForms: true,
    protocol: true,
    timeframe: true,
    programCode: true,
    pausedSince: true,
    organizationName: false,
    communityName: false,
  },

  columnOrder: [
    "select",
    "programName",
    "programCode",
    "dataAccessPolicy",
    "timeframe",
    "type",
    "protocol",
    "members",
    "connectedForms",
    "numberOfObservations",
    "pausedSince",
    "organizationName",
    "communityName",
  ],
};

export interface ProtocolsDirectoryOrder {
  columnVisibility: {
    protocolName: boolean;
    programName: boolean;
    formName: boolean;
    createdBy: boolean;
    dateCreated: boolean;
    dateUpdated: boolean;
    archivedBy: boolean;
  };
  columnOrder: string[];
}

export const initialProtocolsDirectoryOrder: ProtocolsDirectoryOrder = {
  columnVisibility: {
    protocolName: true,
    programName: true,
    formName: true,
    createdBy: true,
    dateCreated: true,
    dateUpdated: true,
    archivedBy: false,
  },
  columnOrder: [
    "select",
    "protocolName",
    "programName",
    "formName",
    "createdBy",
    "dateCreated",
    "dateUpdated",
    "dateArchived",
    "archivedBy",
  ],
};
export interface NotificationsrecipientsDirectoryOrder {
  columnVisibility: {
    recipientName: boolean;
    notificationCenter: boolean;
    toast: boolean;
    push: boolean;
    email: boolean;
  };
  columnOrder: string[];
}

export const initialNotificationRecipientsDirectoryOrder: NotificationsrecipientsDirectoryOrder =
{
  columnVisibility: {
    recipientName: true,
    notificationCenter: true,
    toast: true,
    push: true,
    email: true,
  },
  columnOrder: [
    "select",
    "recipientName",
    "notificationCenter",
    "toast",
    "push",
    "email",
  ],
};

export interface ObservationsDirectoryOrder {
  columnVisibility: {
    programName: boolean;
    status: boolean;
    observer: boolean;
    submissionDate: boolean;
    observationCode: boolean;
    communityName: boolean;
    formName: boolean;
    oldCode: boolean;
  };
  columnOrder: string[];
}

export const initialObservationsDirectoryOrder: ObservationsDirectoryOrder = {
  columnVisibility: {
    programName: true,
    status: true,
    observer: true,
    submissionDate: true,
    observationCode: true,
    communityName: true,
    oldCode: true,
    formName: false,
  },

  columnOrder: [
    "select",
    "observationCode",
    "oldCode",
    "observer",
    "status",
    "programName",
    "communityName",
    "formName",
    "submissionDate",
  ],
};
export interface ManagerDirectoryOrder {
  columnVisibility: {
    reviewer: boolean;
    lastActive: boolean;
    role: boolean;
    community: boolean;
    observationsReviewed: boolean;
    reviewerSince: boolean;
    activeObservations: boolean;
    status: boolean;
    dataEndViewerSince: boolean;
    endOfAccess: boolean;
    activeReportAccess: boolean;
  };
  columnOrder: string[];
}

export const initialManagerDirectoryOrder: ManagerDirectoryOrder = {
  columnVisibility: {
    reviewer: true,
    lastActive: true,
    role: true,
    community: true,
    observationsReviewed: true,
    activeObservations: false,
    status: false,
    activeReportAccess: false,
    dataEndViewerSince: false,
    reviewerSince: false,
    endOfAccess: false,
  },
  columnOrder: [
    "select",
    "reviewer",
    "role",
    "lastActive",
    "community",
    "status",
    "observationsReviewed",
    "activeObservations",
    "activeReportAccess",
    "dataEndViewerSince",
    "reviewerSince",
    "endOfAccess",
  ],
};

export interface QualityAssuranceObservationsDirectoryOrder {
  columnVisibility: {
    programName: boolean;
    status: boolean;
    observer: boolean;
    dateUpdated: boolean;
    observationCode: boolean;
    communityName: boolean;
    unreadMessages: boolean;
    flaggedSections: boolean;
    reviewer: boolean;
  };
  columnOrder: string[];
}

export const initialQualityAssuranceObservationsDirectoryOrder: QualityAssuranceObservationsDirectoryOrder =
{
  columnVisibility: {
    programName: true,
    status: true,
    observer: true,
    dateUpdated: true,
    observationCode: true,
    communityName: true,
    unreadMessages: true,
    flaggedSections: true,
    reviewer: true,
  },

  columnOrder: [
    "select",
    "observationCode",
    "status",
    "unreadMessages",
    "flaggedSections",
    "observer",
    "programName",
    "communityName",
    "reviewer",
    "dateUpdated",
  ],
};
export interface CategoriesDirectoryOrder {
  columnVisibility: {
    categoryName: boolean;
    categoryCode: boolean;
  };
  columnOrder: string[];
}

export const initialCategoriesDirectoryOrder: CategoriesDirectoryOrder = {
  columnVisibility: {
    categoryName: true,
    categoryCode: true,
  },

  columnOrder: ["select", "categoryName", "categoryCode"],
};

export interface BehaviorsDirectoryOrder {
  columnVisibility: {
    behaviorName: boolean;
    types: boolean;
  };
  columnOrder: string[];
}

export const initialBehaviorsDirectoryOrder: BehaviorsDirectoryOrder = {
  columnVisibility: {
    behaviorName: true,
    types: true,
  },

  columnOrder: ["select", "id", "behaviorName", "types"],
};

export interface ConditionsDirectoryOrder {
  columnVisibility: {
    conditionName: boolean;
    types: boolean;
  };
  columnOrder: string[];
}

export const initialConditionsDirectoryOrder: ConditionsDirectoryOrder = {
  columnVisibility: {
    conditionName: true,
    types: true,
  },

  columnOrder: ["select", "conditionName", "types"],
};

export interface TypesDirectoryOrder {
  columnVisibility: {
    categoryName: boolean;
    typeName: boolean;
  };
  columnOrder: string[];
}

export const initialTypesDirectoryOrder: TypesDirectoryOrder = {
  columnVisibility: {
    typeName: true,
    categoryName: true,
  },

  columnOrder: ["select", "typeName", "categoryName"],
};

export interface AdminTrackingDirectoryOrder {
  columnVisibility: {
    adminName: boolean;
    action: boolean;
    entityName: boolean;
    dateAdded: boolean;
  };
  columnOrder: string[];
}

export const initialAdminTrackingDirectoryOrder: AdminTrackingDirectoryOrder = {
  columnVisibility: {
    adminName: true,
    action: true,
    entityName: true,
    dateAdded: true,
  },

  columnOrder: ["select", "adminName", "action", "entityName", "dateAdded"],
};

export interface SpeciesDirectoryOrder {
  columnVisibility: {
    image: boolean;
    commonName: boolean;
    scientificName: boolean;
    alternativeNames: boolean;
    type: boolean;
    dateAdded: boolean;
  };
  columnOrder: string[];
}

export const initialSpeciesDirectoryOrder: SpeciesDirectoryOrder = {
  columnVisibility: {
    image: true,
    commonName: true,
    scientificName: true,
    alternativeNames: true,
    type: true,
    dateAdded: true,
  },

  columnOrder: [
    "select",
    "image",
    "commonName",
    "scientificName",
    "alternativeNames",
    "type",
    "dateAdded",
  ],
};

export interface NotificationsDirectoryOrder {
  columnVisibility: {
    notificationName: boolean;
    targetBy: boolean;
    totalUsersReceived: boolean;
    notificationCenter: boolean;
    toast: boolean;
    push: boolean;
    email: boolean;
    createdBy: boolean;
    scheduledFor: boolean;
  };
  columnOrder: string[];
}
export interface ObjectsDirectoryOrder {
  columnVisibility: {
    image: boolean;
    objectName: boolean;
    description: boolean;
    type: boolean;
    dateAdded: boolean;
  };
  columnOrder: string[];
}

export const initialNotificationsDirectoryOrder: NotificationsDirectoryOrder = {
  columnVisibility: {
    notificationName: true,
    targetBy: true,
    totalUsersReceived: true,
    notificationCenter: true,
    toast: true,
    push: true,
    email: true,
    createdBy: true,
    scheduledFor: true,
  },

  columnOrder: [
    "select",
    "notificationName",
    "targetBy",
    "totalUsersReceived",
    "notificationCenter",
    "toast",
    "push",
    "email",
    "createdBy",
    "scheduledFor",
  ],
};

// export interface ReportsDirectoryOrder {
//   columnVisibility: {
//     reportName: boolean;
//     formsIncluded: boolean;
//     recurrenceSettings: boolean;
//     createdBy: boolean;
//     totalViews: boolean;
//     totalDownloads: boolean;
//     dateAdded: boolean;
//   };
//   columnOrder: string[];
// }
export interface ReportsDirectoryOrder {
  columnVisibility: {
    reportName: boolean;
    formsIncluded: boolean;
    lastRun: boolean;
    nextRun: boolean;
    runsInQueue: boolean;
    recurrenceSettings: boolean;
    createdBy: boolean;
    totalViews: boolean;
    totalDownloads: boolean;
    dateAdded: boolean;
    dateUpdated: boolean;
  };
  columnOrder: string[];
}
export const initialReportsDirectoryOrder: ReportsDirectoryOrder = {
  columnVisibility: {
    reportName: true,
    formsIncluded: true,
    lastRun: true,
    nextRun: true,
    runsInQueue: true,
    recurrenceSettings: false,
    createdBy: true,
    totalViews: false,
    totalDownloads: false,
    dateAdded: true,
    dateUpdated: false,
  },
  columnOrder: [
    "select",
    "reportName",
    "formsIncluded",
    "lastRun",
    "nextRun",
    "runsInQueue",
    "createdBy",
    "dateAdded",
    "recurrenceSettings",
    "totalViews",
    "totalDownloads",
    "dateUpdated",
  ],
};
export interface ReportsProfileDirectoryOrder {
  columnVisibility: {
    version: boolean;
    processedReports: boolean;
    runDateTime: boolean;
    timeRange: boolean;
    processingStatus: boolean;
    observationsProcessed: boolean;
    viewed: boolean;
    downloaded: boolean;
    dateAdded: boolean;
  };
  columnOrder: string[];
}

export const initialReportsProfileDirectoryOrder: ReportsProfileDirectoryOrder =
{
  columnVisibility: {
    version: true,
    processedReports: true,
    runDateTime: true,
    timeRange: true,
    processingStatus: true,
    observationsProcessed: true,
    viewed: true,
    downloaded: true,
    dateAdded: true,
  },
  columnOrder: [
    "select",
    "version",
    "processedReports",
    "runDateTime",
    "timeRange",
    "processingStatus",
    "observationsProcessed",
    "viewed",
    "downloaded",
    "dateAdded",
  ],
};

export const initialObjectsDirectoryOrder: ObjectsDirectoryOrder = {
  columnVisibility: {
    image: true,
    objectName: true,
    description: true,
    type: true,
    dateAdded: true,
  },

  columnOrder: [
    "select",
    "image",
    "objectName",
    "description",
    "type",
    "dateAdded",
  ],
};

export interface TagsDirectoryOrder {
  columnVisibility: {
    tagName: boolean;
    description: boolean;
    taggedEntities: boolean;
  };
  columnOrder: string[];
}

export const initialTagsDirectoryOrder: TagsDirectoryOrder = {
  columnVisibility: {
    description: true,
    taggedEntities: true,
    tagName: true,
  },

  columnOrder: ["tagName", "description", "taggedEntities"],
};

export interface GeographyDirectoryOrder {
  columnVisibility: {
    layerName: boolean;
    description: boolean;
    createdBy: boolean;
    dateAdded: boolean;
  };
  columnOrder: string[];
}

export const initialGeographyDirectoryOrder: GeographyDirectoryOrder = {
  columnVisibility: {
    layerName: true,
    description: true,
    createdBy: true,
    dateAdded: true,
  },
  columnOrder: ["select", "layerName", "description", "createdBy", "dateAdded"],
};

export interface ReportsAnalyticsDirectoryOrder {
  columnVisibility: {
    member: boolean;
    reportVersion: boolean;
    viewed: boolean;
    download: boolean;
    date: boolean;
  };
  columnOrder: string[];
}

export const initialReportsAnalyticsDirectoryOrder: ReportsAnalyticsDirectoryOrder =
{
  columnVisibility: {
    member: true,
    reportVersion: true,
    viewed: true,
    download: true,
    date: true,
  },
  columnOrder: [
    "select",
    "member",
    "reportVersion",
    "viewed",
    "download",
    "date",
  ],
};
export interface ReportsResultDirectoryOrder {
  columnVisibility: {
    obsCode: boolean;
    dateAdded: boolean;
    Observer: boolean;
  };
  columnOrder: string[];
}

export const initialReportsReportsDirectoryOrder: ReportsResultDirectoryOrder =
{
  columnVisibility: {
    obsCode: true,
    dateAdded: true,
    Observer: true,
  },
  columnOrder: ["obsCode", "dateAdded", "Observer"],
};

export interface ImportsDirectoryOrder {
  columnVisibility: {
    fileName: boolean;
    dataReceivedFrom: boolean;
    numberOfObservations: boolean;
    importedBy: boolean;
    importedDate: boolean;
    id: boolean;
  };
  columnOrder: string[];
}

export const initialImportsDirectoryOrder: ImportsDirectoryOrder = {
  columnVisibility: {
    fileName: true,
    dataReceivedFrom: true,
    numberOfObservations: true,
    importedBy: true,
    importedDate: true,
    id: false,
  },
  columnOrder: [
    "select",
    "fileName",
    "dataReceivedFrom",
    "numberOfObservations",
    "importedBy",
    "importedDate",
    "id",
  ],
};

export interface ProgramAdminsDirectoryOrder {
  columnVisibility: {
    name: boolean;
    personImage: boolean;
    programs: boolean;
    date: boolean;
    id: boolean;
    personId: boolean;
  };
  columnOrder: string[];
}

export const initialProgramAdminsDirectoryOrder: ProgramAdminsDirectoryOrder = {
  columnVisibility: {
    name: true,
    programs: true,
    date: true,
    personImage: false,
    id: false,
    personId: false,
  },
  columnOrder: [
    "select",
    "name",
    "programs",
    "date",
    "personImage",
  ],
};

export interface ImportSummaryOrder {
  columnVisibility: {
    columnNameFromFile: boolean;
    mapToField: boolean;
    sampleFileContent: boolean;
  };
  columnOrder: string[];
}

export const initialImportSummaryOrder: ImportSummaryOrder = {
  columnVisibility: {
    columnNameFromFile: true,
    mapToField: true,
    sampleFileContent: true,
  },
  columnOrder: ["columnNameFromFile", "mapToField", "sampleFileContent"],
};

export interface RolesPermissionsDirectoryOrder {
  columnVisibility: {
    roleName: boolean;
    description: boolean;
    editable: boolean;
    users: boolean;
    id: boolean;
  };
  columnOrder: string[];
}

export const initialRolesPermissionsOrder: RolesPermissionsDirectoryOrder = {
  columnVisibility: {
    roleName: true,
    description: true,
    editable: true,
    users: true,
    id: false,
  },
  columnOrder: ["select", "roleName", "description", "editable", "users", "id"],
};

export interface ImportFileDataDirectoryOrder {
  columnVisibility: {
    importStatus: boolean;
    observationCode: boolean;
    observationStatus: boolean;
    observer: boolean;
    programName: boolean;
    communityName: boolean;
    observationDate: boolean;
  };
  columnOrder: string[];
}

export const initialImportFileDataDirectoryOrder: ImportFileDataDirectoryOrder =
{
  columnVisibility: {
    importStatus: true,
    observationCode: true,
    observationStatus: true,
    observer: true,
    programName: true,
    communityName: true,
    observationDate: true,
  },

  columnOrder: [
    "select",
    "importStatus",
    "observationCode",
    "observationStatus",
    "observer",
    "programName",
    "communityName",
    "observationDate",
  ],
};

export interface PollsAndSurveysDirectoryOrder {
  columnVisibility: {
    surveyName: boolean;
    createdBy: boolean;
    numberOfResponses: boolean;
    dateCreated: boolean;
    // dateUpdated: boolean;
    programName: boolean;
    archivedBy: boolean;
    dateArchived: boolean;
    description: boolean;
  };
  columnOrder: string[];
}

export const initialPollsAndSurveysDirectory: PollsAndSurveysDirectoryOrder = {
  columnVisibility: {
    surveyName: true,
    createdBy: true,
    programName: true,
    numberOfResponses: true,
    dateCreated: true,
    // dateUpdated: true,
    archivedBy: false,

    dateArchived: false,
    description: false,
  },
  columnOrder: [
    "select",
    "surveyName",
    "numberOfResponses",
    "expiryDate",
    "createdBy",
    "programName",
    "dateCreated",
    "archivedBy",
    "dateArchived",
    // "dateUpdated",
    "description",
  ],
};

export interface PollAndSurveyResultsDirectoryOrder {
  columnVisibility: {
    name: boolean;
    createdBy: boolean;
    responses: boolean;
    dateAdded: boolean;
    dateUpdated: boolean;
    archivedBy: boolean;
    dateArchived: boolean;
    description: boolean;
  };
  columnOrder: string[];
}

export const initialPollAndSurveyResultsDirectory: PollAndSurveyResultsDirectoryOrder =
{
  columnVisibility: {
    name: true,
    createdBy: true,
    responses: true,
    dateAdded: true,
    dateUpdated: true,
    archivedBy: false,
    dateArchived: false,
    description: false,
  },
  columnOrder: [
    "name",
    "createdBy",
    "archivedBy",
    "responses",
    "dateAdded",
    "dateArchived",
    "dateUpdated",
    "description",
  ],
};

export interface ReportVersionDataDirectoryOrder {
  columnVisibility: {
    group: boolean;
    answer: boolean;
  };
  columnOrder: string[];
}

export const initialReportVersionDataDirectory: ReportVersionDataDirectoryOrder =
{
  columnVisibility: {
    group: true,
    answer: true,
  },
  columnOrder: ["group", "answer"],
};

export interface IDirectoryState {
  usersDirectory: UsersDirectoryOrder;
  organizationsDirectory: OrganizationsDirectoryOrder;
  communitiesDirectory: CommunitiesDirectoryOrder;
  dynamicFormDirectory: DynamicFormDirectoryOrder;
  programsDirectory: ProgramsDirectoryOrder;
  protocolsDirectory: ProtocolsDirectoryOrder;
  observationsDirectory: ObservationsDirectoryOrder;
  managersDirectory: ManagerDirectoryOrder;
  qualityAssuranceObservationsDirectory: QualityAssuranceObservationsDirectoryOrder;
  categoriesDirectory: CategoriesDirectoryOrder;
  behaviorsDirectory: BehaviorsDirectoryOrder;
  conditionsDirectory: ConditionsDirectoryOrder;

  importsDirectory: ImportsDirectoryOrder;
  importSummaryDirectory: ImportSummaryOrder;
  importFileDataDirectory: ImportFileDataDirectoryOrder;
  typesDirectory: TypesDirectoryOrder;
  adminTrackingDirectory: AdminTrackingDirectoryOrder;
  speciesDirectory: SpeciesDirectoryOrder;
  notificationsDirectory: NotificationsDirectoryOrder;
  objectsDirectory: ObjectsDirectoryOrder;
  tagsDirectory: TagsDirectoryOrder;
  notificationsRecipientsDirectory: NotificationsrecipientsDirectoryOrder;
  geographyDirectory: GeographyDirectoryOrder;
  // reportsDirectory: ReportsDirectoryOrderssss;
  reportsDirectory: ReportsDirectoryOrder;
  reportsProfileDirectory: ReportsProfileDirectoryOrder;
  reportsAnalyticsDirectory: ReportsAnalyticsDirectoryOrder;
  reportResultDirectory: ReportsResultDirectoryOrder;
  rolesPermissionsDirectory: RolesPermissionsDirectoryOrder;
  pollsAndSurveysDirectory: PollsAndSurveysDirectoryOrder;
  reportVersionDataDirectory: ReportVersionDataDirectoryOrder,
  programAdminsDirectory: ProgramAdminsDirectoryOrder,
}

export const initialDirectoryOrder: IDirectoryState = {
  usersDirectory: initialUsersDirectoryOrder,
  organizationsDirectory: initialOrganizationsDirectoryOrder,
  communitiesDirectory: initialCommunitiesDirectoryOrder,
  dynamicFormDirectory: initialDynamicFormsDirectoryOrder,
  programsDirectory: initialProgramsDirectoryOrder,
  protocolsDirectory: initialProtocolsDirectoryOrder,
  observationsDirectory: initialObservationsDirectoryOrder,
  managersDirectory: initialManagerDirectoryOrder,
  qualityAssuranceObservationsDirectory:
    initialQualityAssuranceObservationsDirectoryOrder,
  categoriesDirectory: initialCategoriesDirectoryOrder,
  behaviorsDirectory: initialBehaviorsDirectoryOrder,
  conditionsDirectory: initialConditionsDirectoryOrder,
  typesDirectory: initialTypesDirectoryOrder,
  adminTrackingDirectory: initialAdminTrackingDirectoryOrder,
  speciesDirectory: initialSpeciesDirectoryOrder,
  notificationsDirectory: initialNotificationsDirectoryOrder,
  objectsDirectory: initialObjectsDirectoryOrder,
  tagsDirectory: initialTagsDirectoryOrder,
  notificationsRecipientsDirectory: initialNotificationRecipientsDirectoryOrder,
  geographyDirectory: initialGeographyDirectoryOrder,
  reportsDirectory: initialReportsDirectoryOrder,
  reportsProfileDirectory: initialReportsProfileDirectoryOrder,
  reportsAnalyticsDirectory: initialReportsAnalyticsDirectoryOrder,
  reportResultDirectory: initialReportsReportsDirectoryOrder,
  importsDirectory: initialImportsDirectoryOrder,
  importSummaryDirectory: initialImportSummaryOrder,
  importFileDataDirectory: initialImportFileDataDirectoryOrder,
  rolesPermissionsDirectory: initialRolesPermissionsOrder,
  pollsAndSurveysDirectory: initialPollsAndSurveysDirectory,
  reportVersionDataDirectory: initialReportVersionDataDirectory,
  programAdminsDirectory: initialProgramAdminsDirectoryOrder,
};
