import React from "react";
import { OrganizationProfileDetails } from "./OrganizationProfileDetails";
import { SearchToAddExistingOrganizationsToCommunity } from "./SearchToAddExisting/SearchToAddExistingOrganizationsToCommunity";
import { SearchToAddExistingCommunitiesToOrganization } from "./SearchToAddExisting/SearchToAddExistingCommunitiesToOrganization";
import { SearchToAddExistingEntitiesOrOrganizationsToProgram } from "./SearchToAddExisting/SearchToAddExistingCommunitiesOrOrganizationsToProgram";

import { FormDetails } from "./Forms/FormDetailsPanel";
import { AddEditTemplateFormModal } from "./Forms/AddEditTemplateFormModal";
import {
  CreatePasswordModal,
  DeactivateUser,
  DeleteAccountConfirmation,
  ResetPasswordModal,
  ReInviteUserModal,
  RequestDeletionModal,
  EmailVerificationModal,
  PhoneVerificationModal,
  SearchToAddExistingMembersToCommunity,
  RestorePersonModal,
  AddPersonModal,
  RemoveUserFromCommunityModal,
} from "./Users";
import {
  CommunityAccessModal,
  CreateCommunityModal,
  AssignCommunityAdmins,
  ArchiveOrRestoreCommunityModal,
  EditCommunityModal,
  DeleteCommunityModal,
  EditCommunityTagsModal,
  RemoveCommunityFromOrganizationModal,
} from "./Community";
import { ArchiveOrPublishOrganizationModal } from "./ArchiveOrPublishOrganizationModal";
import { FormAccessModal } from "./Forms/FormAccessModal";
import { FormDeleteModal } from "./Forms/DeleteFormModal";
import { AddProgramModal } from "./Programs/AddProgramModal";
import { EditProgramModal } from "./Programs/EditProgram";
import AddFileModal from "./AddFileModal";
import { ArchiveOrRestoreProgramModal } from "./Programs/ArchiveOrRestoreProgramModal";
import { CompleteProgramModal } from "./Programs/CompleteProgramModal";
import { AssignAnAdminForProgram } from "./Programs/AssignAnAdminForProgram";
import { RemoveFromProgramModal } from "./Programs/RemoveFromProgramModal";
import SignOutModal from "./SignOutModal";
import { ManageProgramMembersModal } from "./Programs/ManageProgramMembers";
import { ImageLightBox, VideoLightBox, ImageVideoLightBox } from "./LightBox";
import { DeleteFileModal } from "./Programs/DeleteFileModal";
import { AddEditDuplicateProtocolModal } from "./Protocols/AddEditDuplicateProtocolModal";
import ManageConnectionProtocolModal from "./Protocols/ManageConnectionsModal";
import { ProtocolAccessModal } from "./Protocols/ProtocolAccessModal";
import { CreateOrEditDataAccessPolicyModal } from "./Programs/CreateOrEditDataAccessPolicyModal";
import { ArchiveOrRestoreObservationModal } from "./Observations/ArchiveOrRestoreObservationModal";
import { RiviewerDetailsModal } from "./Manager/RiviewerDetailsModal";
import { AssignManagersToProgram } from "./Programs/AssignManagersToProgram";
import { InviteExternalReviewerQualityAssuranceModal } from "./QualityAssurance/InviteExternalReviewerQualityAssuranceModal";
import { ArchiveOrRestoreQualityAssuranceModal } from "./QualityAssurance/ArchiveOrRestoreQualityAssuranceModal";
import { EditProgramTagsModal } from "./Programs/EditProgramTagsModal";
import {
  ArchiveOrRestoreCategoryModal,
  AddOrEditCategoryModal,
  AddOrEditTypeModal,
  ArchiveOrRestoreTypeModal,
  AddOrEditSpecieModal,
  ArchiveOrRestoreSpecieModal,
  ReuseSpeciesListModal,
  ArchiveOrRestoreObjectModal,
  AddOrEditObjectModal,
  ReuseObjectsListModal,
} from "./SpeciesAndObjects";
import {
  AddOrEditBehaviorsModal,
  ArchiveOrRestoreBehaviorModal,
  AddOrEditConditionsModal,
  ArchiveOrRestoreConditionsModal,
} from "./BehaviorsAndConditions";

import { RemoveMangerModal } from "./Manager/RemoveMangerModal";
import MessageActionsModal from "./MessageDetails/MessageActionsModal";
import { RemoveManagerModal } from "./Manager";
import { AddOrEditTagModal, DeleteTagModal } from "./Tagging";
import { RemoveNotificationRecipientsModal } from "./NotificationRecipients";
import { SearchToAddExistingSpeciesAndObjectsToCommunity } from "./SearchToAddExisting/SearchToAddExistingSpeciesAndObjectsToCommunity";
import { NotificationsSummary } from "./NotificationsSummary";
import { PauseResumeDeleteNotificationModal } from "./Notifications/PauseResumeDeleteNotificationModal";

import { ArchiveOrRestoreGeographyLayerModal } from "./ArchiveOrRestoreGeographyLayerModal";
import { CreateOrganizationModal } from "./Organization/CreateOrganizationModal";
import useRoles from "hooks/roles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ArchiveRestoreDeleteReportsModal } from "./Reports/ArchiveRestoreDeleteReportsModal";
import { ReportsMapModal } from "./ReportsMapModal";
import { ReportsSummary } from "./ReportsSummary";
import { ImportSummaryModal } from "./Imports";
import { ReportsProcessedModal } from "./Reports/ReportsProcessedModal";
import { AddEditDuplicateRoleModal } from "./RolesPermissions/AddEditDuplicateRoleModal";
import { EditPermissionModal } from "./RolesPermissions/EditPermissionModal";
import { DeleteRoleModal } from "./RolesPermissions/DeleteRoleModal";
import { ImportStatusDetailModal } from "./ImportStatusDetail";
import { DownloadOptionsImportModal } from "./ViewImports";
import DashboardAlertConfirmationModal from "./DashboardAlertConfirmationModal";

import {
  AddSurveyForm,
  ArchiveOrRestorePollsAndSurveyModal,
  DeletePollsAndSurveyModal,
  PollsAndSurveysExpiryDateModal,
} from "./Polls&Surveys";
import { RemoveProgramModal } from "./Programs/RemoveProgramModal";
import { SuccessfulMessageModal } from "./QualityAssurance/SuccessfulMessageModal";
import { EditOrganizationTagsModal } from "./Organization/EditOrganizationTagsModal";
import { ImportInfoModal } from "./Observations/ImportInfoModal";
import { DownladPollsAndSurveysResultByVersionModal } from "./Polls&Surveys/DownladPollsAndSurveysResultByVersionModal";
import { InviteDataEndViewersModal } from "./Programs/InviteDataEndViewersModal";
import { DeleteManagerModal } from "./Manager/DeleteManagerModal";
import { SuccessfullInvitationMessageModal } from "./Manager/SuccessfullInvitationMessageModal";
import { InviteRegisteredDataEndReviewer } from "./Manager/InviteRegisteredDataEndReviewer";
import { ContactInfoModal } from "./ContactInfoModal";
import { AddEditContactInfoModal } from "./AddEditContactInfoModal";
import { PauseResumeProgramModal } from "./Programs/PauseResumeProgramModal";
import { ActiveSeasonsHistoryModal } from "./Programs/ActiveSeasonsHistoryModal";
import { NotificationsCenterModal } from "./NotificationsCenterModal";
import MapSettingsModal from "./Programs/MapSettingsModal";
import { ConfirmationAssignAsAdminModal } from "./Users/ConfirmationAssignAsAdminModal";
import { NotifyAboutNewVersionModal } from "./NotifyAboutNewVersionModal";
import { ReportVersionDataModal } from "./Reports/ReportVersionDataModal";
import { UndoAndArchiveImportModal } from "./Imports/UndoAndArchiveModal";
import { AssignProgramAdminPermissionToImportModal } from "./Imports/AssignProgramModal";
import { ExifViewerModal } from "./Observations/ExifViewerModal";
import { ManageLayersPropertiesModal } from "./Forms/ManageLayersPropertiesModal";

const Modals = () => {
  const { isExternalReviewer } = useRoles();

  const modalsState = useSelector((state: RootState) => state.modals);

  const modalConfigs = [
    {
      condition: modalsState.archiveOrPublishOrganizationModal.isOpen,
      component: ArchiveOrPublishOrganizationModal,
    },
    {
      condition: modalsState.removeProgramModal.isOpen,
      component: RemoveProgramModal,
    },
    {
      condition: modalsState.archiveOrRestoreFormModal.isOpen,
      component: FormAccessModal,
    },
    {
      condition: modalsState.deleteFormModal.isOpen,
      component: FormDeleteModal,
    },
    { condition: modalsState.addFileModal.isOpen, component: AddFileModal },
    {
      condition: modalsState.addProgramModal.isOpen,
      component: AddProgramModal,
    },
    {
      condition: modalsState.editProgramModal.isOpen,
      component: EditProgramModal,
    },
    {
      condition: modalsState.archiveOrRestoreProgramModal.isOpen,
      component: ArchiveOrRestoreProgramModal,
    },
    {
      condition: modalsState.completeProgramModal.isOpen,
      component: CompleteProgramModal,
    },
    {
      condition: modalsState.assignAnAdminToProgramModal.isOpen,
      component: AssignAnAdminForProgram,
    },
    {
      condition: modalsState.removeFromProgramModal.isOpen,
      component: RemoveFromProgramModal,
    },
    {
      condition:
        modalsState.addExistingCommunitiesOrOrganizationsToProgramModal.isOpen,
      component: SearchToAddExistingEntitiesOrOrganizationsToProgram,
    },
    {
      condition: modalsState.imageLightBoxModal.isOpen,
      component: ImageLightBox,
    },
    {
      condition: modalsState.videoLightBoxModal.isOpen,
      component: VideoLightBox,
    },

    {
      condition: modalsState.imageVideoLightBoxModal.isOpen,
      component: ImageVideoLightBox,
    },


    {
      condition: modalsState.deleteFileModal.isOpen,
      component: DeleteFileModal,
    },
    {
      condition: modalsState.manageProgramMembersModal.isOpen,
      component: ManageProgramMembersModal,
    },
    {
      condition: modalsState.addEditDuplicateProtocolModal.isOpen,
      component: AddEditDuplicateProtocolModal,
    },
    {
      condition: modalsState.manageConnectioProtocolModal.isOpen,
      component: ManageConnectionProtocolModal,
    },
    {
      condition: modalsState.archiveOrRestoreProtocolModal.isOpen,
      component: ProtocolAccessModal,
    },
    {
      condition: modalsState.createOrEditDataAccessPolicyModal.isOpen,
      component: CreateOrEditDataAccessPolicyModal,
    },
    {
      condition: modalsState.archiveOrRestoreObservationModal.isOpen,
      component: ArchiveOrRestoreObservationModal,
    },
    {
      condition: modalsState.archiveOrRestoreTypeModal.isOpen,
      component: ArchiveOrRestoreTypeModal,
    },
    {
      condition: modalsState.addOrEditTypeModal.isOpen,
      component: AddOrEditTypeModal,
    },
    {
      condition: modalsState.reviewerDetailModal.isOpen,
      component: RiviewerDetailsModal,
    },
    {
      condition: modalsState.addManagerModal.isOpen,
      component: AssignManagersToProgram,
    },
    {
      condition: modalsState.removeManagerModal.isOpen,
      component: RemoveMangerModal,
    },
    {
      condition:
        modalsState.messageActionsModal.isDelete ||
        modalsState.messageActionsModal.isArchive,
      component: MessageActionsModal,
    },
    {
      condition:
        modalsState.inviteExternalReviewerQualityAssuranceObservationModal
          .isOpen,
      component: InviteExternalReviewerQualityAssuranceModal,
    },
    {
      condition:
        modalsState.archiveOrRestoreQualityAssuranceObservationModal.isOpen,
      component: ArchiveOrRestoreQualityAssuranceModal,
    },
    {
      condition: modalsState.archiveOrRestoreCategoryModal.isOpen,
      component: ArchiveOrRestoreCategoryModal,
    },
    {
      condition: modalsState.addOrEditCategoryModal.isOpen,
      component: AddOrEditCategoryModal,
    },
    {
      condition: modalsState.addOrEditSpecieModal.isOpen,
      component: AddOrEditSpecieModal,
    },

    {
      condition: modalsState.archiveOrRestoreConditionsModal.isOpen,
      component: ArchiveOrRestoreConditionsModal,
    },
    {
      condition: modalsState.addOrEditConditionsModal.isOpen,
      component: AddOrEditConditionsModal,
    },

    {
      condition: modalsState.archiveOrRestoreBehaviorsModal.isOpen,
      component: ArchiveOrRestoreBehaviorModal,
    },
    {
      condition: modalsState.addOrEditBehaviorsModal.isOpen,
      component: AddOrEditBehaviorsModal,
    },

    {
      condition: modalsState.archiveOrRestoreSpecieModal.isOpen,
      component: ArchiveOrRestoreSpecieModal,
    },
    {
      condition: modalsState.reUseSpeciesListModal.isOpen,
      component: ReuseSpeciesListModal,
    },
    {
      condition: modalsState.archiveOrRestoreObjectModal.isOpen,
      component: ArchiveOrRestoreObjectModal,
    },
    {
      condition: modalsState.addOrEditObjectModal.isOpen,
      component: AddOrEditObjectModal,
    },
    {
      condition: modalsState.reUseObjectsListModal.isOpen,
      component: ReuseObjectsListModal,
    },
    { condition: modalsState.deleteTagModal.isOpen, component: DeleteTagModal },
    {
      condition: modalsState.addOrEditTagModal.isOpen,
      component: AddOrEditTagModal,
    },
    {
      condition: modalsState.pauseResumeDeleteNotificationModal.isOpen,
      component: PauseResumeDeleteNotificationModal,
    },
    {
      condition: modalsState.removeNotificationRecipientsModal.isOpen,
      component: RemoveNotificationRecipientsModal,
    },
    {
      condition: modalsState.archiveOrRestoreGeographyLayerModal.isOpen,
      component: ArchiveOrRestoreGeographyLayerModal,
    },
    {
      condition: modalsState.addExistingSpeciesOrObjectsToCommunityModal.isOpen,
      component: SearchToAddExistingSpeciesAndObjectsToCommunity,
    },
    {
      condition: modalsState.openNotificationsSummaryModal.isOpen,
      component: NotificationsSummary,
    },
    {
      condition: modalsState.createOrganizationModal.isOpen,
      component: CreateOrganizationModal,
    },
    {
      condition: modalsState.OrganizationProfileDetailModal.isOpen,
      component: OrganizationProfileDetails,
    },
    {
      condition: modalsState.addExistingOrganizationsModal.isOpen,
      component: SearchToAddExistingOrganizationsToCommunity,
    },
    {
      condition: modalsState.addExistingCommunityModal.isOpen,
      component: SearchToAddExistingCommunitiesToOrganization,
    },
    { condition: modalsState.formDetailsModal.isOpen, component: FormDetails },
    {
      condition: modalsState.addEditTemplateFormModal.isOpen,
      component: AddEditTemplateFormModal,
    },
    {
      condition: modalsState.createPasswordModal.isOpen,
      component: CreatePasswordModal,
    },
    {
      condition: modalsState.deactivateUserModal.isOpen,
      component: DeactivateUser,
    },
    {
      condition: modalsState.deleteAccountModal.isOpen,
      component: DeleteAccountConfirmation,
    },
    {
      condition: modalsState.resetPasswordModal.isOpen,
      component: ResetPasswordModal,
    },
    {
      condition: modalsState.reinviteUserModal.isOpen,
      component: ReInviteUserModal,
    },
    {
      condition: modalsState.deleteAccountRequestModal.isOpen,
      component: RequestDeletionModal,
    },
    {
      condition: modalsState.emailVerificationModal.isOpen,
      component: EmailVerificationModal,
    },
    {
      condition: modalsState.phoneVerificationModal.isOpen,
      component: PhoneVerificationModal,
    },
    {
      condition: modalsState.addMemberstoCommunityModal.isOpen,
      component: SearchToAddExistingMembersToCommunity,
    },
    {
      condition: modalsState.restorePersonModal.isOpen,
      component: RestorePersonModal,
    },
    { condition: modalsState.addPersonModal.isOpen, component: AddPersonModal },
    {
      condition: modalsState.removePersonFromCommunityModal.isOpen,
      component: RemoveUserFromCommunityModal,
    },
    {
      condition: modalsState.communityAccessModal.isOpen,
      component: CommunityAccessModal,
    },
    {
      condition: modalsState.createCommunityModal.isOpen,
      component: CreateCommunityModal,
    },
    {
      condition: modalsState.assignAnAdminToCommunityModal.isOpen,
      component: AssignCommunityAdmins,
    },
    {
      condition: modalsState.archiveOrRestoreCommunityModal.isOpen,
      component: ArchiveOrRestoreCommunityModal,
    },
    {
      condition: modalsState.editCommunityModal.isOpen,
      component: EditCommunityModal,
    },
    {
      condition: modalsState.deleteACommunityModal.isOpen,
      component: DeleteCommunityModal,
    },
    {
      condition: modalsState.archiveRestoreDeleteReportModal?.isOpen,
      component: ArchiveRestoreDeleteReportsModal,
    },
    {
      condition: modalsState.openReportsMapModal.isOpen,
      component: ReportsMapModal,
    },
    {
      condition: modalsState.openReportsSummaryModal.isOpen,
      component: ReportsSummary,
    },
    {
      condition: modalsState.importSummaryModal.isOpen,
      component: ImportSummaryModal,
    },
    {
      condition: modalsState.editCommunityTagsModal.isOpen,
      component: EditCommunityTagsModal,
    },
    {
      condition: modalsState.editProgramTagsModal.isOpen,
      component: EditProgramTagsModal,
    },
    {
      condition: modalsState.processedReportModal?.isOpen,
      component: ReportsProcessedModal,
    },

    {
      condition: modalsState.addEditDuplicateRoleModal?.isOpen,
      component: AddEditDuplicateRoleModal,
    },

    {
      condition: modalsState.editPermissionModal?.isOpen,
      component: EditPermissionModal,
    },

    {
      condition: modalsState.deleteRoleModal?.isOpen,
      component: DeleteRoleModal,
    },

    {
      condition: modalsState.importStatusDetailModal?.isOpen,
      component: ImportStatusDetailModal,
    },

    {
      condition: modalsState.downloadOptionsImportModal?.isOpen,
      component: DownloadOptionsImportModal,
    },

    {
      condition: modalsState.dashboardAlertConfirmationModal?.isOpen,
      component: DashboardAlertConfirmationModal,
    },

    {
      condition: modalsState.addOrEditSurveyFormModal?.isOpen,
      component: AddSurveyForm,
    },
    {
      condition: modalsState.deletePollsAndSurveyModal?.isOpen,
      component: DeletePollsAndSurveyModal,
    },
    {
      condition: modalsState.archiveOrRestorePollsAndSurveyModal?.isOpen,
      component: ArchiveOrRestorePollsAndSurveyModal,
    },
    {
      condition: modalsState.archiveOrRestorePollsAndSurveyModal?.isOpen,
      component: ArchiveOrRestorePollsAndSurveyModal,
    },

    {
      condition: modalsState.successMessageModal?.isOpen,
      component: SuccessfulMessageModal,
    },
    {
      condition: modalsState.editOrganizationTagsModal?.isOpen,
      component: EditOrganizationTagsModal,
    },
    {
      condition: modalsState.importInfoModal.isOpen,
      component: ImportInfoModal,
    },
    {
      condition: modalsState.downladPollsAndSurveysResultByVersionModal.isOpen,
      component: DownladPollsAndSurveysResultByVersionModal,
    },
    {
      condition: modalsState.inviteDataEndViewerModal.isOpen,
      component: InviteDataEndViewersModal,
    },
    {
      condition: modalsState.deleteManagerModal.isOpen,
      component: DeleteManagerModal,
    },
    {
      condition: modalsState.successfullInviteMessageModal.isOpen,
      component: SuccessfullInvitationMessageModal,
    },
    {
      condition: modalsState.inviteRegisteredExternalReviewerModal.isOpen,
      component: InviteRegisteredDataEndReviewer,
    },
    {
      condition: modalsState.contactInfoModal.isOpen,
      component: ContactInfoModal,
    },
    {
      condition: modalsState.addEditContactInfoModal.isOpen,
      component: AddEditContactInfoModal,
    },
    {
      condition: modalsState.pauseResumeProgramModal.isOpen,
      component: PauseResumeProgramModal,
    },
    {
      condition: modalsState.mapSettingsProgramProfileModal.isOpen,
      component: MapSettingsModal,
    },
    {
      condition: modalsState.activeSeasonsHistoryModal.isOpen,
      component: ActiveSeasonsHistoryModal,
    },
    {
      condition: modalsState.reportVersionDataModal.isOpen,
      component: ReportVersionDataModal,
    },
    {
      condition: modalsState.notificationsCenterModal.isOpen,
      component: NotificationsCenterModal,
    },
    {
      condition: modalsState.removeCommunityFromOrganizationModal.isOpen,
      component: RemoveCommunityFromOrganizationModal,
    },

    {
      condition: modalsState.confirmationAssignAsAdminModal.isOpen,
      component: ConfirmationAssignAsAdminModal,
    },

    {
      condition: modalsState.notifyAboutNewVersionModal.isOpen,
      component: NotifyAboutNewVersionModal,
    },
    {
      condition: modalsState.undoAndArchiveImportModal.isOpen,
      component: UndoAndArchiveImportModal,
    },

    {
      condition: modalsState.exifViewerModal.isOpen,
      component: ExifViewerModal,
    },
    {
      condition: modalsState.pollsAndSurveysExpiryDateModal.isOpen,
      component: PollsAndSurveysExpiryDateModal,
    },
    
    {
      condition: modalsState.assignProgramAdminPermissionToImportModal.isOpen,
      component: AssignProgramAdminPermissionToImportModal
    },
    // {
    //   condition: modalsState.manageLayersPropertiesModal.isOpen,
    //   component: ManageLayersPropertiesModal
    // }
  ];

  return (
    <>
      {!isExternalReviewer() && (
        <>
          {modalConfigs.map((modalConfig: any, index: any) =>
            modalConfig.condition
              ? React.createElement(modalConfig.component, { key: index })
              : null
          )}
        </>
      )}
      {modalsState.signOutModal.isOpen && <SignOutModal />}
    </>
  );
};

export default Modals;
