import React, { useEffect, useRef, useState } from "react";
import {
  useInitializeMap,
  useCustomizeColorOfHigilightedLayer,
  useHandleClickOnLayer,
  useHandleMovementOfMapCenterPoint,
  usePaintAllLayersOnMap,
  useInitializeCustomLayersListControl,
  usePaintAllGlobalLayersOnMap,
} from "../../hooks";

import mapboxgl from "mapbox-gl";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR = "#FFA500";

const Map = ({ element, answer, eleName, editMode, setFieldValue }: any) => {
  const mapContainer = useRef<any>(null);
  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const [newPopup, setNewPopup] = useState<any>();
  const [popups, setPopups] = useState<any>([]);

  const customControlId = "pointpicker" + element.id;

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
    customControlId,
    "",
    "",
    editMode
  );

  // initializing custom shapes dropdown to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    element.selectedLayers,
    customControlId
  );

  // painting the all global layers on the map
  usePaintAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    LAYER_DEFAULT_COLOR,
    null,
    popups,
    setPopups,
    setNewPopup,
    editMode
  );

  // painting the layers on the map
  const [clickedLayer, setClickedLayer] = usePaintAllLayersOnMap(
    element,
    answer,
    map,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    popups,
    setPopups,
    setNewPopup,
    editMode
  );

  // handle click on layer
  useHandleClickOnLayer(
    clickedLayer,
    setClickedLayer,
    element,
    eleName,
    setFieldValue,
    editMode,
    answer
  );

  // change the color of highlighted layer
  useCustomizeColorOfHigilightedLayer(
    map,
    isMapFullyLoaded,
    element,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    answer
  );

  // display popup of points whenever click on it
  useEffect(() => {
    if (!newPopup || editMode) return;

    const { coordinates, html, id } = newPopup;

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);

    setPopups((prev: any) => [...popups, { id, item: popup }]);
  }, [newPopup]);

  useEffect(() => {
    if (popups.length > 0) {
      popups.forEach((p: any) => {
        p.item.remove();
      });
      setPopups([]);
    }
  }, [editMode]);

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
