import { camelCase, snakeCase } from "change-case";
import { DATE_FORMATS } from "constants/dateFormats";
import _ from "lodash";
import moment from "moment";
import { formatTheDates } from "./formatTheDates";
import store from "store";
const convertToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const convertFiltersToQueryString = (filters: any) => {
  const queryParams: any[] = [];
  for (const key in filters) {
    const value = filters[key];

    if (value !== "" && value !== null && value !== undefined) {
      const snakeCaseKey = convertToSnakeCase(key);
      let formattedValue = "";

      if (Array.isArray(value) && value.length > 0) {
        formattedValue = value
          .map((item: any) => encodeURIComponent(String(item).toLowerCase()))
          .join(",");
      } else if (!Array.isArray(value)) {
        formattedValue = encodeURIComponent(String(value).toLowerCase());
      }

      if (formattedValue) {
        queryParams.push(`${snakeCaseKey}=${formattedValue}`);
      }
    }
  }

  if (queryParams.length > 0) {
    return `?${queryParams.join("&")}`;
  }

  return "";
};

// export const generateQueryStringForUsers = (filters: any) => {
//   const queryString = Object.keys(filters)
//     .filter((filter) => {
//       const filterValue = filters[filter];
//       if (typeof filterValue === "object") {
//         if (!filterValue.applied) {
//           return false;
//         }
//         if (filterValue.from === "" && filterValue.to === "") {
//           return false;
//         }
//         if (
//           Array.isArray(filterValue.value) &&
//           filterValue.value.length === 0
//         ) {
//           return false;
//         }
//       } else if (typeof filterValue === "string" && filterValue.trim() === "") {
//         return false;
//       } else if (Array.isArray(filterValue) && filterValue.length === 0) {
//         return false;
//       }
//       return true;
//     })
//     .map((filter) => {
//       const snakeCaseFilter = filter.replace(
//         /([A-Z])/g,
//         ($1) => `_${$1.toLowerCase()}`
//       );
//       if (
//         filter === "lastActive" ||
//         filter === "memberSince" ||
//         filter === "dateAdded" ||
//         filter === "dateUpdated" ||
//         filter === "dateArchived" ||
//         filter === "date" ||
//         filter === "created" ||
//         filter === "updated" ||
//         filter === "deleted" ||
//         filter === "archived"
//       ) {
//         const { from, to } = filters[filter];
//         return `${snakeCaseFilter}_from=${from}&${snakeCaseFilter}_to=${to}`;
//       } else if (
//         filter === "programs" ||
//         filter === "forms" ||
//         filter === "observations" ||
//         filter === "members" ||
//         filter === "numberOfObservations" ||
//         filter === "numberOfMembers" ||
//         filter === "connectedForms"
//       ) {
//         const { min, max } = filters[filter];
//         return `max_${snakeCaseFilter}=${max}&min_${snakeCaseFilter}=${min}`;
//       } else if (
//         filter === "communityIds" ||
//         filter === "organizationIds" ||
//         filter === "tagIds" ||
//         filter === "createdByIds" ||
//         filter === "archivedByIds" ||
//         filter === "programIds" ||
//         filter === "observerIds" ||
//         filter === "formIds" ||
//         filter === "updatedByIds" ||
//         filter === "deletedByIds" ||
//         filter === "reviewerIds" ||
//         filter === "categoryIds"
//       ) {
//         const ids = filters[filter].value.map((item: any) => item.id);
//         return `${snakeCaseFilter}=${ids.join(",")}`;
//       } else if (filter === "searchString" || filter === "searchBy") {
//         return `${snakeCaseFilter}=${filters[filter]}`;
//       } else if (filter === "type") {
//         const type = snakeCase(filters[filter].value)
//         return `${snakeCaseFilter}=${type}`;
//       } else if (filter === "status") {
//         const status = snakeCase(filters[filter]);
//         return `${snakeCaseFilter}=${status}`;
//       } else if (filter !== "viewType" && filter !== "path") {
//         return `${snakeCaseFilter}=${filters[filter]}`;
//       }
//     })
//     .join("&");
//   return queryString.length > 0 ? `?${queryString}` : "";
// };

export const generateQueryStringForUsers = (
  filters: any
): Record<string, any> => {
  // const userDefaultTimeZone =
  //   store.getState().userProfileSettings.profileSettings.timezone ??
  //   Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userDefaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const queryParams: Record<string, any> = {};
  for (const filter in filters) {
    if (!Object.prototype.hasOwnProperty.call(filters, filter)) continue;

    const filterValue = filters[filter];

    if (
      typeof filterValue === "object" &&
      (!filterValue.applied ||
        (filterValue.from === "" && filterValue.to === ""))
    )
      continue;
    if (
      (typeof filterValue === "string" && filterValue.trim() === "") ||
      (Array.isArray(filterValue) && filterValue.length === 0)
    )
      continue;
    if (
      [
        "lastActive",
        "memberSince",
        "dateAdded",
        "dateUpdated",
        "dateArchived",
        "date",
        "created",
        "updated",
        "deleted",
        "archived",
        "addedDate",
        "updatedDate",
        "lastRun",
        "nextRun",
        "responseDate",
      ].includes(filter)
    ) {
      queryParams[camelCase(`${filter}From`)] = formatTheDates(
        filterValue.from,
        { utc: false, timezone: userDefaultTimeZone }
      );

      queryParams[camelCase(`${filter}To`)] = formatTheDates(filterValue.to, {
        utc: false,
        timezone: userDefaultTimeZone,
      });
    } else if (
      [
        "programs",
        "forms",
        "observations",
        "members",
        "numberOfObservations",
        "numberOfMembers",
        "responses",
        "connectedForms",
      ].includes(filter)
    ) {
      queryParams[`min${_.upperFirst(filter)}`] = filterValue.min;
      queryParams[`max${_.upperFirst(filter)}`] = filterValue.max;
    } else if (
      [
        "communityIds",
        "organizationIds",
        "tagIds",
        "createdByIds",
        "archivedByIds",
        "programIds",
        "observerIds",
        "formIds",
        "updatedByIds",
        "deletedByIds",
        "reviewerIds",
        "categoryIds",
        "typeIds",
        "specieIds",
        "memberIds",
        "reportsId",
        "versionIds",
        "protocolIds",
        "versionIds"
      ].includes(filter)
    ) {
      queryParams[filter] = filterValue.value
        .map((item: any) => item.id)
        .join(",");
    } else if (filter === "filterStatus") {
      queryParams[filter] = filterValue.value
        .map((item: any) => item)
        .join(",");
    } else if (filter === "type" && typeof filterValue === "object") {
      queryParams[filter] = filterValue.value;
    } else if (filter === "status" && typeof filterValue === "object") {
      queryParams[filter] = filterValue.value;
    } else if (filter === "dateRecieved") {
      queryParams[filter] = filterValue.date;
    } else if (filter === "scheduledFor") {
      queryParams[filter] = filterValue.value;
    } else if (filter !== "viewType" && filter !== "path") {
      queryParams[filter] = filterValue;
    }
  }

  return queryParams;
};
