import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DirectModeOverride,
  DragCircleMode,
  DrawRectangleMode,
  SimpleSelectMode,
  SnapLineMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from "Components/Geography/MapBox/lib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetMap,
  updateDrawShapeCoordinates,
  updateSelectedItemToZoom,
} from "store/geography";

import mapboxgl from "mapbox-gl";
import CustomControlForLayersListing from "Components/Geography/MapBox/features/CustomControlForLayersListing";
import customControlForSnapping from "Components/Geography/MapBox/features/CustomControlForSnapping";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useInitializeMap = (
  mapContainer: any,
  lng: number | null,
  lat: number | null,
  zoom: any,
  setZoom: any,
  communityCenterpoint: any
) => {
  const map = useRef<any>(null);
  const [drawRef, setDrawRef] = useState<any>(null);
  const [isMapFUllyLoaded, setIsMapFullyLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (map.current) return; // initialize map only once

    (async () => {
      if (!mapContainer.current) return;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_MAPBOX_STYLE_URL ?? "",
        center: [lng ?? -156.44636531857395, lat ?? 62.40265300551266],
        zoom: 2,
        pitch: 0,
        bearing: 0,
        renderWorldCopies: false,
        minZoom: 0.999,
        // @ts-ignore
        projection: {
          name: "mercator",
        },
      });

      mapContainer.current.style.width = "100%";
      // initializing all controls of drawing shapes
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes,
          draw_point: SnapPointMode,
          draw_polygon: SnapPolygonMode,
          draw_circle: CircleMode,
          direct_select: DirectModeOverride,
          drag_circle: DragCircleMode,
          simple_select: SimpleSelectMode,
          draw_line_string: SnapLineMode,
          draw_rectangle: DrawRectangleMode,
        },
        // Styling guides
         // styles: SnapModeDrawStyles,
        styles: [
          // ACTIVE (being drawn)
          // line stroke

          {
            id: "gl-draw-line",
            type: "line",
            filter: ["all", ["==", "$type", "LineString"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // polygon fill
          {
            id: "gl-draw-polygon-fill",
            type: "fill",
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
              "fill-color": "#D20C0C",
              "fill-outline-color": "#D20C0C",
              "fill-opacity": 0.2,
            },
          },
          // polygon mid points
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#fbb03b",
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: ["all", ["==", "$type", "Polygon"]],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 1,
            },
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 5,
              "circle-color": "#FFF",
            },
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 3,
              "circle-color": "#D20C0C",
            },
          },
          {
            id: "gl-draw-line-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF", // Blue color for inactive
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Polygon fill when inactive
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            paint: {
              "fill-color": "#007FFF", // Blue for inactive polygons
              "fill-outline-color": "#007FFF",
              "fill-opacity": 0.2, // Adjust opacity as needed
            },
          },
          // Polygon outline stroke when inactive
          {
            id: "gl-draw-polygon-stroke-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#007FFF",
              // "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // Vertex points when inactive
          {
            id: "gl-draw-polygon-and-line-vertex-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "active", "true"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#007FFF",
            },
          },
        ],
        userProperties: true,
        // Config snapping features
        // @ts-ignore
        snap: false,
        snapOptions: {
          snapPx: 15, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
        },
        guides: false,
      });

      setDrawRef(draw);

      map.current.addControl(draw);

      // adding zoom and navigation controls
      const nav = new mapboxgl.NavigationControl();
      map.current.addControl(nav, "bottom-right");

      // adding full screen control
      map.current.addControl(new mapboxgl.FullscreenControl());

      // adding custom control for layers listing
      const customLayerListingControl = new CustomControlForLayersListing();
      map.current.addControl(customLayerListingControl, "top-right");

      // adding custom control for snapping
      const customSnappingControl = new customControlForSnapping();
      map.current.addControl(customSnappingControl);

      map.current.on("load", () => setIsMapFullyLoaded(true));
      map.current.on("draw.create", updateGeoJsonData);
      map.current.on("draw.delete", updateGeoJsonData);
      map.current.on("draw.update", updateGeoJsonData);

      map.current.on("move", () => {
        // setLng(map.current.getCenter().lng );
        // setLat(map.current.getCenter().lat );
        setZoom(map.current.getZoom().toFixed(2));
      });

      // setting up and updating geojson
      function updateGeoJsonData() {
        const data = draw.getAll();

        if (data.features.length > 0) {
          // removing unnecessary shape items added by map
          const features = data.features.filter((f: any) => {
            if (
              f.id !== "VERTICAL_GUIDE" &&
              f.id !== "HORIZONTAL_GUIDE" &&
              !f.id.includes("feature")
            ) {
              return true;
            }
            return false;
          });

          if (features[0]?.geometry?.type === "Point") {
            dispatch(
              updateDrawShapeCoordinates(features[0].geometry.coordinates)
            );
          } else {
            dispatch(updateDrawShapeCoordinates(features));
          }
        }
      }
    })();

    return () => {
      //reset selected layers item to zoom on map
      dispatch(updateSelectedItemToZoom(null));
      // reset selected layers item to display on map
      dispatch(resetMap(true));
    };
  }, []);

  useEffect(() => {
    if (map.current && communityCenterpoint) {
      map.current.flyTo({
        center: communityCenterpoint
          ? communityCenterpoint
          : [-156.44636531857395, 62.40265300551266],
      });
    }
  }, [communityCenterpoint]);

  return { map, drawRef, isMapFUllyLoaded };
};

export { useInitializeMap };
