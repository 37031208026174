import React from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import CheckIcon from "assets/icons/HeroIcons/CheckIcon";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import InputMark from "assets/icons/HeroIcons/InputMark";
import { getBrandPlaceholder } from "utils/customBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "member"
  ) {
    return (
      <div className="flex justify-start w-[250px] items-center relative">
        <img
          src={cellValue?.image?.file?.thumb || getBrandPlaceholder("listingProfile")}
          alt=""
          height="100"
          width="100"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className="flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "reportVersion") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-center text-textMid`}>
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (
    (cellValue !== null && columnDef.id === "viewed") ||
    columnDef.id === "download"
  ) {
    return (
      <div className="relative flex items-start flex-grow py-1 px-3">
        {cellValue === true ? (
          <CheckIcon fill="#009F88" />
        ) : cellValue === false ? (
          <XCloseIcon fill="#BF200B" />
        ) : (
          <InputMark fill="#AFB1B3" />
        )}
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "date") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
