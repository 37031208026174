export const CHANGE_USERS_DIRECTORY_COLUMNS_ORDER = "CHANGE_USERS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_USERS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_USERS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_USERS_DIRECTORY_COLUMNS = "RESET_USERS_DIRECTORY_COLUMNS";


export const CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_ORDER = "CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_ORGANIZATIONS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_ORGANIZATIONS_DIRECTORY_COLUMNS = "RESET_ORGANIZATIONS_DIRECTORY_COLUMNS";

export const CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_ORDER = "CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_COMMUNITIES_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_COMMUNITIES_DIRECTORY_COLUMNS = "RESET_COMMUNITIES_DIRECTORY_COLUMNS";

export const CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_ORDER = "CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_DYNAMIC_FORMS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_DYNAMIC_FORMS_DIRECTORY_COLUMNS = "RESET_DYNAMIC_FORMS_DIRECTORY_COLUMNS";
export const UPDATE_DYNAMIC_FORMS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_DYNAMIC_FORMS_COLUMNS_VISIBILITY_BY_STATUS";



export const CHANGE_PROGRAMS_DIRECTORY_COLUMNS_ORDER = "CHANGE_PROGRAMS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY";
export const UPDATE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_PROGRAMS_DIRECTORY_COLUMNS_VISIBILITY_BY_STATUS";
export const RESET_PROGRAMS_DIRECTORY_COLUMNS = "RESET_PROGRAMS_DIRECTORY_COLUMNS";



export const CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_ORDER = "CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_PROTOCOLS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_PROTOCOLS_DIRECTORY_COLUMNS = "RESET_PROTOCOLS_DIRECTORY_COLUMNS";
export const UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_STATUS";
export const UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_ID = "UPDATE_PROTOCOLS_COLUMNS_VISIBILITY_BY_ID";

export const CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_ORDER = "CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS = "RESET_NOTIFICATIONS_RECIPIENTS_DIRECTORY_COLUMNS";
export const UPDATE_NOTIFICATIONS_RECIPIENTS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_NOTIFICATIONS_RECIPIENTS_COLUMNS_VISIBILITY_BY_STATUS";

export const CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER = "CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_OBSERVATIONS_DIRECTORY_COLUMNS = "RESET_OBSERVATIONS_DIRECTORY_COLUMNS";
export const UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS";
export const UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY = "UPDATE_OBSERVATIONS_COLUMNS_VISIBILITY";

export const CHANGE_MANAGERS_DIRECTORY_COLUMNS_ORDER = "CHANGE_MANAGERS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_MANAGERS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_MANAGERS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_MANAGERS_DIRECTORY_COLUMNS = "RESET_MANAGERS_DIRECTORY_COLUMNS";
export const UPDATE_MANAGERS_COLUMNS_VISIBILITY_BY_TYPE = "UPDATE_MANAGERS_COLUMNS_VISIBILITY_BY_TYPE";
export const CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER = "CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS = "RESET_QUALITY_ASSURANCE_OBSERVATIONS_DIRECTORY_COLUMNS";
export const UPDATE_QUALITY_ASSURANCE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_QUALITY_ASSURANCE_OBSERVATIONS_COLUMNS_VISIBILITY_BY_STATUS";




export const CHANGE_CATEGORIES_DIRECTORY_COLUMNS_ORDER = "CHANGE_CATEGORIES_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_CATEGORIES_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_CATEGORIES_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_CATEGORIES_DIRECTORY_COLUMNS = "RESET_CATEGORIES_DIRECTORY_COLUMNS";

export const CHANGE_BEHAVIORS_DIRECTORY_COLUMNS_ORDER = "CHANGE_BEHAVIORS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_BEHAVIORS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_BEHAVIORS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_BEHAVIORS_DIRECTORY_COLUMNS = "RESET_BEHAVIORS_DIRECTORY_COLUMNS";



export const CHANGE_TYPES_DIRECTORY_COLUMNS_ORDER = "CHANGE_TYPES_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_TYPES_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_TYPES_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_TYPES_DIRECTORY_COLUMNS = "RESET_TYPES_DIRECTORY_COLUMNS";


export const CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_ORDER = "CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_NOTIFICATIONS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_NOTIFICATIONS_DIRECTORY_COLUMNS = "RESET_NOTIFICATIONS_DIRECTORY_COLUMNS";

export const CHANGE_REPORTS_COLUMNS_ORDER = "CHANGE_REPORTS_COLUMNS_ORDER";
export const CHANGE_REPORTS_COLUMNS_VISIBILITY = "CHANGE_REPORTS_COLUMNS_VISIBILITY";
export const RESET_REPORTS_COLUMNS = "RESET_REPORTS_COLUMNS";

export const CHANGE_REPORTS_PROFILE_COLUMNS_ORDER = "CHANGE_REPORTS_PROFILE_COLUMNS_ORDER";
export const CHANGE_REPORTS_PROFILE_COLUMNS_VISIBILITY = "CHANGE_REPORTS_PROFILE_COLUMNS_VISIBILITY";
export const RESET_REPORTS_PROFILE_COLUMNS = "RESET_REPORTS_PROFILE_COLUMNS";

export const CHANGE_REPORTS_RESULT_COLUMNS_ORDER = "CHANGE_REPORTS_RESULT_COLUMNS_ORDER";
export const CHANGE_REPORTS_RESULT_COLUMNS_VISIBILITY = "CHANGE_REPORTS_RESULT_COLUMNS_VISIBILITY";
export const RESET_REPORTS_RESULT_COLUMNS = "RESET_REPORTS_RESULT_COLUMNS";

export const CHANGE_REPORTS_ANALYTTICS_COLUMNS_ORDER = "CHANGE_REPORTS_ANALYTTICS_COLUMNS_ORDER";
export const CHANGE_REPORTS_ANALYTTICS_COLUMNS_VISIBILITY = "CHANGE_REPORTS_ANALYTTICS_COLUMNS_VISIBILITY";


export const CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_ORDER = "CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_GEOGRAPHY_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_GEOGRAPHY_DIRECTORY_COLUMNS = "RESET_GEOGRAPHY_DIRECTORY_COLUMNS";

export const CHANGE_IMPORTS_COLUMNS_ORDER = "CHANGE_IMPORTS_COLUMNS_ORDER";
export const CHANGE_IMPORTS_COLUMNS_VISIBILITY = "CHANGE_IMPORTS_COLUMNS_VISIBILITY";
export const RESET_IMPORT_COLUMNS = "RESET_IMPORT_COLUMNS";


export const CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_ORDER = "CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_ORDER";
export const CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_VISIBILITY = "CHANGE_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS_VISIBILITY";
export const RESET_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS = "RESET_POLLS_AND_SURVEYS_DIRECTORY_COLUMNS";
export const UPDATE_POLLS_AND_SURVEYS_COLUMNS_VISIBILITY_BY_STATUS = "UPDATE_POLLS_AND_SURVEYS_COLUMNS_VISIBILITY_BY_STATUS";