import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import calculateMultiEntityZoomLevel from "utils/calculateMultiEntityZoomLevel";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const usePaintAllPointsOnMap = (
  currentQuestionEle: any,
  answer: any,
  map: any,
  isMapFullyLoaded: any,
  LAYER_SELECTED_COLOR: any,
  LAYER_DEFAULT_COLOR: any,
  markers: any,
  setMarkers: any
) => {
  const [clickedPoint, setClickedPoint] = useState<any>(null);

  useEffect(() => {
    if (
      map &&
      isMapFullyLoaded &&
      currentQuestionEle &&
      currentQuestionEle.selectedPoints &&
      currentQuestionEle.selectedPoints.length > 0
    ) {

      // creating markers 
      const newMarkers: any = [];

      currentQuestionEle.selectedPoints.forEach((markerData: any) => {
        const coordinates =
          markerData.mapData?.features[0]?.geometry?.coordinates;

        const isMarkerHighlighted = answer?.find((answerLayer: any) => {
          return answerLayer.id == markerData.id;
        });

        const markerColor = isMarkerHighlighted
          ? LAYER_SELECTED_COLOR
          : LAYER_DEFAULT_COLOR;

        if (coordinates && isMarkerHighlighted) {
          const [longitude, latitude] = coordinates;

          const marker = new mapboxgl.Marker({ color: markerColor })
            .setLngLat([longitude, latitude])
            .addTo(map);
                        
          // Create a popup for this marker
          const popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(`<p class="break-all">${markerData.name}</p>`);


          // Attach the popup to the marker
          marker.setPopup(popup);

          marker.getElement().addEventListener("click", (e) => {
            setClickedPoint(markerData);
          });

          newMarkers.push({ marker, id: markerData.id });
        }
      });

      setMarkers([...newMarkers]);

      // fiting the map zoom level according to the created markers on the map
      const calculation = calculateMultiEntityZoomLevel(
        answer,
        map
      );

      if (calculation?.bounds && calculation?.maxZoom) {
        map.fitBounds({
          ...calculation.bounds,
          maxZoom: calculation.maxZoom,
        });
      }

    }
  }, [map, currentQuestionEle?.selectedPoints, isMapFullyLoaded]);

  return [clickedPoint, setClickedPoint, markers, setMarkers];
};

export { usePaintAllPointsOnMap };
