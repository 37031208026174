import { Dispatch, useLayoutEffect, useState } from "react";
// components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// API services
import apiLibrary from "services/api";
// Store utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen } from "store/geography";
// Third party services
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import Head from "view/components/Head";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";

// Schema
const editVectorLayerSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "255 max characters")
    .required("Layer name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(255, "255 max characters"),
  color: Yup.string().required("Fill Color is required"),
  borderColor: Yup.string().required("Border Color is required"),
});

const EditVectorLayer = () => {
  const dispatch = useDispatch();
  const { editVectorLayerData } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();

  const [isShapeLine, setIsShapeLine] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { dataItem: data, isGlobal } = editVectorLayerData ?? {};

  useLayoutEffect(() => {
    if (communityId && data?.id) {
      const fetchShapeType = async () => {
        setLoading(true); // Start loading
        try {
          const res = await apiLibrary.geography.getSingleLayer(communityId, data?.id);
          const shapeType = res?.data?.mapData?.features[0]?.geometry?.type;
          setIsShapeLine(shapeType === "LineString");
        } catch (error: any) {
          Toasts.error(error.message);
        } finally {
          setLoading(false); // End loading once the API call is complete
        }
      };
      fetchShapeType();
    }
  }, [communityId, data]);

  const initialValues = {
    name: data?.name,
    description: data?.description,
    color: data?.color ?? "#FF0000",
    borderColor: data?.borderColor ?? "#FF0000",
  };

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    try {
      if (!willColorBeVisibleOnMap(values.color)) {
        setFieldError("color", "Please select a fill color that is easily visible");
        return;
      }
      if (!willColorBeVisibleOnMap(values.borderColor)) {
        setFieldError("borderColor", "Please select a border color that is easily visible");
        return;
      }

      if (communityId && !isGlobal) {
        await apiLibrary.geography.editLayer(communityId, data.id, values);
      } else {
        await apiLibrary.geography.editLayerForAdmin(data.id, values);
      }
      Toasts.success(`Vector layer updated successfully`);

      // navigate to all layers screen
      dispatch(openAllLayersListingScreen());
    } catch (err: any) {
      Toasts.error(err.message);
    } finally {
      setSubmitting(true);
    }
  };

  return (
    <div>
      <Head title="Geography: Edit Vector Layer" />
      <Breadcrumb
        label="Edit Vector Layer"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={editVectorLayerSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label="Layer Name*"
                  type="text"
                  placeholder="Layer name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors?.name}
                  touched={touched?.name}
                />
              </div>
              <div className="w-full mb-4">
                <TextInput
                  label="Description*"
                  type="text"
                  rows={3}
                  fieldAs="textarea"
                  placeholder="Description"
                  name="description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  error={errors?.description}
                  touched={touched?.description}
                />
                {!errors?.description && (
                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                    255 max characters
                  </p>
                )}
              </div>
              {data?.shapeType !== "point" && (
                <>
                  <div className="w-full mb-4">
                    <div>
                      <p
                        className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${touched && errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : ""
                          }`}
                      >
                        Select Fill Color
                      </p>
                      <ColorPicker
                        handlePicker={(color: string) =>
                          setFieldValue("color", color)
                        }
                        color={values.color}
                      />
                    </div>
                    {errors?.color && (
                      <p
                        className={`flex-grow text-xs text-left   ${errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : "text-textMidLight dark:text-textMain"
                          } `}
                      >
                        {/* @ts-ignore */}
                        {errors.color}
                      </p>
                    )}
                  </div>
                  {loading ? null : !isShapeLine && <div className="w-full mb-4">
                    <div>
                      <p
                        className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${touched && errors?.borderColor
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : ""
                          }`}
                      >
                        Select Border Color
                      </p>
                      <ColorPicker
                        handlePicker={(color: string) =>
                          setFieldValue("borderColor", color)
                        }
                        color={values.borderColor}
                      />
                    </div>
                    {errors?.borderColor && (
                      <p
                        className={`flex-grow text-xs text-left   ${errors?.borderColor
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : "text-textMidLight dark:text-textMain"
                          } `}
                      >
                        {/* @ts-ignore */}
                        {errors.borderColor}
                      </p>
                    )}
                  </div>}
                </>
              )}

              <div className="flex justify-between gap-2">
                <Button
                  type="reset"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.t%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={goBackToAllLayersScreen}
                />
                <Button
                  type="submit"
                  text="Save"
                  disabled={isSubmitting}
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 w-[48.5%]"
                  width="[48.5%]"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { EditVectorLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain ">
        {label}
      </span>
    </button>
  );
};
