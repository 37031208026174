import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { capitalCase, sentenceCase } from "change-case";
import { MaterialUiSelect } from "view/components/Select";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface UniqueProperties {
  [key: string]: boolean;
}

interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;  
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
interface DataType {
  [key: string]: any; // Adjust 'any' to be more specific as needed
}
export const ManageLayersPropertiesModal = ({
  isMoDalOpen,
  setIsModalOpen,
  option,
  onAddSelection,
}: {
  isMoDalOpen: boolean;
  setIsModalOpen: any;
  option: OptionType;
  onAddSelection: (option: OptionType) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueProperties, setUniqueProperties] = useState<UniqueProperties>(
    option?.uniqueProps
  );

  useEffect(() => {
    if (option.uniqueProps) {
      setUniqueProperties(option.uniqueProps);
    }
  }, [option.uniqueProps]);

  const handleSelectChange = (property: string) => {
    setUniqueProperties((prev: UniqueProperties) => ({
      ...prev,
      [property]: true,
    }));
  };

  const handleRemoveProperty = (property: string) => {
    setUniqueProperties((prev: UniqueProperties) => ({
      ...prev,
      [property]: false,
    }));
  };

  const falsePropertyObjects = () => {
    return Object.entries(uniqueProperties)
      .filter(([, isTrue]) => !isTrue)
      .map(([key]) => ({ value: key, label: capitalCase(key ?? "") }));
  };

  const truePropertyObjects = () => {
    return Object.entries(uniqueProperties)
      .filter(([, isTrue]) => isTrue)
      .map(([key]) => ({ value: key, label: capitalCase(key ?? "") }));
  };
  const filteredData = truePropertyObjects();

  const falsePropertiesData = falsePropertyObjects();
  const updateProperties = () => {
    const updatedOption: OptionType = { ...option };
    updatedOption.uniqueProps = uniqueProperties;
    onAddSelection(updatedOption);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 100);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isMoDalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[680px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between pb-2 items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {option.name ?? "Layer"}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex flex-col items-start w-full py-1">
              <p className="mb-2 dark:text-textMain">Select Attribute</p>
              <div className="flex justify-start items-center w-full relative gap-1.5   rounded bg-white ">
                <MaterialUiSelect
                  options={falsePropertiesData}
                  labelKey="label"
                  valueKey="value"
                  placeholder="Please Select An Attribute"
                  error={""}
                  initialValue={null}
                  handleChange={(value: any) => {
                    handleSelectChange(value.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full max-h-[200px] overflow-auto">
              {filteredData.map((item: any) => {
                return (
                  <div className="flex justify-start items-center w-full mb-1 px-2 pb-1 bg-white border border-[#d6d6d6]">
                    <div className="relative flex items-center justify-start flex-grow p-2">
                      <p className="flex-grow w-96 text-base text-left text-[#161616]">
                        {item.label}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleRemoveProperty(item.value);
                      }}
                      className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 p-2 rounded-[100px]"
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H19.9311L19.1301 19.2137C19.018 20.7837 17.7117 22 16.1378 22H7.86224C6.28832 22 4.982 20.7837 4.86986 19.2137L4.06888 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H8V4ZM10 6H14V4H10V6ZM6.07398 8L6.86478 19.0712C6.90216 19.5946 7.3376 20 7.86224 20H16.1378C16.6624 20 17.0978 19.5946 17.1352 19.0712L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
                          fill="#C7C7C7"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text="Confirm"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={updateProperties}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
