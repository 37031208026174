import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import './App.scss';
import Layout from 'view/layouts/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { RoutesConstants } from 'constants/RoutesConstants';
import apiLibrary from 'services/api';
import { convertKeysToCamelCase } from 'utils/caseConvertor';
import { profileSettingsAction } from 'store/userProfilesettings';
import LoadingBar from 'react-top-loading-bar';
import {
  AuthRoutes,
  ProtectedRoutes,
  SpeacialProtectedRoutes,
} from 'utils/RoutesProtection';
import Cookies from 'universal-cookie';
import 'yet-another-react-lightbox/styles.css';
import {
  CreatePassword,
  ForgetPassword,
  SignIn,
  Account,
  Categories,
  ChangePassword,
  Communities,
  CommunityProfile,
  DataAccessPolicy,
  DeleteAccount,
  Files,
  Forms,
  GeographyMap,
  AdminGeography,
  Management,
  Messages,
  Notification,
  Notifications,
  ObservationProfile,
  Observations,
  Organizations,
  Person,
  ProgramProfile,
  Programs,
  ProtocolProfile,
  Protocols,
  QualityAssurance,
  QualityAssuranceProfile,
  Roles,
  Settings,
  SpecieProfile,
  Species,
  EditFormDetails,
  Types,
  UserPage,
  ProfileLayout,
  Objects,
  ObjectProfile,
  Tags,
  NotificationRecipients,
  AddEditNotification,
  NotificationStatus,
  Reports,
  AddEditReport,
  SyestemSettings,
  NotificationCenter,
  OrganizationProfile,
  Behaviors,
  Conditions,
  AdminsTracking,
} from 'view/pages';
import useRoles from 'hooks/roles';
import Dashboard from 'view/pages/Dashboard';
import ReportsAnalytics from 'view/pages/Reports/ReportsAnalytics';
import ReportsProfile from 'view/pages/Reports/ReportsProfile';
import ResultObservations from 'view/pages/Reports/ResultObservations';
import { Imports } from 'view/pages/imports';
import AddImport from 'view/pages/imports/addImport';
import ViewImportsData from 'view/pages/imports/viewData';
import RunReport from 'view/pages/Reports/RunReport';
import RolesPermissions from 'view/pages/RolesPermissions';
import { DarkMode } from 'store/darkMode/initialState';
import usePermissions from 'hooks/usePermissions';
import {
  PollsSurveys,
  CreateOrEditSurvey,
  SubmitSurvey,
} from 'view/pages/PollsAndSurveys';
import ViewSurveyResults from 'view/pages/PollsAndSurveys/SurveyResults';
import PageNotFound from 'view/pages/404';
import initHotJar from 'utils/hotJarSetup';
import { currentEnvironment } from 'config';
import { removeExpiredFiltersFromLocalStorage } from 'utils/cacheOrRetriveFiltersDate';
import Head from 'view/components/Head';
import { getCurrentBranding } from 'utils/customBranding';

function App() {
  const cookies = new Cookies();
  const getAuthToken = () => cookies.get('token');

  const {
    isExternalReviewer,
    isExternalDataEndReviewer,
    isInternalEndDataViewer,
    isUserInternalReviewer,
  } = useRoles();
  const progress = useSelector((state: RootState) => state.loadingBar.progress);
  const auth = useSelector((state: RootState) => state.auth);
  const {
    roles,
    tags,
    universalSettings,
    surveyAndPolls,
    reports,
    reminders,
    notifications,
    dashboard,
    conversations,
    geographies,
    communities,
    species,
    objects,
    behaviors,
    conditions,
    imports,
    categories,
    types,
    qa,
    observations,
    programs,
    file,
    dynamicForms,
    protocols,
    organizations,
    users,
    profile,
    trackings,
  } = usePermissions();

  const dispatch = useDispatch();

  const fetchUserProfileSettings = useCallback(
    async (personId: number | null) => {
      try {
        const res = await apiLibrary.Settings.userProfileSettings(personId);
        const settings = convertKeysToCamelCase(res.data);
        dispatch(profileSettingsAction(settings));
        // Process other data as needed
      } catch (error: any) {
        console.error(error);
        // Handle error
      }
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    if (auth?.id && getAuthToken() && !isExternalReviewer()) {
      fetchUserProfileSettings(auth.id);
    }
  }, [auth?.id, fetchUserProfileSettings]);

  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  useLayoutEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (
      auth.roles.includes('super_admin') &&
      currentEnvironment !== 'localhost'
    ) {
      initHotJar(auth);
    }
  }, [auth.id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      removeExpiredFiltersFromLocalStorage();
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`${isDarkMode && 'dark'} ${getCurrentBranding()}`}>
      <LoadingBar
        color='#005C89'
        progress={progress}
        height={3}
        onLoaderFinished={() => { }}
      />
      {/* Default title */}
      <Head />
      <Routes>
        <Route path='/auth/*'>
          {/* Other authentication-related routes */}
          <Route
            path={RoutesConstants.login}
            element={<AuthRoutes component={SignIn} />}
          />
          <Route
            path={RoutesConstants.forgetPassword}
            element={<AuthRoutes component={ForgetPassword} />}
          />
          <Route
            path={RoutesConstants.createPassword}
            element={<SpeacialProtectedRoutes component={CreatePassword} />}
          />

          <Route path='*' element={<Navigate to={RoutesConstants.login} />} />
        </Route>

        {isExternalDataEndReviewer() ? (
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Navigate to='/reports/list' replace />} />
            <Route path='/reports/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Reports}
                    permissions={reports.canViewReports}
                    fallbackPath='/'
                  />
                }
              />
              <Route
                path={RoutesConstants.addReport}
                element={
                  <ProtectedRoutes
                    component={AddEditReport}
                    permissions={reports.canCreateReports}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.reportsId}>
                <Route
                  path={RoutesConstants.editReport}
                  element={
                    <ProtectedRoutes
                      component={AddEditReport}
                      permissions={reports.canEditReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.analytics}
                  element={
                    <ProtectedRoutes
                      component={ReportsAnalytics}
                      permissions={reports.canViewAnalyticsReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ReportsProfile}
                      permissions={reports.canViewReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.runReport}
                  element={
                    <ProtectedRoutes
                      component={RunReport}
                      permissions={reports.canViewProcessedVersionReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.reportVersion}
                  element={
                    <ProtectedRoutes
                      component={ResultObservations}
                      permissions={reports.canViewResultReports}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Route>
        ) : isExternalReviewer() ? (
          <Route path='/*' element={<Layout />}>
            <Route path='quality-assurance/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={QualityAssurance}
                    permissions={qa.canViewReviewerObservations}
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.observationId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ObservationProfile}
                      permissions={observations.canViewObservations}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path={RoutesConstants.qualityAssuranceId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes component={QualityAssuranceProfile} />
                  }
                />
              </Route>

              <Route path='*' element={<PageNotFound />} />
            </Route>
            <Route path='protocols/*'>
              <Route path={RoutesConstants.protocolId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ProtocolProfile}
                      permissions={true}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
            </Route>

            <Route path='*' element={<PageNotFound />} />
          </Route>
        ) : (
          <Route path='/*' element={<Layout />}>
            <Route
              path=''
              element={
                <ProtectedRoutes
                  component={Dashboard}
                  permissions={dashboard.canViewDashboardConfiguration}
                  fallbackPath={
                    !dashboard.canViewDashboardConfiguration &&
                      isInternalEndDataViewer()
                      ? '/reports/list'
                      : isUserInternalReviewer()
                        ? 'quality-assurance/list'
                        : -1
                  }
                />
              }
            />
            <Route
              path='profile/:personId/*'
              element={
                <ProtectedRoutes
                  component={ProfileLayout}
                  permissions={users.canViewUsersProfile}
                  fallbackPath={-1}
                />
              }
            >
              <Route
                path={RoutesConstants.person}
                element={
                  <ProtectedRoutes
                    component={Person}
                    permissions={users.canViewUsersProfile}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.account}
                element={
                  <ProtectedRoutes
                    component={Account}
                    permissions={users.canViewUsersProfile}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.changePassword}
                element={<ProtectedRoutes component={ChangePassword} />}
              />
              <Route
                path={RoutesConstants.notifications}
                element={
                  <ProtectedRoutes
                    component={Notification}
                    permissions={users.canViewNotification}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.roles}
                element={
                  <ProtectedRoutes
                    component={Roles}
                    permissions={profile.canViewRolesProfile}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.settings}
                element={
                  <ProtectedRoutes
                    component={Settings}
                    permissions={users.canViewSettings}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.deleteAccount}
                element={<ProtectedRoutes component={DeleteAccount} />}
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='community/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Communities}
                    permissions={
                      communities.canViewViewCommunities &&
                      communities.canViewSideBarCommunities
                    }
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.communityId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={CommunityProfile}
                      permissions={communities.canViewViewCommunities}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.users}
                  element={
                    <ProtectedRoutes
                      component={UserPage}
                      permissions={users.canViewUsers}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.organizations}
                  element={
                    <ProtectedRoutes
                      component={Organizations}
                      permissions={organizations.canViewOrganization}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={'forms'}
                  element={
                    <ProtectedRoutes
                      component={Forms}
                      permissions={dynamicForms.canViewDynamicForm}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={RoutesConstants.observations}
                  element={
                    <ProtectedRoutes
                      component={Observations}
                      permissions={observations.canViewObservations}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path='programs'
                  element={
                    <ProtectedRoutes
                      component={Programs}
                      permissions={programs.canViewProgram}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.geography}
                  element={
                    <ProtectedRoutes
                      component={GeographyMap}
                      permissions={
                        communities.canViewGeographyMapListCommunities
                      }
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path='species'
                  element={
                    <ProtectedRoutes
                      component={Species}
                      permissions={species.canViewSpecies}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route path={`species/${RoutesConstants.specieId}`}>
                  <Route
                    path={RoutesConstants.profile}
                    element={
                      <ProtectedRoutes
                        component={SpecieProfile}
                        permissions={species.canViewSpecies}
                        fallbackPath={-1}
                      />
                    }
                  />
                </Route>

                <Route
                  path='objects'
                  element={
                    <ProtectedRoutes
                      component={Objects}
                      permissions={objects.canViewObjects}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route path={`object/${RoutesConstants.objectId}`}>
                  <Route
                    path={RoutesConstants.profile}
                    element={
                      <ProtectedRoutes
                        component={ObjectProfile}
                        permissions={objects.canViewObjects}
                        fallbackPath={-1}
                      />
                    }
                  />
                </Route>

                <Route
                  path={'quality-assurance'}
                  element={
                    <ProtectedRoutes
                      component={QualityAssurance}
                      permissions={qa.canViewQa}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='users/*'>
              <Route
                path='list'
                element={
                  <ProtectedRoutes
                    component={UserPage}
                    permissions={
                      users.canViewUsers && users.canViewUsersInSideBar
                    }
                    fallbackPath={-1}
                  />
                }
              />
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='organizations/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Organizations}
                    permissions={
                      organizations.canViewOrganization &&
                      organizations.canViewOrganizationDirectory
                    }
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.organizationsId}>
                <Route
                  path={RoutesConstants.users}
                  element={
                    <ProtectedRoutes
                      component={UserPage}
                      permissions={users.canViewUsers}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.communities}
                  element={
                    <ProtectedRoutes
                      component={Communities}
                      permissions={communities.canViewViewCommunities}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path='programs'
                  element={
                    <ProtectedRoutes
                      component={Programs}
                      permissions={programs.canViewProgram}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path='profile'
                  element={
                    <ProtectedRoutes
                      component={OrganizationProfile}
                      permissions={organizations.canViewOrganization}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='forms/*'>
              <Route
                path='list'
                element={
                  <ProtectedRoutes
                    component={Forms}
                    permissions={
                      dynamicForms.canViewDynamicForm &&
                      dynamicForms.canViewDirectoryFromSideBarDynamicForm
                    }
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path=':formId/edit'
                element={
                  <ProtectedRoutes
                    component={EditFormDetails}
                    permissions={dynamicForms.canEditDynamicForm}
                    fallbackPath={-1}
                  />
                }
              />
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='programs/*'>
              <Route
                path='list'
                element={
                  <ProtectedRoutes
                    component={Programs}
                    permissions={
                      programs.canViewProgram &&
                      programs.canViewProgramDirectory
                    }
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.programId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ProgramProfile}
                      permissions={programs.canViewProgram}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.users}
                  element={
                    <ProtectedRoutes
                      component={UserPage}
                      permissions={users.canViewUsers}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={'polls-and-surveys'}
                  element={
                    <ProtectedRoutes
                      component={PollsSurveys}
                      permissions={surveyAndPolls.canViewSurveyAndPolls}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={RoutesConstants.observations}
                  element={
                    <ProtectedRoutes
                      component={Observations}
                      permissions={observations.canViewObservations}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={RoutesConstants.organizations}
                  element={
                    <ProtectedRoutes
                      component={Organizations}
                      permissions={organizations.canViewOrganization}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.communities}
                  element={
                    <ProtectedRoutes
                      component={Communities}
                      permissions={programs.canViewCommunities}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path='forms'
                  element={
                    <ProtectedRoutes
                      component={Forms}
                      permissions={programs.canViewForms}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={'protocols'}
                  element={
                    <ProtectedRoutes
                      component={Protocols}
                      permissions={protocols.canViewProtocol}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={RoutesConstants.files}
                  element={
                    <ProtectedRoutes
                      component={Files}
                      permissions={file.canViewFile}
                      fallbackPath={-1}
                    />
                  }
                />

                <Route
                  path={RoutesConstants.dataAccessPolicy}
                  element={
                    <ProtectedRoutes
                      component={DataAccessPolicy}
                      permissions={programs.canViewDataAccessPolicy}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.management}
                  element={
                    <ProtectedRoutes
                      component={Management}
                      permissions={programs.canViewManagers}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='protocols/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Protocols}
                    permissions={
                      protocols.canViewProtocol &&
                      protocols.canViewSideBarProtocol
                    }
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.protocolId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ProtocolProfile}
                      permissions={protocols.canViewProtocol}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='custom-notifications/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Notifications}
                    permissions={reminders.canViewViewReminders}
                    fallbackPath='/'
                  />
                }
              />

              <Route
                path={RoutesConstants.addNotification}
                element={
                  <ProtectedRoutes
                    component={AddEditNotification}
                    permissions={reminders.canCreateReminders}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.notificationId}>
                <Route
                  path={RoutesConstants.recipients}
                  element={
                    <ProtectedRoutes
                      component={NotificationRecipients}
                      permissions={reminders.canViewMembersReminders}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.status}
                  element={
                    <ProtectedRoutes
                      component={NotificationStatus}
                      permissions={reminders.canViewStatusHistoryReminders}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.editNotification}
                  element={
                    <ProtectedRoutes
                      component={AddEditNotification}
                      permissions={reminders.canEditReminders}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='imports/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Imports}
                    permissions={imports.canViewImports}
                    fallbackPath={-1}
                  />
                }
              />

              <Route
                path={RoutesConstants.addImport}
                element={
                  <ProtectedRoutes
                    component={AddImport}
                    permissions={imports.canCreateImports}
                    fallbackPath={-1}
                  />
                }
              />

              <Route
                path={RoutesConstants.viewImportsData}
                element={
                  <ProtectedRoutes
                    component={ViewImportsData}
                    permissions={imports.canViewViewDataImports}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='polls-and-surveys/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={PollsSurveys}
                    permissions={surveyAndPolls.canViewSurveyAndPolls}
                    fallbackPath='/'
                  />
                }
              />

              <Route
                path={RoutesConstants.viewSurveyResults}
                element={
                  <ProtectedRoutes
                    component={ViewSurveyResults}
                    permissions={surveyAndPolls.canViewResultsSurveyAndPolls}
                    fallbackPath={-1}
                  />
                }
              />

              <Route
                path={RoutesConstants.editSurveyId}
                element={
                  <ProtectedRoutes
                    component={CreateOrEditSurvey}
                    permissions={surveyAndPolls.canEditSurveyAndPolls}
                    fallbackPath={-1}
                  />
                }
              />

              {/* <Route
                path=":surveyId/add"
                element={<ProtectedRoutes component={SurveyComponent} />}
              /> */}

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='observations/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Observations}
                    permissions={observations.canViewObservations}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.observationId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ObservationProfile}
                      permissions={observations.canViewObservations}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='quality-assurance/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={QualityAssurance}
                    permissions={qa.canViewQa}
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.observationId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ObservationProfile}
                      permissions={observations.canViewObservations}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path={RoutesConstants.qualityAssuranceId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes component={QualityAssuranceProfile} />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>
            <Route path='species/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Species}
                    permissions={species.canViewSpecies}
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.specieId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={SpecieProfile}
                      permissions={species.canViewSpecies}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='object/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Objects}
                    permissions={objects.canViewObjects}
                    fallbackPath={-1}
                  />
                }
              />
              <Route path={RoutesConstants.objectId}>
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ObjectProfile}
                      permissions={objects.canViewObjects}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>
              <Route path='*' element={<PageNotFound />} />
            </Route>
            {/* <Route path="regions" element={<ConfigureRegions />} /> */}

            <Route
              path={RoutesConstants.messages}
              element={
                <ProtectedRoutes
                  component={Messages}
                  permissions={conversations.canViewConversations}
                  fallbackPath={-1}
                />
              }
            />

            <Route path='category/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Categories}
                    permissions={categories.canViewCategories}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='behaviors/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Behaviors}
                    permissions={behaviors.canViewBehaviors}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='conditions/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Conditions}
                    permissions={conditions.canViewConditions}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='types/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Types}
                    permissions={types.canViewType}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>
            <Route path='admin-tracking/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={AdminsTracking}
                    permissions={trackings.canViewAdminTracking}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='roles-permissions/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={RolesPermissions}
                    permissions={roles.canViewRole}
                    fallbackPath='/'
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='tagging/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Tags}
                    permissions={tags.canViewTag}
                    fallbackPath='/'
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route path='reports/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={Reports}
                    permissions={reports.canViewReports}
                    fallbackPath={-1}
                  />
                }
              />
              <Route
                path={RoutesConstants.addReport}
                element={
                  <ProtectedRoutes
                    component={AddEditReport}
                    permissions={reports.canCreateReports}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path={RoutesConstants.reportsId}>
                <Route
                  path={RoutesConstants.editReport}
                  element={
                    <ProtectedRoutes
                      component={AddEditReport}
                      permissions={reports.canEditReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.analytics}
                  element={
                    <ProtectedRoutes
                      component={ReportsAnalytics}
                      permissions={reports.canViewAnalyticsReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.profile}
                  element={
                    <ProtectedRoutes
                      component={ReportsProfile}
                      permissions={reports.canViewReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.runReport}
                  element={
                    <ProtectedRoutes
                      component={RunReport}
                      permissions={reports.canViewProcessedVersionReports}
                      fallbackPath={-1}
                    />
                  }
                />
                <Route
                  path={RoutesConstants.reportVersion}
                  element={
                    <ProtectedRoutes
                      component={ResultObservations}
                      permissions={reports.canViewResultReports}
                      fallbackPath={-1}
                    />
                  }
                />
              </Route>

              <Route
                path='*'
                element={<ProtectedRoutes component={PageNotFound} />}
              />
            </Route>

            <Route path='notification-center/*'>
              <Route
                path={RoutesConstants.list}
                element={
                  <ProtectedRoutes
                    component={NotificationCenter}
                    permissions={notifications.canViewNotifications}
                    fallbackPath={-1}
                  />
                }
              />

              <Route path='*' element={<PageNotFound />} />
            </Route>

            <Route
              path={RoutesConstants.stystemSettings}
              element={
                <ProtectedRoutes
                  component={SyestemSettings}
                  permissions={universalSettings.canViewUniversalSettings}
                  fallbackPath={-1}
                />
              }
            />

            <Route
              path='geography'
              element={
                <ProtectedRoutes
                  component={AdminGeography}
                  permissions={
                    geographies.canViewGeographies &&
                    geographies.canViewGeographiesInSideBar
                  }
                  fallbackPath={-1}
                />
              }
            />

            <Route path='*' element={<PageNotFound />} />
          </Route>
        )}

        {/* <Route path="/mapconfigure" element={<ConfigureRegions />} /> */}
        <Route
          path={`/polls-and-surveys/${RoutesConstants.submitSurveyId}`}
          element={<SubmitSurvey />}
        />

        <Route
          path='*'
          element={<Navigate to={getAuthToken() ? `/` : '/auth/signin'} />}
        />
      </Routes>

    </div>
  );
}

export default App;
