// DASHBOARD FILTER ACTIONS TYPES
export const DASHBOARD_DATE_PERIOD = "DASHBOARD_DATE_PERIOD";
export const DASHBOARD_FORMS = "DASHBOARD_FORMS";
export const DASHBOARD_COMMUNITIES = "DASHBOARD_COMMUNITIES";
export const DASHBOARD_PROGRAMS = "DASHBOARD_PROGRAMS";
export const DASHBOARD_ORGANIZATIONS = "DASHBOARD_ORGANIZATIONS";
export const DASHBOARD_RESET_FILTERS = "DASHBOARD_RESET_FILTERS";
export const SET_DASHBOARD_FILTERS = "SET_DASHBOARD_FILTERS";

// USERS FILTER ACTIONS TYPES
export const USERS_SORTING = "USERS_SORTING";
export const USERS_SEARCH = "USERS_SEARCH";
export const USERS_LAST_ACTIVE = "USERS_LAST_ACTIVE";
export const USERS_MEMBER_SINCE = "USERS_MEMBER_SINCE";
export const USERS_FORMS_OBSERVATIONS = "USERS_FORMS_OBSERVATIONS";
export const USERS_COMMUNITIES = "USERS_COMMUNITIES";
export const USERS_PROGRAMS = "USERS_PROGRAMS";

export const USERS_STATUS = "USERS_STATUS";
export const USERS_PAGINATION = "USERS_PAGINATION";
export const USERS_ITEMS_LIMIT = "USERS_ITEMS_LIMIT";
export const USERS_RESET_FILTERS = "USERS_RESET_FILTERS";
export const USERS_OPEN_ACCESS = "USERS_OPEN_ACCESS";

export const USERS_RESET_EXCEPT_COMMUNITY_FILTERS =
  "USERS_RESET_EXCEPT_COMMUNITY_FILTERS";

export const USERS_RESET_EXCEPT_PROGRAM_FILTERS =
  "USERS_RESET_EXCEPT_PROGRAM_FILTERS";

export const SET_USERS_FILTERS = "SET_USERS_FILTERS"

// COMMUNITIES DIRECTORY FILTERS ACTIONS TYPES

export const COMMUNITIES_SORTING = "COMMUNITIES_SORTING";
export const COMMUNITIES_SEARCH = "COMMUNITIES_SEARCH";
export const COMMUNITIES_STATUS = "COMMUNITIES_STATUS";
export const COMMUNITIES_PAGINATION = "COMMUNITIES_PAGINATION";
export const COMMUNITIES_ITEMS_LIMIT = "COMMUNITIES_ITEMS_LIMIT";
export const COMMUNITIES_ORGANIZATIONS = "COMMUNITIES_ORGANIZATIONS";
export const COMMUNITIES_PROGRAM = "COMMUNITIES_PROGRAM";
export const COMMUNITIES_RESET_EXCEPT_ORGANIZATION_FILTERS =
  "COMMUNITIES_RESET_EXCEPT_ORGANIZATION_FILTERS";
export const COMMUNITIES_RESET_EXCEPT_PROGRAM_FILTERS =
  "COMMUNITIES_RESET_EXCEPT_PROGRAM_FILTERS";
export const COMMUNITIES_RESET_FILTERS = "COMMUNITIES_RESET_FILTERS";
export const COMMUNITIES_MEMBERS = "COMMUNITIES_MEMBERS";
export const COMMUNITIES_DATE_ADDED = "COMMUNITIES_DATE_ADDED";
export const COMMUNITIES_TYPE = "COMMUNITIES_TYPE";
export const COMMUNITIES_VIEW_TYPE = "COMMUNITIES_VIEW_TYPE";
export const COMMUNITIES_TAGS = "COMMUNITIES_TAGS";
export const SET_COMMUNITIES_FILTERS = "SET_COMMUNITIES_FILTERS";

/// ORGANIZATIONS DIRECTORY FILTERS

export const ORGANIZATIONS_SORTING = "ORGANIZATIONS_SORTING";
export const ORGANIZATIONS_SEARCH = "ORGANIZATIONS_SEARCH";
export const ORGANIZATIONS_STATUS = "ORGANIZATIONS_STATUS";
export const ORGANIZATIONS_PAGINATION = "ORGANIZATIONS_PAGINATION";
export const ORGANIZATIONS_ITEMS_LIMIT = "ORGANIZATIONS_ITEMS_LIMIT";
export const ORGANIZATIONS_COMMUNITIES = "ORGANIZATIONS_COMMUNITIES";
export const ORGANIZATIONS_PROGRAM = "ORGANIZATIONS_PROGRAM";
export const ORGANIZATIONS_RESET_EXCEPT_COMMUNITY_FILTERS =
  "ORGANIZATIONS_RESET_EXCEPT_COMMUNITY_FILTERS";
export const ORGANIZATIONS_RESET_EXCEPT_PROGRAM_FILTERS =
  "ORGANIZATIONS_RESET_EXCEPT_PROGRAM_FILTERS";
export const ORGANIZATIONS_RESET_FILTERS = "ORGANIZATIONS_RESET_FILTERS";
export const ORGANIZATIONS_MEMBERS = "ORGANIZATIONS_MEMBERS";
export const ORGANIZATIONS_DATE_ADDED = "ORGANIZATIONS_DATE_ADDED";
export const ORGANIZATIONS_TYPE = "ORGANIZATIONS_TYPE";
export const ORGANIZATIONS_VIEW_TYPE = "ORGANIZATIONS_VIEW_TYPE";
export const ORGANIZATIONS_TAGS = "ORGANIZATIONS_TAGS";
export const SET_ORGANIZATIONS_FILTERS = "SET_ORGANIZATIONS_FILTERS";

///   NotificationsRecipients

export const NOTIFICATIONSRECIPIENTS_SORTING =
  "NOTIFICATIONSRECIPIENTS_SORTING";
export const NOTIFICATIONSRECIPIENTS_SEARCH = "NOTIFICATIONSRECIPIENTS_SEARCH";
export const NOTIFICATIONSRECIPIENTS_PAGINATION =
  "NOTIFICATIONSRECIPIENTS_PAGINATION";
export const NOTIFICATIONSRECIPIENTS_ITEMS_LIMIT =
  "NOTIFICATIONSRECIPIENTS_ITEMS_LIMIT";
export const NOTIFICATIONSRECIPIENTS_RESET_FILTERS =
  "NOTIFICATIONSRECIPIENTS_RESET_FILTERS";
export const NOTIFICATIONSRECIPIENTS_MEMBERS =
  "NOTIFICATIONSRECIPIENTS_MEMBERS";
export const NOTIFICATIONSRECIPIENTS_DATE_ADDED =
  "NOTIFICATIONSRECIPIENTS_DATE_ADDED";
export const NOTIFICATIONSRECIPIENTS_ACTIONS =
  "NOTIFICATIONSRECIPIENTS_ACTIONS";
export const NOTIFICATIONSRECIPIENTS_REMOVE = "NOTIFICATIONSRECIPIENTS_REMOVE";
export const NOTIFICATIONS_RECIPIENTS_DATE_CREATED_UPDATED_ARCHIVED_DELETED =
  "NOTIFICATIONS_RECIPIENTS_DATE_CREATED_UPDATED_ARCHIVED_DELETED";
export const NOTIFICATIONS_RECIPIENTS_RESET_EXCEPT_PROGRAM_FILTERS =
  "NOTIFICATIONS_RECIPIENTS_RESET_EXCEPT_PROGRAM_FILTERS";

// DYNAMIC FORMS ACTIONS

export const DYNAMIC_FORMS_SORTING = "DYNAMIC_FORMS_SORTING";
export const DYNAMIC_FORMS_SEARCH = "DYNAMIC_FORMS_SEARCH";
export const DYNAMIC_FORMS_STATUS = "DYNAMIC_FORMS_STATUS";
export const DYNAMIC_FORMS_PAGINATION = "DYNAMIC_FORMS_PAGINATION";
export const DYNAMIC_FORMS_ITEMS_LIMIT = "DYNAMIC_FORMS_ITEMS_LIMIT";
export const DYNAMIC_FORMS_CREATED_BY = "DYNAMIC_FORMS_CREATED_BY";
export const DYNAMIC_FORMS_PROTOCOLS = "DYNAMIC_FORMS_PROTOCOLS";
export const DYNAMIC_FORMS_ARCHIVED_BY = "DYNAMIC_FORMS_ARCHIVED_BY";
export const DYNAMIC_FORMS_DATE_CREATED_UPDATED_ARCHIVED =
  "DYNAMIC_FORMS_DATE_CREATED_UPDATED_ARCHIVED";
export const DYNAMIC_FORMS_PROGRAMS = "DYNAMIC_FORMS_PROGRAMS";
export const DYNAMIC_FORMS_RESET_FILTERS = "DYNAMIC_FORMS_RESET_FILTERS";
export const DYNAMIC_FORMS_VIEW_TYPE = "DYNAMIC_FORMS_VIEW_TYPE";
export const DYNAMIC_FORMS_OBSERVASTIONS = "DYNAMIC_FORMS_OBSERVASTIONS";
export const DYNAMIC_FORMS_RESET_EXCEPT_PROGRAMS_FILTERS =
  "DYNAMIC_FORMS_RESET_EXCEPT_PROGRAMS_FILTERS";
export const DYNAMIC_FORMS_COMMUNITY = "DYNAMIC_FORMS_COMMUNITY";
export const SET_DYNAMIC_FORMS_FILTERS = "SET_DYNAMIC_FORMS_FILTERS";

// PROGRAMS DIRECTORY FILTERS ACTIONS TYPES
export const PROGRAMS_SORTING = "PROGRAMS_SORTING";
export const PROGRAMS_SEARCH = "PROGRAMS_SEARCH";
export const PROGRAMS_STATUS = "PROGRAMS_STATUS";
export const PROGRAMS_PAGINATION = "PROGRAMS_PAGINATION";
export const PROGRAMS_ITEMS_LIMIT = "PROGRAMS_ITEMS_LIMIT";
export const PROGRAMS_ORGANIZATIONS = "PROGRAMS_ORGANIZATIONS";
export const PROGRAMS_RESET_EXCEPT_ORGANIZATION_FILTERS =
  "PROGRAMS_RESET_EXCEPT_ORGANIZATION_FILTERS";
export const PROGRAMS_RESET_EXCEPT_COMMUNITY_FILTERS =
  "PROGRAMS_RESET_EXCEPT_COMMUNITY_FILTERS";
export const PROGRAMS_RESET_FILTERS = "PROGRAMS_RESET_FILTERS";
export const PROGRAMS_MEMBERS_OBSERVATIONS_FORMS =
  "PROGRAMS_MEMBERS_OBSERVATIONS_FORMS";
export const PROGRAMS_TIMEFRAME = "PROGRAMS_TIMEFRAME";
export const PROGRAMS_TYPE = "PROGRAMS_TYPE";
export const PROGRAMS_VIEW_TYPE = "PROGRAMS_VIEW_TYPE";
export const PROGRAMS_COMMUNITY = "PROGRAMS_COMMUNITY";
export const PROGRAMS_TAGS = "PROGRAMS_TAGS";
export const SET_PROGRAMS_FILTERS = "SET_PROGRAMS_FILTERS";

//  MANAGERS DIRECTORY FILTERS ACTIONS TYPES

export const ALL_MANAGERS = "ALL_MANAGERS";
export const MANAGER_SORTING = "MANAGER_SORTING";
export const MANAGER_SEARCH = "MANAGER_SEARCH";
export const MANAGER_TYPE = "MANAGER_TYPE";
export const MANAGER_PAGINATION = "MANAGER_PAGINATION";
export const MANAGER_ITEMS_LIMIT = "MANAGER_ITEMS_LIMIT";
export const MANAGER_FILTER_RESET = "MANAGER_FILTER_RESET";
export const SET_MANAGER_FILTERS = "SET_MANAGER_FILTERS";


//  ROLES DATA FILTER ACTIONS TYPES

export const ALL_ROLES = "ALL_ROLES";
export const ROLES_ITEMS_LIMIT = "ROLES_ITEMS_LIMIT";
export const ROLES_SEARCH = "ROLES_SEARCH";
export const ROLES_TYPE = "ROLES_TYPE";
export const ROLES_PERMISSIONS_RESET_FILTER = "ROLES_PERMISSIONS_RESET_FILTER";

// TARGET MEMBERS FILTER ACTIONS

export const ALL_TARGET_MEMBERS = "ALL_TARGET_MEMBERS";
export const TARGET_MEMBERS_ITEMS_LIMIT = "TARGET_MEMBERS_ITEMS_LIMIT";
export const TARGET_MEMBERS_SEARCH = "TARGET_MEMBERS_SEARCH";
export const TARGET_MEMBERS_COMMUNITY_IDS = "TARGET_MEMBERS_COMMUNITY_IDS";
export const TARGET_MEMBERS_PROGRAM_IDS = "TARGET_MEMBERS_PROGRAM_IDS";
export const TARGET_MEMBERS_ROLE_IDS = "TARGET_MEMBERS_ROLE_IDS";

/// PROTOCOLS DIRECTORY FILTERS

export const PROTOCOLS_SORTING = "PROTOCOLS_SORTING";
export const PROTOCOLS_SEARCH = "PROTOCOLS_SEARCH";
export const PROTOCOLS_STATUS = "PROTOCOLS_STATUS";
export const PROTOCOLS_PAGINATION = "PROTOCOLS_PAGINATION";
export const PROTOCOLS_ITEMS_LIMIT = "PROTOCOLS_ITEMS_LIMIT";
export const PROTOCOLS_PROGRAM = "PROTOCOLS_PROGRAM";
export const PROTOCOLS_FORM = "PROTOCOLS_FORM";
export const PROTOCOLS_RESET_EXCEPT_PROGRAM_FILTERS =
  "PROTOCOLS_RESET_EXCEPT_PROGRAM_FILTERS";
export const PROTOCOLS_RESET_EXCEPT_FORM_FILTERS =
  "PROTOCOLS_RESET_EXCEPT_FORM_FILTERS";
export const PROTOCOLS_DATE_CREATED_UPDATED_ARCHIVED_DELETED =
  "PROTOCOLS_DATE_CREATED_UPDATED_ARCHIVED_DELETED";
export const PROTOCOLS_RESET_FILTERS = "PROTOCOLS_RESET_FILTERS";
export const PROTOCOLS_CREATED_BY = "PROTOCOLS_CREATED_BY";
export const PROTOCOLS_ARCHIVED_BY = "PROTOCOLS_ARCHIVED_BY";
export const PROTOCOLS_DELETED_BY = "PROTOCOLS_DELETED_BY";
export const PROTOCOLS_UPDATED_BY = "PROTOCOLS_UPDATED_BY";
export const SET_PROTOCOLS_FILTERS = "SET_PROTOCOLS_FILTERS"
// OBSERVATIONS DIRECTORY FILTERS ACTIONS TYPES
export const OBSERVATIONS_SORTING = "OBSERVATIONS_SORTING";
export const OBSERVATIONS_SEARCH = "OBSERVATIONS_SEARCH";
export const OBSERVATIONS_STATUS = "OBSERVATIONS_STATUS";
export const OBSERVATIONS_PAGINATION = "OBSERVATIONS_PAGINATION";
export const OBSERVATIONS_ITEMS_LIMIT = "OBSERVATIONS_ITEMS_LIMIT";

export const OBSERVATIONS_RESET_EXCEPT_PROGRAM_FILTERS =
  "OBSERVATIONS_RESET_EXCEPT_PROGRAM_FILTERS";
export const OBSERVATIONS_RESET_EXCEPT_COMMUNITY_FILTERS =
  "OBSERVATIONS_RESET_EXCEPT_COMMUNITY_FILTERS";

export const OBSERVATIONS_RESET_FILTERS = "OBSERVATIONS_RESET_FILTERS";

export const OBSERVATIONS_DATE_ADDED = "OBSERVATIONS_DATE_ADDED";
export const OBSERVATIONS_TYPE = "OBSERVATIONS_TYPE";

export const OBSERVATIONS_VIEW_TYPE = "OBSERVATIONS_VIEW_TYPE";

export const OBSERVATIONS_OBSERVER = "OBSERVATIONS_OBSERVER";

export const OBSERVATIONS_PROGRAMS = "OBSERVATIONS_PROGRAMS";

export const OBSERVATIONS_COMMUNITY = "OBSERVATIONS_COMMUNITY";

export const OBSERVATIONS_FORMS = "OBSERVATIONS_FORMS";
export const OBSERVATIONS_SPECIES = "OBSERVATIONS_SPECIES";
export const SET_OBSERVATIONS_FILTERS = "SET_OBSERVATIONS_FILTERS"

// MESSAGES FILTERS ACTIONS TYPES
export const MESSAGES_SEARCH = "MESSAGES_SEARCH";
export const MESSAGES_PAGINATION = "MESSAGES_PAGINATION";
export const MESSAGES_ITEMS_LIMIT = "MESSAGES_ITEMS_LIMIT";
export const MESSAGES_CONTACT_PERSON_SEARCH = "MESSAGES_CONTACT_PERSON_SEARCH";
export const MESSAGES_STATUS = "MESSAGES_STATUS";
export const MESSAGES_NEW_CHAT = "MESSAGES_NEW_CHAT";
export const MESSAGES_APP_ID = "MESSAGES_APP_ID";
export const MESSAGES_RESET_FILTER = "MESSAGES_RESET_FILTER";

// QA OBSERVATIONS DIRECTORY FILTERS ACTIONS TYPES
export const QUALITY_ASSURANCE_OBSERVATIONS_SORTING =
  "QUALITY_ASSURANCE_OBSERVATIONS_SORTING";
export const QUALITY_ASSURANCE_OBSERVATIONS_SEARCH =
  "QUALITY_ASSURANCE_OBSERVATIONS_SEARCH";
export const QUALITY_ASSURANCE_OBSERVATIONS_STATUS =
  "QUALITY_ASSURANCE_OBSERVATIONS_STATUS";
export const QUALITY_ASSURANCE_OBSERVATIONS_PAGINATION =
  "QUALITY_ASSURANCE_OBSERVATIONS_PAGINATION";
export const QUALITY_ASSURANCE_OBSERVATIONS_ITEMS_LIMIT =
  "QUALITY_ASSURANCE_OBSERVATIONS_ITEMS_LIMIT";

export const QUALITY_ASSURANCE_OBSERVATIONS_RESET_FILTERS =
  "QUALITY_ASSURANCE_OBSERVATIONS_RESET_FILTERS";

export const QUALITY_ASSURANCE_OBSERVATIONS_DATE_UPDATED =
  "QUALITY_ASSURANCE_OBSERVATIONS_DATE_UPDATED";
export const QUALITY_ASSURANCE_OBSERVATIONS_TYPE =
  "QUALITY_ASSURANCE_OBSERVATIONS_TYPE";

export const QUALITY_ASSURANCE_OBSERVATIONS_VIEW_TYPE =
  "QUALITY_ASSURANCE_OBSERVATIONS_VIEW_TYPE";

export const QUALITY_ASSURANCE_OBSERVATIONS_FILTER_STATUS =
  "QUALITY_ASSURANCE_OBSERVATIONS_FILTER_STATUS";

export const QUALITY_ASSURANCE_OBSERVATIONS_OBSERVER =
  "QUALITY_ASSURANCE_OBSERVATIONS_OBSERVER";

export const QUALITY_ASSURANCE_OBSERVATIONS_PROGRAMS =
  "QUALITY_ASSURANCE_OBSERVATIONS_PROGRAMS";

export const QUALITY_ASSURANCE_OBSERVATIONS_COMMUNITY =
  "QUALITY_ASSURANCE_OBSERVATIONS_COMMUNITY";

export const QUALITY_ASSURANCE_OBSERVATIONS_REVIEWER =
  "QUALITY_ASSURANCE_OBSERVATIONS_REVIEWER";

export const SET_QUALITY_ASSURANCE_OBSERVATIONS_FILTERS = "SET_QUALITY_ASSURANCE_OBSERVATIONS_FILTERS"
// CATEGORIES DIRECTORY FILTERS ACTIONS TYPES
export const CATEGORIES_SORTING = "CATEGORIES_SORTING";
export const CATEGORIES_SEARCH = "CATEGORIES_SEARCH";
export const CATEGORIES_STATUS = "CATEGORIES_STATUS";
export const CATEGORIES_PAGINATION = "CATEGORIES_PAGINATION";
export const CATEGORIES_ITEMS_LIMIT = "CATEGORIES_ITEMS_LIMIT";
export const CATEGORIES_RESET_FILTERS = "CATEGORIES_RESET_FILTERS";
export const SET_CATEGORIES_FILTERS = "SET_CATEGORIES_FILTERS";

// BEHAVIORS DIRECTORY FILTERS ACTIONS TYPES
export const BEHAVIORS_SORTING = "BEHAVIORS_SORTING";
export const BEHAVIORS_SEARCH = "BEHAVIORS_SEARCH";
export const BEHAVIORS_STATUS = "BEHAVIORS_STATUS";
export const BEHAVIORS_PAGINATION = "BEHAVIORS_PAGINATION";
export const BEHAVIORS_ITEMS_LIMIT = "BEHAVIORS_ITEMS_LIMIT";
export const BEHAVIORS_RESET_FILTERS = "BEHAVIORS_RESET_FILTERS";
export const BEHAVIORS_TYPES = "BEHAVIORS_TYPES";
export const SET_BEHAVIORS_FILTERS = "SET_BEHAVIORS_FILTERS";

// CONDITIONS DIRECTORY FILTERS ACTIONS TYPES
export const CONDITIONS_SORTING = "CONDITIONS_SORTING";
export const CONDITIONS_SEARCH = "CONDITIONS_SEARCH";
export const CONDITIONS_STATUS = "CONDITIONS_STATUS";
export const CONDITIONS_PAGINATION = "CONDITIONS_PAGINATION";
export const CONDITIONS_ITEMS_LIMIT = "CONDITIONS_ITEMS_LIMIT";
export const CONDITIONS_RESET_FILTERS = "CONDITIONS_RESET_FILTERS";
export const CONDITIONS_TYPES = "CONDITIONS_TYPES";
export const SET_CONDITIONS_FILTERS = "SET_CONDITIONS_FILTERS";

// IMPORTS DIRECTORY FILTERS ACTIONS TYPES
export const IMPORTS_SORTING = "IMPORTS_SORTING";
export const IMPORTS_SEARCH = "IMPORTS_SEARCH";
export const IMPORTS_STATUS = "IMPORTS_STATUS";
export const IMPORTS_PAGINATION = "IMPORTS_PAGINATION";
export const IMPORTS_ITEMS_LIMIT = "IMPORTS_ITEMS_LIMIT";
export const IMPORTS_FILTER_RESET = "IMPORTS_FILTER_RESET";
export const SET_IMPORTS_FILTERS = "SET_IMPORTS_FILTERS";

// IMPORTS PROPERTY DROPDOWN SEARCH
export const IMPORTS_PROPERTY_DROPDOWN_SEARCH =
  "IMPORTS_PROPERTY_DROPDOWN_SEARCH";

// TYPES DIRECTORY FILTERS ACTIONS TYPES
export const TYPES_SORTING = "TYPES_SORTING";
export const TYPES_SEARCH = "TYPES_SEARCH";
export const TYPES_STATUS = "TYPES_STATUS";
export const TYPES_PAGINATION = "TYPES_PAGINATION";
export const TYPES_ITEMS_LIMIT = "TYPES_ITEMS_LIMIT";
export const TYPES_CATEGORIES = "TYPES_CATEGORIES";
export const TYPES_RESET_FILTERS = "TYPES_RESET_FILTERS";
export const SET_TYPES_FILTERS = "SET_TYPES_FILTERS";
// ADMIN_TRACKING DIRECTORY FILTERS ACTIONS TYPES
export const ADMIN_TRACKING_SORTING = "ADMIN_TRACKING_SORTING";
export const ADMIN_TRACKING_SEARCH = "ADMIN_TRACKING_SEARCH";
export const ADMIN_TRACKING_STATUS = "ADMIN_TRACKING_STATUS";
export const ADMIN_TRACKING_PAGINATION = "ADMIN_TRACKING_PAGINATION";
export const ADMIN_TRACKING_ITEMS_LIMIT = "ADMIN_TRACKING_ITEMS_LIMIT";
export const ADMIN_TRACKING_RESET_FILTERS = "ADMIN_TRACKING_RESET_FILTERS";
export const SET_ADMIN_TRACKING_FILTERS = "SET_ADMIN_TRACKING_FILTERS";

// REPORTS DIRECTORY FILTERS ACTIONS TYPES
export const REPORTS_SORTING = "REPORTS_SORTING";
export const REPORTS_SEARCH = "REPORTS_SEARCH";
export const REPORTS_STATUS = "REPORTS_STATUS";
export const REPORTS_PAGINATION = "REPORTS_PAGINATION";
export const REPORTS_ITEMS_LIMIT = "REPORTS_ITEMS_LIMIT";
export const REPORTS_CATEGORIES = "REPORTS_CATEGORIES";
export const REPORTS_RESET_FILTERS = "REPORTS_RESET_FILTERS";
export const SET_REPORT_FILTERS = "SET_REPORT_FILTERS"


// REPORTS PROFILE DIRECTORY FILTERS ACTIONS TYPES
export const REPORTS_PROFILE_SORTING = "REPORTS_PROFILE_SORTING";
export const REPORTS_PROFILE_STATUS = "REPORTS_PROFILE_STATUS";
export const REPORTS_PROFILE_PAGINATION = "REPORTS_PROFILE_PAGINATION";
export const REPORTS_PROFILE_ITEMS_LIMIT = "REPORTS_PROFILE_ITEMS_LIMIT";
export const REPORTS_PROFILE_RESET_FILTERS = "REPORTS_PROFILE_RESET_FILTERS";
export const SET_REPORTS_PROFILE_FILTERS = "SET_REPORTS_PROFILE_FILTERS"



export const REPORTS_VERSION_RESET_FILTERS = "REPORTS_VERSION_RESET_FILTERS";
export const REPORTS_VERSIONS_VIEW_TYPE = "REPORTS_VERSIONS_VIEW_TYPE";
export const REPORTS_VERSIONS = "REPORTS_VERSIONS";
export const REPORTS_VETSION_SEARCH = "REPORTS_VETSION_SEARCH";
export const REPORTS_VERIONS_ITEMS_LIMIT = "REPORTS_VERIONS_ITEMS_LIMIT";
export const REPORTS_VERIONS_TOTAL_PAGES = "REPORTS_VERIONS_TOTAL_PAGES";
export const REPORTS_VERSION_PAGINATION = "REPORTS_VERSION_PAGINATION";
export const REPORTS_VERSION_LOCATIONS = "REPORTS_VERSION_LOCATIONS";
export const REPORTS_VERSION_ALL_LOCATIONS = "REPORTS_VERSION_ALL_LOCATIONS";
export const REPORTS_ITEMS_PER_PAGE_LIMIT = "REPORTS_ITEMS_PER_PAGE_LIMIT";
export const SET_REPORTS_VERSION_FILTERS = "SET_REPORTS_VERSION_FILTERS"



export const REPORTS_PROGRAMS = "REPORTS_PROGRAMS";
export const REPORTS_COMMUNITY = "REPORTS_COMMUNITY";
export const REPORTS_ORGANIZATION = "REPORTS_ORGANIZATION";
export const REPORTS_FORMS = "REPORTS_FORMS";
export const REPORTS_DATE_ADDED_UPDATED_LAST_RUN_NEXT_RUN =
  "REPORTS_DATE_ADDED_UPDATED_LAST_RUN_NEXT_RUN";

export const REPORTS_CREATED_BY = "REPORTS_CREATED_BY";
export const REPORTS_DATE_ADDED = "REPORTS_DATE_ADDED";
export const REPORTS_UPDATED_DATE = "REPORTS_UPDATED_DATE";
export const REPORTS_LAST_RUN = "REPORTS_LAST_RUN";
export const REPORTS_NEXT_RUN = "REPORTS_NEXT_RUN";

// REPORTS ANALYTICS DIRECTORY FILTERS ACTIONS TYPES

export const REPORTS_ANALYTICS_SORTING = "REPORTS_ANALYTICS_SORTING";
export const REPORTS_ANALYTICS_STATUS = "REPORTS_ANALYTICS_STATUS";
export const REPORTS_ANALYTICS_PAGINATION = "REPORTS_ANALYTICS_PAGINATION";
export const REPORTS_ANALYTICS_ITEMS_LIMIT = "REPORTS_ANALYTICS_ITEMS_LIMIT";
export const REPORTS_ANALYTICS_RESET_FILTERS =
  "REPORTS_ANALYTICS_RESET_FILTERS";

export const REPORTS_ANALYTICS_VERSION = "REPORTS_ANALYTICS_VERSION";
export const REPORTS_ANALYTICS_MEMBER = "REPORTS_ANALYTICS_MEMBER";
export const REPORTS_ANALYTICS_ACTIVITY = "REPORTS_ANALYTICS_ACTIVITY";
export const REPORTS_ANALYTICS_DATE = "REPORTS_ANALYTICS_DATE";
export const SET_REPORTS_ANALYTICS_FILTERS = "SET_REPORTS_ANALYTICS_FILTERS"
// NOTIFICATIONS DIRECTORY FILTERS ACTIONS TYPES
export const NOTIFICATIONS_SORTING = "NOTIFICATIONS_SORTING";
export const NOTIFICATIONS_SEARCH = "NOTIFICATIONS_SEARCH";
export const NOTIFICATIONS_STATUS = "NOTIFICATIONS_STATUS";
export const NOTIFICATIONS_PAGINATION = "NOTIFICATIONS_PAGINATION";
export const NOTIFICATIONS_ITEMS_LIMIT = "NOTIFICATIONS_ITEMS_LIMIT";

export const NOTIFICATIONS_RESET_FILTERS = "NOTIFICATIONS_RESET_FILTERS";

export const NOTIFICATIONS_TYPE = "NOTIFICATIONS_TYPE";

export const NOTIFICATIONS_VIEW_TYPE = "NOTIFICATIONS_VIEW_TYPE";

export const NOTIFICATIONS_FORMS = "NOTIFICATIONS_FORMS";

export const NOTIFICATIONS_PROGRAMS = "NOTIFICATIONS_PROGRAMS";

export const NOTIFICATIONS_COMMUNITY = "NOTIFICATIONS_COMMUNITY";

export const NOTIFICATIONS_MEMBER = "NOTIFICATIONS_MEMBER";

export const NOTIFICATIONS_SCHEDULED_FOR = "NOTIFICATIONS_SCHEDULED_FOR";

export const NOTIFICATIONS_CREATED_BY = "NOTIFICATIONS_CREATED_BY";

export const SET_NOTIFICATIONS_FILTERS = "SET_NOTIFICATIONS_FILTERS";




// SPECIES DIRECTORY FILTERS ACTIONS SPECIES
export const SPECIES_SORTING = "SPECIES_SORTING";
export const SPECIES_SEARCH = "SPECIES_SEARCH";
export const SPECIES_STATUS = "SPECIES_STATUS";
export const SPECIES_PAGINATION = "SPECIES_PAGINATION";
export const SPECIES_ITEMS_LIMIT = "SPECIES_ITEMS_LIMIT";
export const SPECIES_TYPES = "SPECIES_TYPES";
export const SPECIES_CATEGORIES = "SPECIES_CATEGORIES";
export const SPECIES_COMMUNITY = "SPECIES_COMMUNITY";
export const SPECIES_RESET_FILTERS = "SPECIES_RESET_FILTERS";
export const SPECIES_DATE_ADDED = "SPECIES_DATE_ADDED";
export const SPECIES_VIEW_TYPE = "SPECIES_VIEW_TYPE";
export const SET_SPECIES_FILTERS = "SET_SPECIES_FILTERS"


// OBJECTS DIRECTORY FILTERS ACTIONS OBJECTS
export const OBJECTS_SORTING = "OBJECTS_SORTING";
export const OBJECTS_SEARCH = "OBJECTS_SEARCH";
export const OBJECTS_STATUS = "OBJECTS_STATUS";
export const OBJECTS_PAGINATION = "OBJECTS_PAGINATION";
export const OBJECTS_ITEMS_LIMIT = "OBJECTS_ITEMS_LIMIT";
export const OBJECTS_TYPES = "OBJECTS_TYPES";
export const OBJECTS_CATEGORIES = "OBJECTS_CATEGORIES";
export const OBJECTS_RESET_FILTERS = "OBJECTS_RESET_FILTERS";
export const OBJECTS_DATE_ADDED = "OBJECTS_DATE_ADDED";
export const OBJECTS_VIEW_TYPE = "OBJECTS_VIEW_TYPE";
export const SET_OBJECTS_FILTERS = "SET_OBJECTS_FILTERS"

// TAGS DIRECTORY FILTERS ACTIONS TYPES
export const TAGS_SORTING = "TAGS_SORTING";
export const TAGS_SEARCH = "TAGS_SEARCH";
export const TAGS_PAGINATION = "TAGS_PAGINATION";
export const TAGS_ITEMS_LIMIT = "TAGS_ITEMS_LIMIT";
export const TAG_RESET_ACTION = "TAG_RESET_ACTION";
export const SET_TAGS_FILTERS = "SET_TAGS_FILTERS"


// GEOGRAPHY DIRECTORY FILTERS ACTIONS TYPES
export const GEOGRAPHY_SORTING = "GEOGRAPHY_SORTING";
export const GEOGRAPHY_SEARCH = "GEOGRAPHY_SEARCH";
export const GEOGRAPHY_TYPE = "GEOGRAPHY_TYPE";
export const GEOGRAPHY_PAGINATION = "GEOGRAPHY_PAGINATION";
export const GEOGRAPHY_ITEMS_LIMIT = "GEOGRAPHY_ITEMS_LIMIT";
export const GEOGRAPHY_RESET_FILTERS = "GEOGRAPHY_RESET_FILTERS";
export const GEOGRAPHY_DATE_ADDED = "GEOGRAPHY_DATE_ADDED";
export const GEOGRAPHY_VIEW_TYPE = "GEOGRAPHY_VIEW_TYPE";
export const GEOGRAPHY_CREATED_BY = "GEOGRAPHY_CREATED_BY";
export const SET_GEOGRAPHY_FILTERS = "SET_GEOGRAPHY_FILTERS";

// OBSERVATIONS ACTIVITIES FILTER ACTIONS TYPES
export const ACTIVITIES_PAGINATION = "ACTIVITIES_PAGINATION";
export const ACTIVITIES_ITEMS_LIMIT = "ACTIVITIES_ITEMS_LIMIT";
export const ACTIVITY_TYPE = "ACTIVITY_TYPE";
export const ACTIVITIES_RESET_FILTER = "ACTIVITIES_RESET_FILTER";

// IMPORTS VIEW DATA DIRECTORY FILTERS ACTIONS TYPES
export const IMPORTS_VIEW_DATA_SORTING = "IMPORTS_VIEW_DATA_SORTING";
export const IMPORTS_VIEW_DATA_SEARCH = "IMPORTS_VIEW_DATA_SEARCH";
export const IMPORTS_VIEW_DATA_STATUS = "IMPORTS_VIEW_DATA_STATUS";
export const IMPORTS_VIEW_DATA_PAGINATION = "IMPORTS_VIEW_DATA_PAGINATION";
export const IMPORTS_VIEW_DATA_ITEMS_LIMIT = "IMPORTS_VIEW_DATA_ITEMS_LIMIT";
export const IMPORTS_VIEW_DATA_RESET_FILTERS =
  "IMPORTS_VIEW_DATA_RESET_FILTERS";

// ROLES PERMISSIONS DIRECTORY FILTERS ACTIONS TYPES
export const ROLES_PERMISSIONS_SORTING = "ROLES_PERMISSIONS_SORTING";
export const ROLES_PERMISSIONS_SEARCH = "ROLES_PERMISSIONS_SEARCH";
export const ROLES_PERMISSIONS_PAGINATION = "ROLES_PERMISSIONS_PAGINATION";
export const ROLES_PERMISSIONS_ITEMS_LIMIT = "ROLES_PERMISSIONS_ITEMS_LIMIT";
export const SET_ROLES_PERMISSIONS_FILTERS = "SET_ROLES_PERMISSIONS_FILTERS";

// IMPORTS SUMMARY DATA TABLE FILTERS ACTIONS TYPES
export const IMPORTS_SUMMARY_DATA_SORTING = "IMPORTS_SUMMARY_DATA_SORTING";
export const IMPORTS_SUMMARY_DATA_PAGINATION =
  "IMPORTS_SUMMARY_DATA_PAGINATION";
export const IMPORTS_SUMMARY_DATA_ITEMS_LIMIT =
  "IMPORTS_SUMMARY_DATA_ITEMS_LIMIT";
export const IMPORTS_SUMMARY_DATA_RESET_FILTERS =
  "IMPORTS_SUMMARY_DATA_RESET_FILTERS";

// NOTIFICATION CENTER FILTERS ACTIONS TYPES
export const NOTIFICATION_CENTER_STATUS = "NOTIFICATION_CENTER_STATUS";
export const NOTIFICATION_CENTER_PAGINATION = "NOTIFICATION_CENTER_PAGINATION";
export const NOTIFICATION_CENTER_ITEMS_LIMIT =
  "NOTIFICATION_CENTER_ITEMS_LIMIT";
export const NOTIFICATION_CENTER_RESET_FILTERS =
  "NOTIFICATION_CENTER_RESET_FILTERS";

/// POLLS_AND_SURVEYS DIRECTORY FILTERS
export const POLLS_AND_SURVEYS_SORTING = "POLLS_AND_SURVEYS_SORTING";
export const POLLS_AND_SURVEYS_SEARCH = "POLLS_AND_SURVEYS_SEARCH";
export const POLLS_AND_SURVEYS_STATUS = "POLLS_AND_SURVEYS_STATUS";
export const POLLS_AND_SURVEYS_PAGINATION = "POLLS_AND_SURVEYS_PAGINATION";
export const POLLS_AND_SURVEYS_ITEMS_LIMIT = "POLLS_AND_SURVEYS_ITEMS_LIMIT";
export const POLLS_AND_SURVEYS_CREATED_BY = "POLLS_AND_SURVEYS_CREATED_BY";
export const POLLS_AND_SURVEYS_ARCHIVED_BY = "POLLS_AND_SURVEYS_ARCHIVED_BY";
export const POLLS_AND_SURVEYS_PROGRAMS = "POLLS_AND_SURVEYS_PROGRAMS";
export const POLLS_AND_SURVEYS_RESET_FILTERS =
  "POLLS_AND_SURVEYS_RESET_FILTERS";
export const POLLS_AND_SURVEYS_DATE_CREATED_UPDATED_ARCHIVED =
  "POLLS_AND_SURVEYS_DATE_CREATED_UPDATED_ARCHIVED";
export const POLLS_AND_SURVEYS_RESPONSES = "POLLS_AND_SURVEYS_RESPONSES";
export const SET_POLLS_AND_SURVEYS_FILTERS = "SET_POLLS_AND_SURVEYS_FILTERS"

/// POLLS_AND_SURVEYS_RESULTS DIRECTORY FILTERS
export const POLLS_AND_SURVEYS_RESULTS_SEARCH =
  "POLLS_AND_SURVEYS_RESULTS_SEARCH";
export const POLLS_AND_SURVEYS_RESULTS_PAGINATION =
  "POLLS_AND_SURVEYS_RESULTS_PAGINATION";
  export const POLLS_AND_SURVEYS_RESULTS_RESPONSE_DATE =
  "POLLS_AND_SURVEYS_RESULTS_RESPONSE_DATE";
export const POLLS_AND_SURVEYS_RESULTS_ITEMS_LIMIT =
  "POLLS_AND_SURVEYS_RESULTS_ITEMS_LIMIT";
export const POLLS_AND_SURVEYS_RESULTS_RESET_FILTERS =
  "POLLS_AND_SURVEYS_RESULTS_RESET_FILTERS";
export const POLLS_AND_SURVEYS_RESULTS_VERSION =
  "POLLS_AND_SURVEYS_RESULTS_VERSION";

//DASHBOARD REDIRECTIONS

export const COMMUNITIES_REDIRECTION_FROM_DASHBOARD =
  "COMMUNITIES_REDIRECTION_FROM_DASHBOARD";
export const PROGRAMS_REDIRECTION_FROM_DASHBOARD =
  "PROGRAMS_REDIRECTION_FROM_DASHBOARD";
export const FORMS_REDIRECTION_FROM_DASHBOARD =
  "FORMS_REDIRECTION_FROM_DASHBOARD";
export const SPECIES_REDIRECTION_FROM_DASHBOARD =
  "SPECIES_REDIRECTION_FROM_DASHBOARD";
export const USERS_REDIRECTION_FROM_DASHBOARD =
  "USERS_REDIRECTION_FROM_DASHBOARD";
export const OBSERVATIONS_REDIRECTION_FROM_DASHBOARD =
  "OBSERVATIONS_REDIRECTION_FROM_DASHBOARD";
