import { useCallback, useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
// import { DrawShapesDropdown, ToggleButton } from "../components";
import LayersIcon from "assets/images/layers-icon.svg";
import CloseIcon from "Components/Dashboard/Configuration/CloseIcon";
import { convertKeysToCamelCase } from "utils/caseConvertor";

const useInitializeCustomLayersListControl = (
  isMapFullyLoaded: boolean,
  map: any,
  selectedGlobalLayers: any,
  setSelectedGlobalLayers: any,
  layers:any,
  customControlId:string
) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
//   const [layers, setLayers] = useState<any>(null);

//   useEffect(() => {
//     fetchLayers().then((data) => {
//       setLayers(data);
//     });
//   }, []);

  useEffect(() => {
    if (map && isMapFullyLoaded) {
      ReactDOM.render(
        <>
          <ToggleButton handleClick={toggleDropdownListener} />
          <LayersList
            isVisible={isDropdownVisible}
            handleClickOnCloseBtn={toggleDropdownListener}
            data={layers}
            handleClickOnCheckbox={handleClickOnLayerCheckBox}
            selectedGlobalLayers={selectedGlobalLayers}
          />
        </>,
        document.getElementById("LayersListControlContainer"+customControlId)
      );
    }

    return () => {
      const container = document.getElementById("LayersListControlContainer"+customControlId);
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
    };
  }, [map, isMapFullyLoaded, isDropdownVisible, layers]);

  // handlers
  const toggleDropdownListener = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleClickOnLayerCheckBox = (e: any, layer: any) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedGlobalLayers((prev: any) => {
        const alreadyAddedLayerIndex = prev?.findIndex(
          (l: any) => l?.id === layer?.id
        );

        if (alreadyAddedLayerIndex >= 0) {
          prev[alreadyAddedLayerIndex].status = true;
        } else {
          prev.push({ ...layer, status: true });
        }

        return [...prev];
      });
    } else {
      setSelectedGlobalLayers((prev: any) => {
        const layerIndex = prev?.findIndex((l: any) => l?.id === layer?.id);

        if (layerIndex >= 0) {
          prev[layerIndex].status = false;
        }

        return [...prev];
      });
    }
  };

  return null;
};

export { useInitializeCustomLayersListControl };

const ToggleButton = ({ handleClick }: any) => {
  return (
    <button onClick={handleClick} type="button">
      <img src={LayersIcon} className="mx-auto" />
    </button>
  );
};

const LayersList = ({
  isVisible,
  handleClickOnCloseBtn,
  data,
  handleClickOnCheckbox,
  selectedGlobalLayers,
}: any) => {
  return (
    <div
      className=" bg-bgWhite h-[250px] w-[200px] absolute top-0 right-[38px] rounded shadow-md "
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <div className="relative w-full h-full p-4">
        <div
          className="absolute flex items-center justify-center cursor-pointer top-4 right-4"
          onClick={handleClickOnCloseBtn}
        >
          <CloseIcon
            className="mx-auto "
            style={{ width: "18px !important" }}
          />
        </div>
        <h2 className="mb-4 text-base font-semibold font-Overpass text-secondaryMid">
          Global Layers
        </h2>
        {data ? (
          <ul className="overflow-y-auto overflow-x-hidden  h-[82%] scroll">
            {data.map((l: any) => {
              const isChecked = !!selectedGlobalLayers?.find(
                (layer: any) => layer.id === l.id
              );

              return (
                <li className="mb-2  pr-[2px]" key={l.id.toString()}>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      defaultChecked={isChecked}
                      onChange={(e: any) => handleClickOnCheckbox(e, l)}
                      id={l?.id}
                      className="w-3 h-3 cursor-pointer"
                    />
                    <label
                      htmlFor={l?.id}
                      className="cursor-pointer text-sm text-secondaryMidLight font-Overpass -mb-[3px] "
                      style={{ wordBreak: "break-word" }}
                    >
                      {l?.name}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="h-[80%] w-full flex justify-center items-center">
            <h1 className="text-sm font-semibold font-Overpass text-secondaryMid">
              Layers Not Found!
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

async function fetchLayers() {
  return [
    {
      geo_file_path: null,
      id: 73,
      legend_file_id: null,
      map_data: {
        snippet: "https://www.dycedenoqycony.us",
      },
      name: "Aiko Stevens",
      shape_type: null,
      type: "raster",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/1/original/map%20%2820%29_1704789796781087.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=27824bbb23e88f9c5621f1f571bc606a0084e839bc2da907f722fd3ec733b174",
      id: 134,
      legend_file_id:
        "isn/uploads/1/original/pexels-photo-417074_1704789797185779.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [27.30010702389191, -8.030637888755948],
                  [27.30010702389191, -8.031661675747827],
                  [27.300759968742085, -8.031661675747827],
                  [27.300759968742085, -8.030637888755948],
                  [27.30010702389191, -8.030637888755948],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
          {
            geometry: {
              coordinates: [
                [
                  [29.230710136258892, -6.5223295790497815],
                  [28.902971581072364, -6.5223295790497815],
                  [28.902971581072364, -6.73470542040846],
                  [29.230710136258892, -6.73470542040846],
                  [29.230710136258892, -6.5223295790497815],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Illana Mclaughlin",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/1/original/map%20%2820%29_1704789857782381.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=8b93e866898294a31403afce0fd963ad8dac53c373ed40221ba8e401a1241634",
      id: 135,
      legend_file_id:
        "isn/uploads/1/original/waterfall-high-resolution-desktop-wallpaper-preview_1704789858192450.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [27.30010702389191, -8.030637888755948],
                  [27.30010702389191, -8.031661675747827],
                  [27.300759968742085, -8.031661675747827],
                  [27.300759968742085, -8.030637888755948],
                  [27.30010702389191, -8.030637888755948],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
          {
            geometry: {
              coordinates: [
                [
                  [29.230710136258892, -6.5223295790497815],
                  [28.902971581072364, -6.5223295790497815],
                  [28.902971581072364, -6.73470542040846],
                  [29.230710136258892, -6.73470542040846],
                  [29.230710136258892, -6.5223295790497815],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "new vector",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/uploads/1/original/map%20%2820%29_1704790147388914.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=2f7f8ab18dbd4d56945b70b8262e57d6f3cf0ab55427964451d72bdf2faac8db",
      id: 136,
      legend_file_id:
        "isn/uploads/1/original/download (1)_1704790147388394.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [27.30010702389191, -8.030637888755948],
                  [27.30010702389191, -8.031661675747827],
                  [27.300759968742085, -8.031661675747827],
                  [27.300759968742085, -8.030637888755948],
                  [27.30010702389191, -8.030637888755948],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
          {
            geometry: {
              coordinates: [
                [
                  [29.230710136258892, -6.5223295790497815],
                  [28.902971581072364, -6.5223295790497815],
                  [28.902971581072364, -6.73470542040846],
                  [29.230710136258892, -6.73470542040846],
                  [29.230710136258892, -6.5223295790497815],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Madison Hendrix",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path: null,
      id: 233,
      legend_file_id: null,
      map_data: {
        snippet:
          "https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015",
      },
      name: "New york raster layer",
      shape_type: null,
      type: "raster",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map_1705652165893049.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=841a7586caeea0fbcf0a93d280171f2d3a9ddff02c31ef0460318319da4a4789",
      id: 247,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/image 1_1705652165953518.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [66.19213414980456, 28.518266548007347],
                  [66.19213414980456, 26.92992611083011],
                  [70.38500785796182, 26.92992611083011],
                  [70.38500785796182, 28.518266548007347],
                  [66.19213414980456, 28.518266548007347],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Trupic Test User",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map%20%284%29_1705905775666661.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9639de33a07e6ba6c9821766c479098ab711b077fffd896701db8df6a67f9ee1",
      id: 293,
      legend_file_id: null,
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [11.216925768156841, 25.525173834179604],
                  [9.645155438453118, 25.449333500788153],
                  [8.093216673524179, 25.22282685733418],
                  [6.5803939748110825, 24.848663372662735],
                  [5.124921137860765, 24.331749965290783],
                  [3.7435579766290825, 23.678738181153363],
                  [2.451272898040916, 22.89782943532476],
                  [1.2610428469930208, 21.99855386157347],
                  [0.18376840653095441, 20.991538514230086],
                  [-0.7717094287858942, 19.888279039777785],
                  [-1.5985117825314068, 18.700926040363527],
                  [-2.2915681444392564, 17.442093837117977],
                  [-2.847430662509544, 16.12469579247295],
                  [-3.2640781656045976, 14.761807194451569],
                  [-3.540727497256424, 13.36655418343551],
                  [-3.6776651074431754, 11.952025390761127],
                  [-3.6761073568558778, 10.531201820306409],
                  [-3.538094022044519, 9.116899937179411],
                  [-3.2664161965409164, 7.721722810226154],
                  [-2.8645771469948484, 6.358014375813963],
                  [-2.3367826026108873, 5.037812363032657],
                  [-1.6879553054871062, 3.7727960880878237],
                  [-0.92376732565864, 2.5742261560961692],
                  [-0.05068259479486425, 1.4528740849792192],
                  [0.923998653544944, 0.4189409751478686],
                  [1.9921022606012788, -0.5180344329488827],
                  [3.1445580004576787, -1.3492766875923938],
                  [4.371400922727446, -2.0668806383303697],
                  [5.661802813283871, -2.663920461919289],
                  [7.004138291253771, -3.1345556966934596],
                  [8.386087274236472, -3.4741287094515223],
                  [9.794772294162394, -3.679247924899924],
                  [11.216925768156838, -3.747851599827383],
                  [12.639079242151281, -3.679247924899925],
                  [14.047764262077203, -3.474128709451524],
                  [15.429713245059906, -3.1345556966934613],
                  [16.772048723029798, -2.6639204619192927],
                  [18.062450613586233, -2.066880638330371],
                  [19.289293535856004, -1.349276687592392],
                  [20.441749275712393, -0.5180344329488844],
                  [21.509852882768733, 0.41894097514786544],
                  [22.484534131108543, 1.4528740849792225],
                  [23.357618861972316, 2.5742261560961595],
                  [24.121806841800776, 3.772796088087816],
                  [24.770634138924567, 5.03781236303266],
                  [25.298428683308526, 6.358014375813961],
                  [25.700267732854588, 7.721722810226145],
                  [25.971945558358197, 9.116899937179411],
                  [26.10995889316956, 10.531201820306404],
                  [26.111516643756858, 11.952025390761122],
                  [25.9745790335701, 13.366554183435506],
                  [25.697929701918277, 14.761807194451565],
                  [25.281282198823224, 16.124695792472956],
                  [24.72541968075294, 17.442093837117977],
                  [24.032363318845093, 18.70092604036352],
                  [23.205560965099576, 19.888279039777785],
                  [22.250083129782727, 20.991538514230086],
                  [21.172808689320664, 21.99855386157346],
                  [19.982578638272763, 22.89782943532476],
                  [18.6902935596846, 23.678738181153356],
                  [17.308930398452908, 24.331749965290783],
                  [15.853457561502612, 24.84866337266273],
                  [14.340634862789509, 25.22282685733418],
                  [12.78869609786056, 25.449333500788153],
                  [11.216925768156841, 25.525173834179604],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "geospatial_test_vector_layer_circle",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map%20%285%29_1705905883155560.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=dffd3ec0496c6f7d25fbb996fe5668023f1a77402726f9c619ee40f15022902a",
      id: 294,
      legend_file_id: null,
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [27.014572140664654, 12.578696940623075],
                  [20.871806195375825, 2.820604325003984],
                  [15.086809938921334, -6.556069926812299],
                  [26.801063209672577, -13.735807499406704],
                  [39.460783540447125, -18.093438381309127],
                  [54.738595376293006, 1.1807365076627292],
                  [13.81476375526708, 25.704566315479696],
                  [-5.044911919086246, 12.5989374124958],
                  [-5.337960304836372, -5.030467855863975],
                  [14.643936143910281, -6.343673322511819],
                  [27.014572140664654, 12.578696940623075],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "geospatial_test_vector_layer_polygon",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map%20%285%29_1705908566638637.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=a1a78350ffd8573ba2b7d85be89b1c6abfd1ca1386be6b9459ecd0a40d6a3333",
      id: 301,
      legend_file_id: null,
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-137.19340769361753, 56.11826780145563],
                  [-137.19340769361753, -9.016143855085645],
                  [16.57332794643588, -9.016143855085645],
                  [16.57332794643588, 56.11826780145563],
                  [-137.19340769361753, 56.11826780145563],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Midnight sun Retreat",
      shape_type: "point",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/margaret%20eagen%20sullivan%20park_1705994822804072.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=b1a24e07b2e58d6f1be6930134f9e12b8456aa87434104491626e37bd2f7e3c4",
      id: 346,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/lagend for vector layer sample_1705994822803356.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.9239548744124, 61.20793858774158],
                  [-149.9241866822323, 61.205538104612174],
                  [-149.9184204627143, 61.203263059275855],
                  [-149.91645009624582, 61.20273265698489],
                  [-149.91418997000255, 61.203039733084324],
                  [-149.91108954041246, 61.20493795523731],
                  [-149.9172614236152, 61.207171011293724],
                  [-149.91830455880435, 61.20711518682225],
                  [-149.91934769399347, 61.20765947119804],
                  [-149.9203328772277, 61.20765947119804],
                  [-149.9223611956512, 61.208608457351886],
                  [-149.92317252302058, 61.20852472442991],
                  [-149.9233753548629, 61.20828747994179],
                  [-149.9239548744124, 61.20793858774158],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "margaret eagen sullivan park",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map_1705998578485168.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d9d63df2f46577fbe7ad9013e33c490038693653d9247832293f332534347171",
      id: 357,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/lagend for vector layer sample_1705998578442117.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.86805623546297, 61.227021781859975],
                  [-149.86805623546297, 61.200798423561565],
                  [-149.86152137389243, 61.200798423561565],
                  [-149.86152137389243, 61.227021781859975],
                  [-149.86805623546297, 61.227021781859975],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "rectangle",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/University%20of%20alaska%20anchorage_1705998789656807.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=caf626eb562d108382ee5543e3d2006e2e6b363d1e3c20f49e119637575041c8",
      id: 358,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/lagend for vector layer sample_1705998789689079.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.86805623546297, 61.227021781859975],
                  [-149.86805623546297, 61.200798423561565],
                  [-149.86152137389243, 61.200798423561565],
                  [-149.86152137389243, 61.227021781859975],
                  [-149.86805623546297, 61.227021781859975],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
          {
            geometry: {
              coordinates: [
                [
                  [-149.83827098397282, 61.1949513419045],
                  [-149.83827098397282, 61.181154025046254],
                  [-149.79159340132568, 61.181154025046254],
                  [-149.79159340132568, 61.1949513419045],
                  [-149.83827098397282, 61.1949513419045],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "UOAA",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/mappp_1706000854248781.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=34335207ea5f0f8f01b0f4248e32a29d4873642879f03a921939eac2f5699e67",
      id: 361,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/1000_F_412356450_R4cbcI2A23IGCCx75LP1qLLUXkmathPB_1706000854374018.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.9239548744124, 61.20793858774158],
                  [-149.9241866822323, 61.205538104612174],
                  [-149.9184204627143, 61.203263059275855],
                  [-149.91645009624582, 61.20273265698489],
                  [-149.91418997000255, 61.203039733084324],
                  [-149.91108954041246, 61.20493795523731],
                  [-149.9172614236152, 61.207171011293724],
                  [-149.91830455880435, 61.20711518682225],
                  [-149.91934769399347, 61.20765947119804],
                  [-149.9203328772277, 61.20765947119804],
                  [-149.9223611956512, 61.208608457351886],
                  [-149.92317252302058, 61.20852472442991],
                  [-149.9233753548629, 61.20828747994179],
                  [-149.9239548744124, 61.20793858774158],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Samuel Harrison",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path: null,
      id: 372,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/lagend for vector layer sample_1706005849052072.jpeg",
      map_data: {
        snippet:
          "https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015",
      },
      name: "test raster",
      shape_type: null,
      type: "raster",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/University%20of%20alaska%20anchorage_1706006100948987.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=1c24439bef69b113faaf72966084565339cb905c2a976e2a4f1ab4460384fa17",
      id: 375,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/lagend for vector layer sample_1706006100966641.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.83827098397282, 61.1949513419045],
                  [-149.83827098397282, 61.181154025046254],
                  [-149.79159340132568, 61.181154025046254],
                  [-149.79159340132568, 61.1949513419045],
                  [-149.83827098397282, 61.1949513419045],
                ],
              ],
              type: "Polygon",
            },
            id: 0,
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "test vector",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/South%20Africa%20Vecter%20Polygon_1706006734052380.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=0c3a7f51c7b5b412a5736f6e75ba418d551499eae26d68c7485147824309d288",
      id: 383,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/1705036590838 - Copy_1706006733983109.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-69.84425622936917, -4.496481107446641],
                  [-54.04753788592268, -1.6001357204156506],
                  [-50.44573599420724, 2.03733577837437],
                  [-59.24819618550367, 6.190505454011884],
                  [-71.49135305287135, 11.642909445193482],
                  [-78.58252829950644, 1.9093543885989561],
                  [-72.13661683803336, -54.63569879237331],
                  [-46.56405365138917, -22.941329431848956],
                  [-54.03993035056749, -1.6118423096985879],
                  [-69.84425622936917, -4.496481107446641],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "South Africa Polygon",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/Kuwait%20Point_1706072947066443.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d746355f4f66bc136abcd0b0d77a489cc26cb1fc87ff931c1bc58b9746dc5bb5",
      id: 429,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/nature3_1706072947272536.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [47.48512556663911, 29.527711752970987],
              type: "Point",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Kuwait Point",
      shape_type: "point",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/Spain%20Point_1706073013581028.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=00d0eb1ea2bb090351649a71c87131563835aa8505d8548b916cc67c3c1f6cb8",
      id: 430,
      legend_file_id: null,
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [-3.91195898394443, 41.44053556914028],
              type: "Point",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Spain Point",
      shape_type: "point",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/Paris%20Point_1706073097469272.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=837512cc6d059d13cf7c155d773fa0eed4643c4e8ef78d858c06a3be12037b32",
      id: 431,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/download (3) - Copy_1706073097473197.jpeg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [2.216538645420968, 48.879100089509166],
              type: "Point",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Paris Point",
      shape_type: "point",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/mappp_1706077512225572.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=296d0dc58cc7d4609a44e54f297f5ec538bee7c74906943dafe5b8bea38ad10d",
      id: 432,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/1000_F_412356450_R4cbcI2A23IGCCx75LP1qLLUXkmathPB_1706077512229772.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [-149.9239548744124, 61.20793858774158],
                  [-149.9241866822323, 61.205538104612174],
                  [-149.9184204627143, 61.203263059275855],
                  [-149.91645009624582, 61.20273265698489],
                  [-149.91418997000255, 61.203039733084324],
                  [-149.91108954041246, 61.20493795523731],
                  [-149.9172614236152, 61.207171011293724],
                  [-149.91830455880435, 61.20711518682225],
                  [-149.91934769399347, 61.20765947119804],
                  [-149.9203328772277, 61.20765947119804],
                  [-149.9223611956512, 61.208608457351886],
                  [-149.92317252302058, 61.20852472442991],
                  [-149.9233753548629, 61.20828747994179],
                  [-149.9239548744124, 61.20793858774158],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Lionel Durham",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
    {
      geo_file_path:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/https%3A//s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/map%20%2810%29_1706098957333345.geojson?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA56V5M36RU5TMT44E%2F20240124%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240124T142525Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=bbac7ce9bde881defb84a48ccbcec07a11d31ab74e0fbfe0e4cb68cc6905832b",
      id: 469,
      legend_file_id:
        "https://s3.ap-southeast-1.amazonaws.com/isn-landscape/isn/public/1/original/ruffey_park_map_1706098957535474.jpg",
      map_data: {
        features: [
          {
            geometry: {
              coordinates: [
                [
                  [31.841695762996977, 62.18459620916116],
                  [30.262820535200603, 61.37159920390607],
                  [32.42467878026412, 60.02419576755807],
                  [35.060650259698264, 61.4970689044672],
                  [31.841695762996977, 62.18459620916116],
                ],
              ],
              type: "Polygon",
            },
            properties: {},
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      },
      name: "Finland",
      shape_type: "polygon",
      type: "vector",
      vector_layers: null,
    },
  ].map((obj: any) => convertKeysToCamelCase(obj));
}